import styled from 'styled-components';
import { useState } from 'react';
import { ButtonBase } from 'ui/Buttons/ButtonBase';
import { HelpTooltip } from 'ui/icons/HelpTooltip';
import { InfoModal } from '../Modales/InfoModal';

export const InfoModalButton = () => {
  const [knowmoreModalOpen, setKnowmoreModalOpen] = useState<boolean>(false);

  const handleClick = () => {
    setKnowmoreModalOpen(!knowmoreModalOpen);
  };

  const closeModal = () => setKnowmoreModalOpen(false);

  return (
    <>
      <ButtonBase
        size={'large'}
        disabled={false}
        styled={'secondary'}
        iconOnly={false}
        onClick={handleClick}
        hasPadding={true}
      >
        En savoir plus
        <IconContainer>
          <HelpTooltip />
        </IconContainer>
      </ButtonBase>
      <InfoModal isOpen={knowmoreModalOpen} closeModal={closeModal} />
    </>
  );
};

const IconContainer = styled.div`
  height: 16px;
  width: 16px;
  flex-shrink: 0;
  margin-top: -12px;
`;
