import { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import parse from 'html-react-parser';

import { PropositionIcon } from './PropositionIcon';
import { QCMCheckbox } from './QCMCheckbox';
import { Toggler } from './Toggler';
import { propositionNumToLetter } from './utils';
import { popSlideInAnimation } from 'helpers/css/animations/popSlideIn';
import { useShouldAutomaticallyOpenComments } from 'modules/user-options/hooks/useShouldAutomaticallyOpenCommentary';
import { useIsExplicitCorrection } from 'modules/user-options/hooks/useIsExplicitCorrection';
import { isAnswerCorrect } from '../helpers/isAnswerCorrect';
import { StyledMathJax } from 'ui/MathJax';
import { CorrectionItemsQcm } from 'types/correction.type';
import { Quotes } from 'components/Quote/Quotes';
import { useTour } from '@reactour/tour';

interface Props {
  itemCorrection: CorrectionItemsQcm;
  hasUserAnswered: boolean;
}

export const Proposition: FC<Props> = ({ itemCorrection, hasUserAnswered }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { isOpen: isTutoOpen } = useTour();

  const shouldAutomaticallyOpenComments = useShouldAutomaticallyOpenComments();
  useEffect(() => {
    setIsOpen(shouldAutomaticallyOpenComments);
  }, [shouldAutomaticallyOpenComments]);

  const toggleOpen = () => setIsOpen(!isOpen);

  const isExplicitCorrection = useIsExplicitCorrection();
  const isCorrect = isAnswerCorrect(itemCorrection, hasUserAnswered);

  useEffect(() => {
    if (isTutoOpen && itemCorrection.id === 106911987) {
      setIsOpen(true);
    } else if (isTutoOpen) {
      setIsOpen(false);
    }
  }, [isTutoOpen, itemCorrection.id]);

  return (
    <Container
      isExplicitCorrection={isExplicitCorrection}
      isAnswerCorrect={isCorrect}
      id={'corrections-questions-' + itemCorrection.id}
    >
      <PropositionIcon
        answer={itemCorrection.answer}
        state={itemCorrection.question_item.state}
        hasUserAnswered={hasUserAnswered}
      />

      <RightSide>
        <PropositionContainer onClick={toggleOpen}>
          <PropositionHeader>
            <HeaderLeft>
              <QCMCheckbox
                answer={itemCorrection.answer}
                hasUserAnswered={hasUserAnswered}
              />
              Proposition{' '}
              {propositionNumToLetter(itemCorrection.question_item.num)}
            </HeaderLeft>
            <Toggler
              isOpen={isOpen}
              toggleOpen={toggleOpen}
              propositionState={itemCorrection.question_item.state}
            />
          </PropositionHeader>

          <Statement>
            <StyledMathJax inline>
              {parse(itemCorrection.question_item.statement)}
            </StyledMathJax>
          </Statement>
        </PropositionContainer>

        {isOpen && (
          <ToggledInfos className="correction-toggle">
            <StyledMathJax inline>
              {parse(itemCorrection.question_item.correction)}
            </StyledMathJax>
            {itemCorrection.question_item.quotes.length !== 0 && (
              <StyledQuotes quotes={itemCorrection.question_item.quotes} />
            )}
          </ToggledInfos>
        )}
      </RightSide>
    </Container>
  );
};

const Container = styled.div<{
  isExplicitCorrection: boolean;
  isAnswerCorrect: boolean;
}>`
  margin-right: 28px;
  margin-left: 4px;
  display: flex;
  gap: 4px;

  --statement-left-padding: 16px;
  --statement-right-padding: 12px;
  --statement-vertical-padding: 8px;
  --statement-gap: 4px;
  --statement-comment-gap: 4px;

  ${({ theme, isExplicitCorrection, isAnswerCorrect }) =>
    isExplicitCorrection
      ? css`
          --proposition-color: ${isAnswerCorrect
            ? theme.colors.correctionCard.successMain
            : theme.colors.correctionCard.failedMain};
          --comment-color: ${isAnswerCorrect
            ? theme.colors.correctionCard.successComment
            : theme.colors.correctionCard.failedComment};
        `
      : css`
          --proposition-color: ${theme.colors.primary.light};
          --comment-color: ${theme.colors.primary.light + '99'};
        `}
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--statement-comment-gap);
  flex-grow: 1;
`;

const PropositionContainer = styled.div`
  background-color: var(--proposition-color);
  border-radius: 8px;
  padding: var(--statement-vertical-padding) 0;
  z-index: 1;
  cursor: pointer;
`;

const PropositionHeader = styled.div`
  margin-left: var(--statement-left-padding);
  margin-right: var(--statement-right-padding);
  display: flex;
  justify-content: space-between;
`;

const HeaderLeft = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const Statement = styled.div`
  padding: var(--statement-gap) var(--statement-left-padding) 0;
  ${({ theme }) => theme.typography.label}
`;

const ToggledInfos = styled.div`
  background-color: var(--comment-color);
  border-radius: 8px;
  padding: var(--statement-vertical-padding) var(--statement-left-padding);
  margin-bottom: 4px;
  ${popSlideInAnimation}
`;

const StyledQuotes = styled(Quotes)`
  background-color: transparent;
`;
