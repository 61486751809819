import { RepartitionBarData } from 'components/StatsElements/RepartitionBar';
import { CardsContainer, SmallSectionTitle } from 'pages/newHome/ui/Global';
import React from 'react';
import BlueCard from 'ui/Cards/BlueCard';
import { HomePieChart } from './HomePieChart';

interface GlobalAvancementProps {
  dpData?: RepartitionBarData;
  qiData?: RepartitionBarData;
  qiGrade?: number;
  dpGrade?: number;
}

export const TrainingQuestions: React.FC<GlobalAvancementProps> = ({
  qiData,
  dpData,
  qiGrade,
  dpGrade,
}) => {
  if (!dpData || !qiData) return null;
  return (
    <CardsContainer nbCards={2} gap="2rem" fullWidth noPadding>
      <BlueCard
        noMargin
        color="#CDE0F6"
        darker
        fullWidth
        gap="0rem"
        overflow="visible"
      >
        <SmallSectionTitle>DP & KFP</SmallSectionTitle>
        <HomePieChart data={dpData} grade={dpGrade?.toString() || ''} />
      </BlueCard>
      <BlueCard
        noMargin
        color="#CDE0F6"
        darker
        fullWidth
        gap="0rem"
        overflow="visible"
      >
        <SmallSectionTitle>QI</SmallSectionTitle>
        <HomePieChart data={qiData} grade={qiGrade?.toString() || ''} />
      </BlueCard>
    </CardsContainer>
  );
};
