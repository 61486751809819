import { FC } from 'react';
import styled, { css } from 'styled-components/macro';

interface Props {
  status: 'free' | 'limited' | 'unvisible';
}

export const AccessSticker: FC<Props> = ({ status }) => {
  return (
    <Container>
      <Sticker status={status}>
        {status === 'free'
          ? 'Gratuit'
          : status === 'limited'
          ? 'Accès limité'
          : 'Premium'}
      </Sticker>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 4px;
`;

const Sticker = styled.div<{ status: 'free' | 'limited' | 'unvisible' }>`
  display: flex;
  padding: 4px 8px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
  border-radius: 4px;
  background: var(--Decorative-lightblue, rgba(50, 151, 148, 0.3));
  ${({ theme }) => theme.typography.h6};
  ${({ status }) => {
    switch (status) {
      case 'free':
        return css`
          background: var(--Decorative-lightblue, rgba(50, 151, 148, 0.3));
        `;
      case 'limited':
        return css`
          background-color: #f7d1bb;
          color: ${({ theme }) => theme.colors.button.primary};
        `;
      case 'unvisible':
        return css`
          display: none;
        `;
      default:
        return '';
    }
  }}
`;
