import { PageContent } from './components/PageContent';
import { DisplayOptionsMenu } from './ui/DisplayOptionsMenu';
import { TopPageContent } from './components/TopPageContent';
import { HomeItemsFiltersProvider } from './context/homeItemsFiltersContext';
import { HomeSpecialiesFiltersProvider } from './context/homeSpecialtiesFiltersContext';
import { HomeSearchProvider } from './context/homeSearchContext';
import { ScrollToTop } from 'helpers/scroll/scrollToTop';
import styled from 'styled-components';
import { useHomeInfoContext } from './context/homeInfoContext';
import { LoaderWithText } from 'components/Loader/LoaderWithText';

export const WholePageContent = () => {
  const { shownPage, activeTab, activeItemId, activeSpecialtyId, isLoading } =
    useHomeInfoContext();
  return (
    <ScrollToTop
      triggers={[
        shownPage,
        activeTab,
        activeItemId,
        activeSpecialtyId,
        isLoading,
      ]}
    >
      <TitleContainer>
        <h1>Accueil</h1>
        <DisplayOptionsMenu />
      </TitleContainer>
      {isLoading ? (
        <LoaderWithText text="Chargement des données..." />
      ) : (
        <>
          <HomeItemsFiltersProvider>
            <HomeSpecialiesFiltersProvider>
              <HomeSearchProvider>
                <TopPageContent />
                <PageContent />
              </HomeSearchProvider>
            </HomeSpecialiesFiltersProvider>
          </HomeItemsFiltersProvider>
        </>
      )}
    </ScrollToTop>
  );
};

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
