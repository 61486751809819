import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routing/constants';
import { Button } from 'ui/Buttons/Button';
import { Modal } from 'ui/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { useGetCCBUnfinishedTasks } from 'http/task-history/connector';
import {
  fetchFormatedSessionsStatus,
  updateAllDone,
  updateNextTask,
  updateScreenToDisplay,
  updateSessionsLeftToDo,
} from 'store/slices/ccbSlice';
import { useIsUserPremium } from 'modules/user-options/hooks/useIsUserPremium';
import styled, { useTheme } from 'styled-components';
import { toast } from 'react-toastify';
import { useGetUserOptions } from 'modules/user-options/hooks/useGetUserOptions';
import { useSubmitTask } from 'pages/new_training/hooks/saveHooks/useSubmitTask';
import { CCBScreenType, FormatedCCBSession } from 'http/ccb/type';
import { useGetEstimatedDurations } from 'modules/user-options/hooks/useGetEstimatedDurations';

interface Props {}

export const OngoingCCBTraining: FC<Props> = () => {
  const dispatch = useDispatch();
  const { isUserPremium } = useIsUserPremium();
  const { data: unfinishedCCBTasks, refetch } = useGetCCBUnfinishedTasks();
  const theme = useTheme();
  const activeCcb = useSelector((state: any) => state.ccb.activeCcb) || {
    allDone: false,
    isCCBActivatedFree: false,
    sessions: [],
    id: null,
  };
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loadingCancelButton, setLoadingCancelButton] =
    useState<boolean>(false);
  const [loadingGetBackButton, setLoadingGetBackButton] =
    useState<boolean>(false);
  const { data: userOptions } = useGetUserOptions();
  const estimatedDurations = useGetEstimatedDurations();
  const submitTask = useSubmitTask();

  const navigate = useNavigate();

  useEffect(() => {
    refetch();
    if (!unfinishedCCBTasks || unfinishedCCBTasks.length === 0) {
      setIsModalOpen(false);
      return;
    } else if (unfinishedCCBTasks && unfinishedCCBTasks.length > 0) {
      const shouldShowModal =
        unfinishedCCBTasks.length > 0 &&
        activeCcb?.lastTask?.finished === false &&
        (activeCcb?.allDone === false || activeCcb?.allDone === undefined) &&
        (isUserPremium || (!isUserPremium && activeCcb?.isCCBActivatedFree));

      setIsModalOpen(shouldShowModal);
    }
  }, [
    activeCcb?.allDone,
    unfinishedCCBTasks,
    isUserPremium,
    activeCcb?.isCCBActivatedFree,
    refetch,
    activeCcb.lastTask,
  ]);

  const goToLastUnfinishedTask = async () => {
    setLoadingGetBackButton(true);
    try {
      if (activeCcb?.nextTask?.finished) {
        const taskId = activeCcb.nextTask.id;
        navigate({ pathname: routes.CCBTRAINING, search: `?id=${taskId}` });
      } else if ((unfinishedCCBTasks?.length ?? 0) > 0) {
        const lastTask = (unfinishedCCBTasks ?? [])[
          (unfinishedCCBTasks ?? []).length - 1
        ];
        if (lastTask?.id !== undefined) {
          dispatch(
            updateNextTask({
              ccbId: activeCcb.id,
              task: { id: lastTask.id, finished: false },
            })
          );
          navigate({
            pathname: routes.CCBTRAINING,
            search: `?id=${lastTask.id}`,
          });
        } else {
          navigate({ pathname: routes.CONCOURS_BLANC });
        }
      } else {
        navigate({ pathname: routes.CONCOURS_BLANC });
      }
    } finally {
      setLoadingGetBackButton(false);
      setIsModalOpen(false);
    }
  };

  const handleTaskSubmission = async () => {
    setLoadingCancelButton(true);
    try {
      if (
        !unfinishedCCBTasks?.[0] ||
        !userOptions ||
        !activeCcb?.sessionsLeftToDo ||
        !estimatedDurations
      ) {
        toast.error("Aucune épreuve de concours blanc n'est en cours.");
        return;
      }

      dispatch(
        updateSessionsLeftToDo({
          ccbId: activeCcb.id,
          sessions: activeCcb.sessionsLeftToDo,
        })
      );

      dispatch(
        fetchFormatedSessionsStatus({
          ccbId: activeCcb.id,
          ucDuration: estimatedDurations.ucDuration,
          trainingDuration: userOptions.trainingDuration,
        })
      );

      await submitTask(unfinishedCCBTasks[0].id, () => {});

      const finishedSessions = activeCcb.sessions.filter(
        (session: FormatedCCBSession) => session.finished === true
      );
      const isdone = finishedSessions.length === activeCcb.sessions.length;

      dispatch(updateAllDone({ ccbId: activeCcb.id, value: isdone }));
      dispatch(updateScreenToDisplay(CCBScreenType.PostCCB));

      navigate({ pathname: routes.CONCOURS_BLANC });
    } finally {
      setIsModalOpen(false);
      setLoadingCancelButton(false);
      refetch();
    }
  };

  return (
    <Modal isOpen={isModalOpen}>
      <ModalBody>
        <h3>Vous avez une épreuve de concours blanc en cours.</h3>
        <h5>Souhaitez-vous la reprendre ?</h5>
        <ButtonContainer>
          <GetBackButton
            onClick={goToLastUnfinishedTask}
            isLoading={loadingGetBackButton}
          >
            Reprendre
          </GetBackButton>
          <CancelButton
            style={{
              backgroundColor: theme.colors.secondary.main,
            }}
            onClick={handleTaskSubmission}
            isLoading={loadingCancelButton}
          >
            Soumettre l'épreuve
          </CancelButton>
        </ButtonContainer>
      </ModalBody>
    </Modal>
  );
};

const ModalBody = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 30px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const GetBackButton = styled(Button)``;

const CancelButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.secondary.main};
`;
