import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { CCBRanking } from './Ranking/CCBRanking';
import { CCBStatsAndGrades } from './Stats/CCBStatsAndGrades';
import { CorrectedCopy } from './Copies/CorrectedCopy';
import { UnderlinedTabSwitch } from '../../../../components/Tabs/UnderlinedTabSwitch';
import { PromotionFilters } from './components/PromotionFilters';
import { device } from 'helpers/css/responsive';
import { updateStatsActiveTab } from 'store/slices/ccbSlice';
import { Statstabs } from 'http/ccb/type';
import { RootState } from 'store';

export const PageContent = () => {
  const { activeCcb } = useSelector((state: RootState) => state.ccb);
  const dispatch = useDispatch();
  const handleTabClick = (clickedTab: Statstabs) => {
    dispatch(
      updateStatsActiveTab({ ccbId: activeCcb?.id, statsActiveTab: clickedTab })
    );
  };

  const PageToDisplay = () => {
    switch (activeCcb?.statsActiveTab) {
      case 'Classement':
        return <CCBRanking />;
      case 'Correction':
        return <CorrectedCopy />;
      case 'Statistique':
        return <CCBStatsAndGrades />;
      default:
        return <CCBRanking />;
    }
  };

  if (!activeCcb?.correction)
    return (
      <>Les statistiques de ce concours-blanc ne sont pas encore disponibles</>
    );

  return (
    <PageContentContainer>
      <Headers>
        <UnderlinedTabSwitch
          tabOptions={[
            { label: 'Classement', value: 'Classement' },
            { label: 'Statistiques', value: 'Statistique' },
            { label: 'Copies corrigées', value: 'Correction' },
          ]}
          handleTabClick={handleTabClick}
          activeTab={activeCcb.statsActiveTab ?? 'Classement'}
        />
        <PromotionFilters isShown={activeCcb.statsActiveTab === 'Classement'} />
      </Headers>
      <PageToDisplay />
    </PageContentContainer>
  );
};

const PageContentContainer = styled.div`
  width: 100%;
`;
const Headers = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  align-items: center;
  width: 100%;
  @media ${device.mobile} {
    flex-direction: column;
  }
`;
