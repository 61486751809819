import React, { Fragment, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { buttonReset } from 'helpers/css/button-reset';
import { TCSRow } from './types';
import { Range } from './Range';
import { popSlideInAnimation } from 'helpers/css/animations/popSlideIn';
import { DownChevron } from 'ui/icons/Arrows/DownChevron';
import { useShouldAutomaticallyOpenComments } from 'modules/user-options/hooks/useShouldAutomaticallyOpenCommentary';
import { StyledMathJax } from 'ui/MathJax';
import parse from 'html-react-parser';

interface Props {
  row: TCSRow;
}

export const Comment: React.FC<Props> = ({ row }) => {
  const [isCommentVisible, setIsCommentVisible] = useState<boolean>(false);
  const toggleComment = () => setIsCommentVisible(!isCommentVisible);

  const shouldAutomaticallyOpenComments = useShouldAutomaticallyOpenComments();

  useEffect(() => {
    setIsCommentVisible(shouldAutomaticallyOpenComments);
  }, [shouldAutomaticallyOpenComments]);

  return (
    <Fragment key={row.id}>
      <Cell onClick={toggleComment}>{row.firstColumn}</Cell>
      <Cell onClick={toggleComment}>{row.secondColumn}</Cell>
      <Cell onClick={toggleComment}>
        <RangeContainer>
          <Range userAnswer={row.userAnswer} answerPoints={row.rangePoints} />
        </RangeContainer>
      </Cell>
      <ToggleCell onClick={toggleComment}>
        <Arrow isOpen={isCommentVisible} />
      </ToggleCell>
      {isCommentVisible && (
        <>
          <Commentary onClick={toggleComment}>
            <StyledMathJax inline>{parse(row.commentary)}</StyledMathJax>
          </Commentary>
          <CellPlaceholder />
        </>
      )}
      <Separator />
    </Fragment>
  );
};

const Cell = styled.div`
  display: grid;
  align-items: flex-start;
  min-height: 110px;
  text-align: left;
  cursor: pointer;
  width: 100%;
`;

const ToggleCell = styled.button`
  ${buttonReset}
  display: grid;
  place-items: flat-start;
  height: 100%;
  text-align: center;
  justify-content: center;
  padding: 1rem 0.5rem;
`;

const RangeContainer = styled.div`
  width: 100%;
`;

const Commentary = styled.div`
  grid-column: 1/4;
  padding: 8px 12px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.primary.light};
  width: 100%;
  border-radius: 8px;
  margin-bottom: 1rem;
  ${popSlideInAnimation}
`;

const CellPlaceholder = styled.div`
  grid-column: 3/4;
`;

const Separator = styled.div`
  grid-column: 1/-1;
  padding: 0 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.main};
`;

const Arrow = styled(({ isOpen, ...props }: { isOpen: boolean }) => (
  <DownChevron {...props} />
))`
  height: 12px;
  width: 12px;

  transition: transform 0.2s ease-in-out;
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `}
`;
