import styled from 'styled-components';
import { PageContent } from './PageContent';
import { useDispatch, useSelector } from 'react-redux';
import { BackwardsButton } from 'ui/Buttons/Backwards';
import { updateScreenToDisplay, updateActiveCcb } from 'store/slices/ccbSlice';
import { CCBScreenType } from 'http/ccb/type';
import { RootState } from 'store';

export const StatisticsPage = () => {
  const dispatch = useDispatch();

  const { collection } = useSelector((state: RootState) => state.ccb);
  const handleClick = () => {
    dispatch(updateActiveCcb(null));
    dispatch(updateScreenToDisplay(CCBScreenType.PreCCBInfo));
  };

  return (
    <>
      <BackwardsButton text={'Retour'} handleClick={handleClick} />
      <TitleContainer>
        <h1>{collection?.label}</h1>
      </TitleContainer>
      <PageContent />
    </>
  );
};

const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 40px;
  gap: 1rem;
`;
