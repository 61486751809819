import styled from 'styled-components';
import { FC } from 'react';

interface Props {
  isSmall?: boolean;
}

export const Knowmore: FC<Props> = ({ isSmall }) => {
  return (
    <Container isSmall={isSmall ?? false}>
      <h1>Comment fonctionnent nos sessions ?</h1>
      <Chapter>
        <SubTitles>Durée et déroulement des sessions</SubTitles>
        <div>
          <Paragraph>
            Comme pour le jour J, les sessions doivent être complétées en une
            seule fois sur une période de 3 heures sans interruption et dans
            l'ordre spécifié (UC1, UC2, UC3 puis la LCA).
          </Paragraph>
        </div>
      </Chapter>
      <Chapter>
        <SubTitles>Deux types de classements par EDN Blanc</SubTitles>
        <Paragraph>
          <ul>
            <li>
              Le premier qui inclura uniquement les participants ayant réalisé
              le CCB sur une période donnée.
            </li>
            <li>Un classement général qui inclura tous les participants</li>
          </ul>
          Une fois l’EDN réalisé et corrigé par nos équipes, vous pourrez
          accéder à un 13 classements par groupe de spécialités ainsi que de
          nombreuses statistiques.
        </Paragraph>
      </Chapter>
    </Container>
  );
};

const Container = styled.div<{ isSmall: boolean }>`
  display: flex;
  gap: ${({ isSmall }) => (isSmall ? '1rem' : '2.5rem')};
  flex-direction: column;
`;

const Chapter = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  margin-bottom: 1.5rem;
`;

const Paragraph = styled.div`
  ${({ theme }) => theme.typography.textBody2};
  display: flex;
  flex-direction: column;
  gap: 8px;
  display: inline;
  ul {
    margin-top: -2px;
  }
`;

const SubTitles = styled.h4`
  font-weight: 700;
  display: inline;
`;
