import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ccbCreateTask } from 'http/ccb/api';
import { routes } from 'routing/constants';
import { reportErrorToSentry } from 'helpers/application/reportErrorToSentry';
import {
  updateNextTask,
  updateLastTask,
  addTaskIdToSession,
} from 'store/slices/ccbSlice';
import { useDispatch } from 'react-redux';
import { CCBTask } from 'http/ccb/type';
import { useState } from 'react';

export const useLauchCCBTask = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false); // Set initial loading state to false
  const { activeCcb } = useSelector((state: any) => state.ccb);

  const startCCBTask = async () => {
    if (
      !activeCcb.sessionsLeftToDo[0] ||
      !activeCcb.sessionsLeftToDo[0].composition_unit
    ) {
      toast.error("Nous n'avons pas pu récupérer l'épreuve de concours-blanc");
      return;
    }
    setIsLoading(true);
    try {
      const task: CCBTask = await ccbCreateTask(
        activeCcb.id,
        activeCcb.sessionsLeftToDo[0].composition_unit
      );

      dispatch(
        addTaskIdToSession({
          ccbId: activeCcb.id,
          taskComposition_id: activeCcb.sessionsLeftToDo[0].composition_unit,
          taskId: task.id,
        })
      );

      dispatch(
        updateNextTask({
          ccbId: activeCcb.id,
          task: {
            ...task,
            composition_unit: activeCcb.sessionsLeftToDo[0].composition_unit,
          },
        })
      );

      dispatch(
        updateLastTask({
          ccbId: activeCcb.id,
          task: {
            ...task,
            composition_unit: activeCcb.sessionsLeftToDo[0].composition_unit,
          },
        })
      );

      navigate({ pathname: routes.CCBTRAINING, search: `?id=${task.id}` });
      setIsLoading(false);
    } catch (e) {
      toast.error(
        "Nous n'avons pas pu récupérer l'épreuve de concours-blanc, merci de recharger la page et de réessayer."
      );
      reportErrorToSentry(e);
      setIsLoading(false);
    }
  };

  return { startCCBTask, isLoading };
};
