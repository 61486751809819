import styled from 'styled-components/macro';

export const GreyCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  padding: 0.85rem;
  border: 1px solid ${({ theme }) => theme.colors.inactive};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.inactive + '22'};
  color: ${({ theme }) => theme.colors.primary.dark};
  overflow: hidden;
  img {
    max-width: 100%;
    height: auto;
  }
`;
