import { MS_IN_AN_HOUR } from 'constants/date';
import { useQuery } from 'react-query';
import { getCCBstatus, getLCAArticles, getCCBbyId } from './api';
import { CCBStatus } from './type';
import { useMemo } from 'react';

export const useGetStatus = (CCBid: number) => {
  const query = useQuery(['ccbCreateTask'], () => getCCBstatus(CCBid), {
    staleTime: MS_IN_AN_HOUR,
    cacheTime: MS_IN_AN_HOUR,
  });
  const ccbStatus = useMemo<CCBStatus | undefined>(
    () => query.data,
    [query.data]
  );

  return {
    ...query,
    ccbStatus,
  };
};

export const useCCBStatistics = () => {
  const statistics = undefined;
  return { statistics };
};

export const useCCBLcaArticles = (ccbId: number) => {
  const query = useQuery(
    ['ccb_lca_articles', ccbId],
    () => getLCAArticles(ccbId),
    {
      staleTime: MS_IN_AN_HOUR,
      cacheTime: MS_IN_AN_HOUR,
    }
  );
  return query;
};

export const useGetCCBbyId = (ccbId: number) => {
  const query = useQuery(['ccb_by_id', ccbId], () => getCCBbyId(ccbId), {
    staleTime: MS_IN_AN_HOUR,
    cacheTime: MS_IN_AN_HOUR,
  });
  return query;
};
