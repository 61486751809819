import { FC } from 'react';
import styled from 'styled-components/macro';
import parse from 'html-react-parser';

import { Button } from 'ui/Buttons/Button';
import { ModalHeader, Modal } from 'ui/Modal';

import { QuestionPreviewBlock } from '../../correction/active-correction-group/questions-correction-blocks/components/RelatedQuestions/QuestionPreview/QuestionPreviewBlock';
import { useIsSimilarQuestionAlreadySelected } from '../../correction/active-correction-group/questions-correction-blocks/components/RelatedQuestions/useIsSimilarQuestionAlreadySelected';
import { useToggleSimilarQuestionInCart } from '../../correction/active-correction-group/questions-correction-blocks/components/RelatedQuestions/useToggleSimilarQuestionInCart';
import { formatSimilarQuestionTitle } from '../../correction/active-correction-group/questions-correction-blocks/components/RelatedQuestions/helpers';
import { buttonReset } from 'helpers/css/button-reset';
import { CorrectionThemeProvider } from 'components/CorrectionThemeProvider';
import { StyledMathJax } from 'ui/MathJax';
import { SimilarQuestion } from 'types/correction.type';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  question: SimilarQuestion;
  nextQuestion: () => void;
  previousQuestion: () => void;
  canNextQuestion: boolean;
  canPreviousQuestion: boolean;
}

export const QuestionModal: FC<Props> = ({
  isOpen,
  closeModal,
  question,
  nextQuestion,
  previousQuestion,
  canNextQuestion,
  canPreviousQuestion,
}) => {
  const isQuestionAlreadySelected = useIsSimilarQuestionAlreadySelected(
    question!
  );
  const addQuestionToCart = useToggleSimilarQuestionInCart();

  return (
    <Modal isOpen={isOpen} onClose={closeModal} id="knowledge-question-modal">
      <ModalHeader onCloseButtonClick={closeModal}>
        <HeaderActions>
          <h4>{formatSimilarQuestionTitle(question)}</h4>
          <Toggle onClick={previousQuestion} disabled={!canPreviousQuestion}>
            ←
          </Toggle>
          <Toggle onClick={nextQuestion} disabled={!canNextQuestion}>
            →
          </Toggle>
        </HeaderActions>
      </ModalHeader>
      <ModalContent>
        <ScrollableContainer>
          <CorrectionThemeProvider>
            <Statement>
              <StyledMathJax inline>
                {parse(question?.statement ?? '')}
              </StyledMathJax>
            </Statement>
            {question && <QuestionPreviewBlock questionData={question} />}
          </CorrectionThemeProvider>
        </ScrollableContainer>

        <AddButton onClick={() => addQuestionToCart(question)}>
          {isQuestionAlreadySelected
            ? 'Supprimer de la sélection'
            : question?.group.type === 'QI'
            ? 'Ajouter la ' + question?.group.type + ' à la sélection'
            : 'Ajouter le ' + question?.group.type + ' à la sélection'}
        </AddButton>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const Statement = styled.div`
  width: 100%;
  margin-bottom: 8px;
`;

const AddButton = styled(Button)`
  margin-top: 32px;
`;

const ScrollableContainer = styled.div`
  overflow: auto;
  padding-right: 4px;
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Toggle = styled.button`
  ${buttonReset}
  height: 24px;
  width: 24px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.primary.dark};
  text-align: center;

  &:disabled {
    color: ${({ theme }) => theme.colors.inactive};
  }
`;
