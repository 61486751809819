import React, { useEffect, useState } from 'react';
import { ActiveTabType, useHomeInfoContext } from '../context/homeInfoContext';
import { ResearchOptions } from '../ui/ReseachOptions';
import { CardsContainerHeader } from '../ui/Global';
import { FlexDivJustify, SpeIcon } from '../ui/StatCard';
import { StudyTours } from '../ui/StudyTours';
import { BackwardsIcon } from 'pages/compass/component/BackwardsButton';
import styled from 'styled-components';
import { Star } from 'ui/icons/Star';
import { useGetItem } from '../hooks/useGetItems';
import { device } from 'helpers/css/responsive';
import { UnderlinedTabSwitch } from 'components/Tabs/UnderlinedTabSwitch';
import { useAccount } from 'pages/account/useAccount';
import { useUpdateItemCard, useUpdateSpeCard } from 'http/new-home/connector';

export const TopPageContent = () => {
  const {
    shownPage,
    goToHome,
    activeTab,
    handleChangeTab,
    activeItem,
    activeSpecialty,
    isLoading,
  } = useHomeInfoContext();

  const { updateUserOptions, userOptions } = useAccount();
  const item = useGetItem(activeItem?.item.item);
  //optmistic
  const [isFavorite, setIsFavorite] = useState(true);
  const [clickedTour, setClickedTour] = useState(0);

  const { updateSpeCard } = useUpdateSpeCard();
  const { updateItemCard } = useUpdateItemCard();

  // useEffect to compensate loading time
  useEffect(() => {
    if (activeTab === 'specialties' && activeSpecialty) {
      setIsFavorite(activeSpecialty.favorite);
      setClickedTour(activeSpecialty.round);
    }
    if (activeTab === 'items' && activeItem) {
      setIsFavorite(activeItem.favorite);
      setClickedTour(activeItem.round);
    }
  }, [activeTab, activeItem, activeSpecialty]);

  const handleTabClick = (clickedTab: ActiveTabType) => {
    handleChangeTab(clickedTab);
  };

  const handleUpdateUserOptions = (clickedTab: ActiveTabType) => {
    updateUserOptions({ ...userOptions, home_preferred_tabs: clickedTab });
  };

  const handleSetFavorite = () => {
    setIsFavorite(!isFavorite);
    if (activeTab === 'specialties' && activeSpecialty) {
      updateSpeCard({
        specialty: activeSpecialty.specialty.trigram,
        favorite: !activeSpecialty.favorite,
      });
    } else if (activeTab === 'items' && activeItem) {
      updateItemCard({
        item: activeItem.item.item,
        favorite: !activeItem.favorite,
      });
    }
  };

  const handleTourClick = (newTour: number) => {
    setClickedTour(newTour);
    if (activeTab === 'specialties' && activeSpecialty) {
      updateSpeCard({
        specialty: activeSpecialty.specialty.trigram as string,
        round: newTour,
      });
    }
    if (activeTab === 'items' && activeItem) {
      updateItemCard({
        item: activeItem.item.item as number,
        round: newTour,
      });
    }
  };

  if (shownPage === 'main') {
    return (
      <>
        <UnderlinedTabSwitch
          tabOptions={[
            { value: 'specialties', label: 'Spécialités' },
            { value: 'items', label: 'Items' },
          ]}
          activeTab={activeTab}
          handleTabClick={handleTabClick}
          updatePreferredTab={handleUpdateUserOptions}
          enableUnlock={true}
          userPreferredTab={userOptions?.home_preferred_tabs}
        />
        <ResearchOptions />
      </>
    );
  } else {
    return (
      <FlexDivJustify>
        <CardsContainerHeader onClick={() => goToHome()}>
          <BackwardsIcon />
          {isLoading ? (
            <Title>Chargement...</Title>
          ) : (
            <>
              <Title>
                {activeTab === 'specialties'
                  ? activeSpecialty?.specialty.name
                  : `${item.item?.item} - ${item?.item?.short_title}`}
              </Title>
              {activeTab === 'specialties' && (
                <SpeIcon
                  trigram={activeSpecialty?.specialty.trigram as string}
                />
              )}
            </>
          )}

          <Star
            isSelected={isFavorite}
            onClick={(e) => {
              e.stopPropagation();
              handleSetFavorite();
            }}
          />
        </CardsContainerHeader>
        <StudyTours
          activeTourNumber={clickedTour}
          handleClick={handleTourClick}
          big
        />
      </FlexDivJustify>
    );
  }
};

const Title = styled.h3`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary.dark};
  @media ${device.mobile} {
  }
`;
