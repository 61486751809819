import { useGetSessionListRecap } from 'http/selection/session-list/connector';
import { useGetSessionRecap } from 'modules/recap/hooks/useGetSessionrecap';
import { SessionDropdown } from 'pages/home/components/TrainingStats/SessionDropdown';
import { ProgressBarStat } from 'components/StatsElements/ProgressBarStats';
import { FC, useEffect } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { Grade } from './components/Grade';
import { Rank } from './components/Rank';
import { getSessionProgress } from '../helpers/getSessionProgress';
import { useSessionContext } from '../sessionContext';
import { Button } from 'ui/Buttons/Button';
import { useSearchParams } from 'react-router-dom';

interface Props {}

export const SessionProgress: FC<Props> = () => {
  const theme = useTheme();

  const { selectedSession, setSelectedSession } = useSessionContext();
  const sessions = useGetSessionListRecap(false);

  const { data: sessionRecap } = useGetSessionRecap(
    selectedSession
      ? {
          collection: selectedSession.value.collection,
        }
      : undefined
  );

  const [searchParams] = useSearchParams();
  const selectedSessionUrl = searchParams.get('selectedSession');
  const currentViewUrl = searchParams.get('currentView');

  useEffect(() => {
    if (sessions.selectSessionList) {
      const session = sessions.selectSessionList.find(
        (session) => session.label === selectedSessionUrl
      );
      if (session) {
        setSelectedSession(session);
        return;
      }
      if (currentViewUrl === 'sessionDetail') {
        setSelectedSession(sessions.selectSessionList[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSessionUrl, sessions.selectSessionList]);

  const sessionProgress = getSessionProgress(sessionRecap);
  return (
    <Container>
      <SessionDropdownContainer>
        <SessionDropdown
          options={sessions.selectSessionList}
          value={selectedSession}
          onChange={(newValue) =>
            newValue ? setSelectedSession(newValue) : null
          }
        />
      </SessionDropdownContainer>

      <ProgressContainer>
        <h3>{sessionProgress || 0}%</h3>
        <BarContainer>
          <ProgressBarStat
            fillingColor={theme.colors.primary.dark}
            backgroundFillingColor={theme.colors.primary.main}
            percentage={sessionProgress || 0}
            height={16}
          />
        </BarContainer>
      </ProgressContainer>

      <InfosRow>
        <InfoContainer>
          <h2>
            <Grade sessionRecap={sessionRecap} />
          </h2>
        </InfoContainer>
        <InfoContainer>
          <h2>
            <Rank
              sessionRecap={sessionRecap}
              collection={selectedSession?.value.collection}
            />
          </h2>
          <EasyDispoLink
            href={process.env.REACT_APP_EASY_DISPO_LINK}
            rel="noreferrer"
          >
            <Button small>Easy Dispo</Button>
          </EasyDispoLink>
        </InfoContainer>
      </InfosRow>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 100%;

  padding: 0 var(--horizontal-padding);
`;

const SessionDropdownContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  width: 100%;
`;

const BarContainer = styled.div`
  flex-grow: 1;
`;

const InfosRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;
  gap: 16px;
`;

const EasyDispoLink = styled.a`
  text-decoration: none !important;
`;
