import styled, { css } from 'styled-components/macro';

import { device } from 'helpers/css/responsive';
import { routes } from 'routing/constants';

import { useMenuContext } from '../menuContext';
import { menuOpenTransitionConfig } from '../cssTransitions';
import { MenuItem } from './components/MenuItem';
import { MenuGroup } from './components/MenuGroup';
import { MenuLogout } from '../../../ui/icons/MenuIcons/MenuLogout';
import { MenuStart } from '../../../ui/icons/MenuIcons/MenuStart';
import { useGetMenuElements } from './menuElements';
import { CloseMenuOverlay } from './components/CloseMenuOverlay';
import { useTour } from '@reactour/tour';
import { useLocation, useNavigate } from 'react-router-dom';

export const Menu = () => {
  const { isMenuExpanded } = useMenuContext();
  const menuElements = useGetMenuElements();
  const { setIsOpen } = useTour();
  const navigate = useNavigate();
  const location = useLocation();

  const tutoLocations = [
    '/',
    '/selection',
    '/progress',
    '/start',
    '/correction',
    '/flash-questions',
  ];

  const openTuto = () => {
    if (tutoLocations.includes(location.pathname)) {
      setIsOpen(true);
    } else {
      navigate('/');
      setIsOpen(true);
    }
  };

  return (
    <>
      <MenuSpacingPlaceholder />
      <CloseMenuOverlay />
      <MenuContainer isExpanded={isMenuExpanded}>
        {menuElements.map((menuGroup, index) => (
          <MenuGroup key={index}>
            {menuGroup.map((menuElement) => (
              <MenuItem
                icon={menuElement.icon}
                text={menuElement.text}
                to={menuElement.to}
                isHighlighted={menuElement.isHighlighted}
                isNew={menuElement.isNew}
                key={menuElement.text}
                id={`menu-item-${menuElement.text.replace(/\s/g, '')}`}
              />
            ))}
          </MenuGroup>
        ))}

        <LogoutItem>
          <MenuItem
            icon={MenuStart}
            text="Tutoriel"
            to={''}
            id={'menu-item-tutorial'}
            isLinkToPage={false}
            onClick={() => openTuto()}
          />
        </LogoutItem>

        <LogoutItem>
          <MenuItem
            icon={MenuLogout}
            text="Déconnexion"
            id={'menu-item-logout'}
            to={routes.LOGOUT}
            isLinkToPage={false}
          />
        </LogoutItem>
      </MenuContainer>
    </>
  );
};

const shrinkedMenuWidth = css`
  width: 60px;
`;

/**
 * The actual menu is absolutely positionned to be
 * able to grow without pushing the content. Therefore
 * we need to add a placeholder on the page to mock the
 * shrinked menu spacing.
 */
const MenuSpacingPlaceholder = styled.div`
  display: none;

  @media ${device.desktop} {
    display: block;
    flex-shrink: 0;
    ${shrinkedMenuWidth};
  }
`;

const MenuContainer = styled.div<{ isExpanded: boolean }>`
  height: 100%;
  width: 0;
  overflow-y: auto;
  overflow-x: hidden;

  @media (hover: hover) {
    overflow: hidden;
    &:hover {
      overflow-y: auto;
    }
  }

  padding: 16px 8px;
  /* Make the menu invisible when it is shrinked mobile */
  padding-right: 0;
  padding-left: 0;
  @media ${device.desktop} {
    padding: 16px 8px;
    ${shrinkedMenuWidth};
  }

  display: flex;
  flex-direction: column;
  gap: 26px;

  position: absolute;
  left: 0;
  top: 0;

  background-color: ${({ theme }) => theme.colors.primary.main};
  z-index: ${({ theme }) => theme.zIndex.menu};

  transition-property: width, padding-right, padding-left, gap;
  ${menuOpenTransitionConfig};

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      width: 196px;
      padding: 16px 8px;

      @media ${device.desktop} {
        width: 280px;
        padding: 16px 8px;
      }
    `}

  --scroll-background: ${({ theme }) => theme.colors.primary.light};
  --scroll-bar: #999999;
  /* Works on Firefox */
  & {
    scrollbar-width: thin;
    scrollbar-color: var(--scroll-bar) var(--scroll-background);
  }

  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--scroll-background);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scroll-bar);
    border-radius: 20px;
    border: 1px solid var(--scroll-background);
  }
`;

const LogoutItem = styled.div`
  margin-top: auto;
`;
