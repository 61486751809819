import styled from 'styled-components/macro';
import {
  useState,
  FC,
  useEffect,
  ReactElement,
  useRef,
  useCallback,
} from 'react';
import { device } from 'helpers/css/responsive';
import { useIsUserPremium } from 'modules/user-options/hooks/useIsUserPremium';
import { ButtonBase } from 'ui/Buttons/ButtonBase';
import { LockIcon } from 'ui/icons/Lock';
import { PromotionModal } from '../../Modales/PromotionModal';
import Tippy from '@tippyjs/react';
import { SubscribeButton } from 'ui/Buttons/SubscribeButton';
import { toast } from 'react-toastify';
import { Countdown } from 'components/Countdown';
import { CCBPendingTable } from '../../TrainingTable/CCBPendingTable';
import BlueCard from 'ui/Cards/BlueCard';
import { useAccount } from 'pages/account/useAccount';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateAllDone,
  updateHasUpdatedPromotion,
  updateScreenToDisplay,
  updateNextTask,
} from 'store/slices/ccbSlice';
import { CCBScreenType, FormatedCCBSession } from 'http/ccb/type';
import { useLauchCCBTask } from 'pages/start/hooks/useLaunchCCBTask';
import { routes } from 'routing/constants';
import { useNavigate } from 'react-router-dom';
import { resetHasUpdatedPromotion } from 'store/slices/ccbSlice';
import { hasSixMonthsPassed, isPastDate } from 'helpers/dates/utils';
import { useGetCCBUnfinishedTasks } from 'http/task-history/connector';
import { RootState } from 'store';

interface WrapperProps {
  activateHover: boolean;
  children: ReactElement<any>;
}

const Wrapper: FC<WrapperProps> = ({ activateHover, children }) => {
  return (
    <>
      {activateHover ? (
        <Tippy
          content={"Vous devez être utilisateur premium pour lancer l'épreuve"}
          arrow={false}
          placement="bottom"
        >
          <div style={{ display: 'inline-block' }}>{children}</div>
        </Tippy>
      ) : (
        children
      )}
    </>
  );
};

export const TrainingLaunch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { startCCBTask, isLoading } = useLauchCCBTask();
  const { isUserPremium } = useIsUserPremium();
  const { data: unfinishedCCBTasks } = useGetCCBUnfinishedTasks();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [seconds, setSeconds] = useState<number | undefined>(undefined);
  // const [selectedPromotion, setSelectedPromotion] = useState<
  //   string | undefined
  // >(undefined);
  const { updateUserOptions, userOptions } = useAccount();
  const { activeCcb } = useSelector((state: RootState) => state.ccb);
  const [activateButton, setActiviteButton] = useState<boolean>(false);
  const isStartingTask = useRef(false);

  // redirect if done
  useEffect(() => {
    if (activeCcb && activeCcb.sessions && activeCcb.sessions.length > 0) {
      const isdone =
        activeCcb?.sessions?.filter(
          (session: FormatedCCBSession) => session.finished
        )?.length === activeCcb?.sessions?.length;
      if (isdone) {
        dispatch(updateAllDone({ ccbId: activeCcb.id, value: true }));
        dispatch(updateScreenToDisplay(CCBScreenType.PostCCB));
      }
    }
  }, [activeCcb, dispatch]);

  // check promotion updated at
  useEffect(() => {
    if (
      activeCcb &&
      activeCcb.promotions_updated_at &&
      hasSixMonthsPassed(activeCcb.promotions_updated_at)
    ) {
      resetHasUpdatedPromotion({ ccbId: activeCcb.id });
    }
  }, [activeCcb]);

  // define if user has permission to launch CCB
  useEffect(() => {
    if (
      activeCcb &&
      (!isPastDate(activeCcb.start_date) ||
        (isPastDate(activeCcb.end_date) && !isUserPremium))
    ) {
      setActiviteButton(false);
    } else {
      setActiviteButton(true);
    }
  }, [activateButton, activeCcb, isLoading, isUserPremium]);

  const handleButtonClick = () => {
    if (isStartingTask.current) {
      return;
    }
    // if user is free and not within CCB dates :
    if (activeCcb && !isUserPremium && isPastDate(activeCcb.end_date)) {
      toast.info("Vous devez être utilisateur premium pour lancer l'épreuve");
    } else if (
      activeCcb &&
      !activeCcb.hasUpdatedPromotion
      // &&
      // activeCcb.promotions &&
      // activeCcb.promotions.length > 0
    ) {
      // si promotion pas encore updated ouvrir modale
      setModalOpen(true);
    } else if (activeCcb && activeCcb.hasUpdatedPromotion === true) {
      // si promotion updated
      handleModalClick();
    }
  };

  const handleModalClick = (selectedPromotion?: string | undefined) => {
    setModalOpen(!isLoading);
    setModalOpen(false);
    setSeconds(5);

    // send promotion call API call patch auth/user
    if (selectedPromotion) {
      if (userOptions) {
        updateUserOptions({ ...userOptions, promotion: selectedPromotion });
        toast.info('maintemant redirection sur Training');
      }
      dispatch(
        updateHasUpdatedPromotion({
          ccbId: activeCcb?.id,
          chosenPromotion: selectedPromotion,
        })
      );
      toast.info('Votre promotion a bien été prise en compte.');
    }
  };

  const handleAfterCountdown = useCallback(async () => {
    //if not starting task return
    if (isStartingTask?.current) return;
    isStartingTask.current = true;

    //if next session not started
    if (
      activeCcb &&
      activeCcb.nextTask &&
      activeCcb.sessionsLeftToDo &&
      activeCcb.sessionsLeftToDo[0].hasBeenStarted &&
      activeCcb.sessionsLeftToDo[0].finished === false &&
      activeCcb.sessionsLeftToDo[0].composition_unit ===
        activeCcb.nextTask.composition_unit
    ) {
      navigate({
        pathname: routes.CCBTRAINING,
        search: `?id=${activeCcb.nextTask.id}`,
      });
      //if next session not finished
    } else if (
      activeCcb &&
      unfinishedCCBTasks &&
      unfinishedCCBTasks.length > 0
    ) {
      const lastTask = unfinishedCCBTasks[unfinishedCCBTasks.length - 1];
      if (lastTask && lastTask.id !== undefined) {
        const taskId = lastTask.id;
        dispatch(
          updateNextTask({
            ccbId: activeCcb?.id,
            task: { id: taskId, finished: false },
          })
        );
        navigate({ pathname: routes.CCBTRAINING, search: `?id=${taskId}` });
      } else {
        navigate({ pathname: routes.CONCOURS_BLANC });
      }
    } else {
      await startCCBTask();
      isStartingTask.current = false;
      setSeconds(undefined);
    }
    setActiviteButton(false);
  }, [activeCcb, unfinishedCCBTasks, navigate, dispatch, startCCBTask]);

  return (
    <Container>
      <h3>Épreuve en attente de lancement</h3>
      <CCBPendingTable showAll={false} />
      <Center>
        <Wrapper activateHover={!activateButton}>
          <ButtonBase
            disabled={!activateButton || isLoading}
            size={'large'}
            styled={'primary'}
            iconOnly={false}
            onClick={handleButtonClick}
            hasPadding={true}
          >
            Lancer l'épreuve
            {activeCcb !== null &&
              isPastDate(activeCcb.end_date) &&
              !isUserPremium && (
                <IconContainer>
                  <LockIcon />
                </IconContainer>
              )}
          </ButtonBase>
        </Wrapper>
        {activeCcb?.end_date &&
          isPastDate(activeCcb.end_date) &&
          !isUserPremium && <SubscribeButton />}
        <PromotionModal isOpen={modalOpen} handleClick={handleModalClick} />
      </Center>

      {seconds && (
        <BlueCard fullWidth>
          <LaunchContainer>
            Cet écran disparaitra automatiquement dès le lancement de l'épreuve
            :
            <Countdown seconds={seconds} callback={handleAfterCountdown} />
          </LaunchContainer>
        </BlueCard>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const IconContainer = styled.div<{ big?: boolean }>`
  height: 21px;
  width: 21px;
  flex-shrink: 0;
  margin-top: -8px;
`;

const Center = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const LaunchContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 8px;
  min-width: 100%;
`;
