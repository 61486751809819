import { FC, useState } from 'react';
import { ArrowUp } from 'pages/correction/components/icons/ArrowUp';
import { ArrowDown } from 'pages/correction/components/icons/ArrowDown';
import { useHomeInfoContext } from '../context/homeInfoContext';
import {
  CardsContainer,
  CardsContainerHeader,
  GlobalContainer,
  SmallSectionTitle,
} from './Global';

interface AllElementsPaginatedTabProps {
  children?: React.ReactNode;
}

export const AllElementsPaginatedTab: FC<AllElementsPaginatedTabProps> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const { activeTab } = useHomeInfoContext();

  return (
    <GlobalContainer>
      <CardsContainerHeader
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {isOpen ? <ArrowUp /> : <ArrowDown />}
        <SmallSectionTitle>
          {activeTab === 'specialties'
            ? 'Toutes les spécialités'
            : 'Tous les items'}
        </SmallSectionTitle>
      </CardsContainerHeader>
      {isOpen && <CardsContainer fullWidth>{children}</CardsContainer>}
    </GlobalContainer>
  );
};
