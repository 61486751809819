import styled from 'styled-components';
import { LockIcon } from 'ui/icons/Lock';
import { AccessSticker } from 'components/Stickers/AccessSticker';
import { useIsUserFree } from 'modules/user-options/hooks/useIsUserPremium';
import {
  availableDateFormat,
  formatDate,
  isCurrentDateTimeInRange,
  isPastDate,
} from 'helpers/dates/utils';
import BlueCard from 'ui/Cards/BlueCard';
import { GradeContainer } from './GradeContainer';
import { ParticipantsContainer } from './ParticipantsContainer';
import { MenuAccount } from 'ui/icons/MenuIcons/MenuAccount';
import { RightChevron } from 'ui/icons/Arrows/RightChevron';
import { CCBScreenType, selectedCCB } from 'http/ccb/type';
import { FC } from 'react';
import {
  fetchFormatedSessionsStatus,
  updateScreenToDisplay,
  updateActiveCcb,
  fetchFormatedCCBStats,
} from 'store/slices/ccbSlice';
import { useDispatch } from 'react-redux';
import { useGetEstimatedDurations } from 'modules/user-options/hooks/useGetEstimatedDurations';
import { useGetUserOptions } from 'modules/user-options/hooks/useGetUserOptions';
import { useGetFastUserOptions } from 'http/account/connector';

interface Props {
  ccb: selectedCCB;
  type: 'limited' | 'available';
}

interface Props {
  ccb: selectedCCB;
  type: 'limited' | 'available';
}

export const Session: FC<Props> = ({ ccb, type }) => {
  const estimatedDurations = useGetEstimatedDurations();
  const { data: userOptions } = useGetUserOptions();
  const user = useGetFastUserOptions(); // Could be null if still loading
  const isUserFree = useIsUserFree();
  const dispatch = useDispatch();

  const handleRedirect = () => {
    if (!user || !ccb) return;
    if (ccb.permission === 'private' && !ccb.private_users?.includes(user.pk)) {
      return;
    }
    dispatch(updateActiveCcb(ccb));
    if (userOptions && estimatedDurations) {
      dispatch(
        fetchFormatedSessionsStatus({
          ccbId: ccb.id,
          ucDuration: estimatedDurations.ucDuration,
          trainingDuration: userOptions.trainingDuration,
        })
      );
    }
    // if all tasks are completed
    if (ccb.completed_tasks === 4) {
      if (isPastDate(ccb.end_date) && ccb.correction) {
        dispatch(
          fetchFormatedCCBStats({
            ccbId: ccb.id,
            promotion: user.promotion,
          })
        );
        dispatch(updateScreenToDisplay(CCBScreenType.Statistics));
      } else {
        dispatch(updateScreenToDisplay(CCBScreenType.PostCCB));
      }
    } else {
      if (estimatedDurations && userOptions) {
        dispatch(
          fetchFormatedSessionsStatus({
            ccbId: ccb.id,
            ucDuration: estimatedDurations.ucDuration,
            trainingDuration: userOptions.trainingDuration,
          })
        );
      }

      // If ccb is open for free users
      if (ccb.lastTask) {
        // Just finished last task => PostCCB
        dispatch(updateScreenToDisplay(CCBScreenType.PostCCB));
      } else {
        // By default => Launch Screen
        dispatch(updateScreenToDisplay(CCBScreenType.CCBLauchScreen));
      }
    }
  };

  // If user is not on the private list, hide the card
  if (ccb.permission === 'private' && !ccb.private_users?.includes(user?.pk!)) {
    return null;
  }

  const status = isCurrentDateTimeInRange(ccb.start_date, ccb.end_date)
    ? 'free'
    : // Ongoing => free
    !isPastDate(ccb.end_date) && !isUserFree
    ? 'unvisible'
    : 'limited';

  return (
    <BlueCard fullWidth $mediumPadding>
      <ContentContener>
        <Row>
          <Left>
            <label>{ccb.collection.label}</label>
            {isUserFree && <AccessSticker status={status} />}
            {isPastDate(ccb.end_date) && isUserFree && <StyledLockIcon />}
          </Left>
          <Right>{availableDateFormat(ccb.start_date, ccb.end_date)}</Right>
        </Row>

        {isPastDate(ccb.end_date) &&
          !ccb.correction &&
          'ℹ️ Corrections en cours, les copies et résultats seront bientôt disponibles'}

        <Row $alignBottom>
          <Left>
            <GradesContainer>
              {(!isPastDate(ccb.end_date) || !ccb.correction) && (
                <>
                  <RanksContainer>
                    <h5>{ccb.completed_tasks ?? 0}/4</h5>
                    <StyledText>
                      {ccb.completed_tasks === 4
                        ? `Vous avez terminé toutes les épreuves${
                            ccb.completed_date
                              ? ` au ${formatDate(ccb.completed_date)}`
                              : ''
                          }`
                        : 'Épreuves réalisées'}
                    </StyledText>{' '}
                  </RanksContainer>
                  <Separator />
                </>
              )}
              <IconContainer>
                <MenuAccount />
              </IconContainer>
              <ParticipantsSection>
                <ParticipantsContainer
                  periodParticipants={ccb.period_participants}
                  totalParticipants={
                    type === 'limited' ? ccb.total_participants : undefined
                  }
                />
              </ParticipantsSection>
              {isPastDate(ccb.end_date) &&
                ccb.correction &&
                ccb.completed_tasks === 4 && (
                  <>
                    <Separator />

                    <GradeContainer
                      text={`Ma note globale avec coefficient 1 pour le rang B - épreuves terminées au ${formatDate(
                        ccb.end_date
                      )}`}
                      grade={ccb.user_global_grade}
                    />
                    <Separator />
                    <GradeContainer
                      text="Note globale moyenne avec coefficient 1 pour le rang B - sur la période"
                      grade={ccb.period_average}
                    />
                  </>
                )}
            </GradesContainer>
          </Left>
          <Right>
            <LinkContainer onClick={() => handleRedirect()}>
              {isPastDate(ccb.end_date) && ccb.completed_tasks === 4
                ? 'Plus de détail'
                : 'Vers les épreuves'}{' '}
              <StyledRightChevron />
            </LinkContainer>
          </Right>
        </Row>
      </ContentContener>
    </BlueCard>
  );
};

const ContentContener = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 1.5rem;
`;

const Row = styled.div<{ $alignBottom?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: ${(props) => (props.$alignBottom ? 'flex-end' : 'center')};
  justify-content: space-between;
  height: 100%;
`;

const ParticipantsSection = styled.div``;

const GradesContainer = styled.div`
  display: flex;
  padding: 12px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Light-Border-grey, #7f8a95);
`;

const StyledLockIcon = styled(LockIcon)`
  width: 20px;
  height: 20px;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 1rem;
`;

const Separator = styled.div`
  width: 0.5px;
  background-color: var(--Light-Border-grey, #7f8a95);
  height: 90%;
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #a22f2b;
  cursor: pointer;
`;

const RanksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const IconContainer = styled.div`
  height: 32px;
  width: 32px;
  flex-shrink: 0;
`;

const StyledRightChevron = styled(RightChevron)`
  width: 16px;
  height: 16px;
  color: inherit;
`;

const StyledText = styled.div`
  color: ${({ theme }) => theme.colors.inactive};
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;
