import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useSortOptions } from 'pages/selection/hooks/useSortOptions';
import { useSearchbarContext } from 'pages/selection/components/Searchbar/SearchbarContext';
import { selectDeck } from 'store/slices/fqCurrentSlice';
import { useInfiniteQuery, useQuery } from 'react-query';
import { getFQCardsFromAPI } from 'http/flash-questions/fqdeckcreation/api';

export function useGetFQTableValues() {
  const deck = useSelector(selectDeck);
  const [formatedData, setFormatedData] = useState<any>([]);
  const selectedCardsIds = useMemo(
    () => deck?.cards.map((card) => card.question) ?? [],
    [deck]
  );

  const filters = useSelector((state: RootState) => state.selectionFilters);
  const pageSize = useSelector((state: RootState) => state.fqTable.pageSize);
  const { isAscending, sortColumn } = useSortOptions((state) => state.fqTable);
  const { debouncedSearchTerm } = useSearchbarContext();

  const getParams = (pageParam = 1) => ({
    page: pageParam,
    page_size: pageSize,
    collection: filters.collections,
    sort_column: sortColumn,
    sort_ascending: isAscending,
    specialty: filters.specialities,
    rank: filters.rank,
    item: filters.items,
    tag: filters.categories,
    type: filters.type,
    search: debouncedSearchTerm,
    done: filters.showDone,
  });

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isError,
    refetch,
  } = useInfiniteQuery(
    [
      'fqTableData',
      filters,
      sortColumn,
      isAscending,
      pageSize,
      debouncedSearchTerm,
    ],
    ({ pageParam = 1 }) => getFQCardsFromAPI(getParams(pageParam)),
    {
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage) return undefined;
        const nextPage = pages.length + 1;
        return lastPage.count > pages.length * pageSize ? nextPage : undefined;
      },
    }
  );

  const { data: selectedItems } = useQuery(
    ['selectedFQItems', selectedCardsIds],
    () =>
      getFQCardsFromAPI({
        type: ['QRM', 'QRU', 'QRP', 'QRPL', 'QZP', 'TCS', 'QROC'],
        question_ids: selectedCardsIds.filter(
          (id): id is number => id !== null
        ),
      }),
    {
      enabled: selectedCardsIds.length > 0,
    }
  );

  useEffect(() => {
    if (!data) return;

    const allResults = data.pages.flatMap((page) => page.results);
    const selectedResults = selectedItems?.results ?? [];

    const filteredResults = allResults.filter(
      (item) => !selectedCardsIds.includes(item.id)
    );

    const combinedResults = [
      ...selectedResults.map((row) => ({
        ...row,
        due: deck?.cards.find((card) => card.question === row.id)?.due,
      })),
      ...filteredResults.map((row) => ({ ...row, due: null })),
    ];

    setFormatedData(combinedResults);
  }, [data, selectedItems, deck, selectedCardsIds]);

  return {
    formatedData,
    isLoading,
    isError,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
}
