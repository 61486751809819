import { FC, useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import parse from 'html-react-parser';
import { Lesson } from 'types/lesson.type';
import { PremiumLockedRessource } from 'components/PremiumLockedRessource';
import { SubRow, SubRowContent } from './uiElements';
import { StyledMathJax } from 'ui/MathJax';
import { Button } from 'ui/Buttons/Button';
import { useGetLessonRelatedQuestion } from 'http/lessons-questions/course-questions/connector';
import { QuestionModal } from '../../../correction/active-correction-group/questions-correction-blocks/components/RelatedQuestions/QuestionModal';
import { SimilarQuestion } from 'types/correction.type';
import { BookCard } from 'components/Book/BookCard';

interface OpenedRowProps {
  index: number;
  lesson: Lesson;
}

export const OpenedRow: FC<OpenedRowProps> = ({ index, lesson }) => {
  const { data: formatedData } = useGetLessonRelatedQuestion(lesson.lesson);

  const [shownQuestion, setShownQuestion] = useState<
    SimilarQuestion | undefined
  >();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const currentQuestionIndex = useMemo(() => {
    const currentQuestionIndex = formatedData.findIndex(
      (q) => q.id === shownQuestion?.id
    );
    if (!currentQuestionIndex) return 0;
    return currentQuestionIndex;
  }, [formatedData, shownQuestion]);

  const canNextQuestion = useMemo(() => {
    return currentQuestionIndex < formatedData.length - 1;
  }, [currentQuestionIndex, formatedData.length]);

  const canPreviousQuestion = useMemo(() => {
    return currentQuestionIndex > 0;
  }, [currentQuestionIndex]);

  const nextQuestion = () => {
    if (canNextQuestion) {
      setShownQuestion(formatedData[currentQuestionIndex + 1]);
    }
  };

  const previousQuestion = () => {
    if (canPreviousQuestion) {
      setShownQuestion(formatedData[currentQuestionIndex - 1]);
    }
  };
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleBookClick = (spe: string) => {
    const url = `/?selected=specialties&activeSpeciality=${spe}`;
    window.open(url, '_blank');
  };

  return (
    <>
      <SubRow>
        <SubRowContent isColored={index % 2 === 0}>
          <PremiumLockedRessource
            ressourceName="Contenu du cours réservé aux abonnés"
            isFree={lesson.isFree}
          >
            <ImageSizeConstraint>
              <StyledMathJax inline>
                {parse(lesson.content || '')}
              </StyledMathJax>
            </ImageSizeConstraint>
            <BottomContainer>
              {lesson.books.length > 0 && (
                <BooksContainer>
                  <Title>LIVRES</Title>
                  {lesson.books.map((book) => (
                    <BookCard
                      title={book.title}
                      handleClick={() => handleBookClick(book.specialty)}
                      isBlue
                    />
                  ))}
                </BooksContainer>
              )}
              {lesson.sources.length > 0 && (
                <SourceContainer>
                  <Title>SOURCES</Title>
                  {lesson.sources.map((source) => (
                    <a
                      key={source.title}
                      href={source.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {source.title}
                    </a>
                  ))}
                </SourceContainer>
              )}
            </BottomContainer>
            {formatedData.length > 0 && (
              <ButtonContainer>
                <Button
                  onClick={() => {
                    setShownQuestion(formatedData[0]);
                    openModal();
                  }}
                >
                  <>Voir les questions</>
                </Button>
              </ButtonContainer>
            )}
          </PremiumLockedRessource>
        </SubRowContent>
        {shownQuestion && (
          <QuestionModal
            isOpen={isModalOpen}
            closeModal={closeModal}
            question={shownQuestion}
            canNextQuestion={canNextQuestion}
            canPreviousQuestion={canPreviousQuestion}
            nextQuestion={nextQuestion}
            previousQuestion={previousQuestion}
          />
        )}
      </SubRow>
    </>
  );
};

const ImageSizeConstraint = styled.div`
  img {
    max-width: 100%;
    height: auto;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 40px;
`;

const BooksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 40%;
`;

const SourceContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-left: 1rem;
`;

const Title = styled.div`
  margin-bottom: 16px;
`;

const ButtonContainer = styled.div`
  margin: 40px 0 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
