import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCCBList } from 'store/slices/ccbSlice';
import { DisplayedPage } from './DisplayedPage';

export const ConcoursBlanc = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCCBList());
  }, [dispatch]);

  return <DisplayedPage />;
};
