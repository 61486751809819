import { FC } from 'react';
import styled from 'styled-components/macro';

import { Button } from 'ui/Buttons/Button';
import { useGetQIDetail } from 'http/progress/detail/qi/connector';

import { useAddToLocalCartContext } from '../contexts/addToLocalCartContext';
import { useIsQIAlreadySelected } from '../hooks/useIsQIAlreadySelected';
import { useToggleQIInCart } from '../hooks/useToggleQIInCart';

interface Props {
  qiId: number;
}

export const AddQIToSelectionButton: FC<Props> = ({ qiId }) => {
  const { data: question } = useGetQIDetail(qiId);

  const isQuestionAlreadySelected = useIsQIAlreadySelected(
    question ?? { id: -1, type: 'QI' }
  );
  const toggleQuestionInCart = useToggleQIInCart();

  const { isQuestionInLocalSelection, toggleInLocalCart } =
    useAddToLocalCartContext();

  const toggleQuestion = () => {
    if (question === undefined) return;

    if (isQuestionInLocalSelection(qiId)) {
      toggleInLocalCart(qiId);
    }

    toggleQuestionInCart(question);
  };

  return (
    <AddButton onClick={toggleQuestion}>
      {isQuestionAlreadySelected
        ? 'Supprimer le QI de la sélection'
        : 'Ajouter le QI à la sélection'}
    </AddButton>
  );
};

const AddButton = styled(Button)`
  margin-top: 32px;
`;
