import { FC, memo } from 'react';
import styled from 'styled-components/macro';

import { Button } from 'ui/Buttons/Button';
import { ModalHeader, Modal } from 'ui/Modal';

import { useGetUserOptions } from 'modules/user-options/hooks/useGetUserOptions';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { setFQParameters } from 'store/actions/fqSettingsActions';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}

interface InnerProps {
  max: number;
  review: number;
}
export interface FormProps {
  newCardsPerDay: number;
  reviewCardsPerDay: number;
}
const settingsSchema = yup.object({
  newCardsPerDay: yup
    .number()
    .required('Veuillez entrer un nom de paquet')
    .min(1, "Le nombre doit être d'au minimum 1 carte"),
  reviewCardsPerDay: yup
    .number()
    .required('Veuillez entrer un nom de paquet')
    .min(1, "Le nombre doit être d'au minimum 1 carte"),
});

const FQSettingsModal: FC<Props & InnerProps> = ({
  isOpen,
  closeModal,
  max,
  review,
}) => {
  const dispatch = useDispatch();

  const { register, handleSubmit } = useForm<FormProps>({
    resolver: yupResolver(settingsSchema),
    defaultValues: {
      newCardsPerDay: max,
      reviewCardsPerDay: review,
    },
  });

  const submit: SubmitHandler<FormProps> = (values) => {
    dispatch(setFQParameters(values));
    closeModal();
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalHeader onCloseButtonClick={closeModal}>
        <HeaderActions>
          <h4>Paramètres des questions flash</h4>
        </HeaderActions>
      </ModalHeader>
      <ModalContent>
        <form onSubmit={handleSubmit(submit)}>
          <Statement style={{ marginBottom: 20 }}>
            <p>
              Nombre de questions <strong>nouvelles</strong> par jour :
            </p>
            <Input type="number" {...register('newCardsPerDay')} />
          </Statement>

          <Statement>
            <p>
              Nombre de questions <strong>à refaire</strong> par jour :
            </p>
            <Input type="number" {...register('reviewCardsPerDay')} />
          </Statement>
          <Button style={{ margin: '0 auto', marginTop: 30 }} type="submit">
            Valider
          </Button>
        </form>
      </ModalContent>
    </Modal>
  );
};

const withDefaultValues = (Component: React.FC<Props & InnerProps>) => {
  const MemoComponent = memo(Component);

  const Wrapper = (props: Props) => {
    const { data: userOptions, isLoading } = useGetUserOptions();

    if (!userOptions || isLoading) return null;
    return (
      <MemoComponent
        max={userOptions.flashMaxNew}
        review={userOptions.flashMaxReview}
        {...props}
      />
    );
  };

  return memo(Wrapper);
};

export default withDefaultValues(FQSettingsModal);
const Input = styled.input`
  ${({ theme }) => theme.typography.label}
  padding: 10px 22px;
  border-radius: ${({ theme }) => theme.borderRadius.cta.desktop};
  border: 2px solid ${({ theme }) => theme.colors.primary.dark};
  width: 100%;
  font-weight: 400;
  -moz-appearance: textfield;
  background: transparent;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const ModalContent = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const Statement = styled.div`
  width: 100%;
  margin-bottom: 8px;
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
