import { useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { Layout } from 'ui/Layout/PageLayout';
import { LauchingPage } from './components/LaunchPage/LaunchingPage';
import { CompletedTraining } from './components/CompletedPage/CompletedTraining';
import { StatisticsPage } from './components/StatisticsPage/StatisticsPage';

import { MultiCCBInfoPage } from './components/MultiCCB/MultiCCBInfoPage';
import { RootState } from 'store';
import { CCBScreenType } from 'http/ccb/type';

export const DisplayedPage = () => {
  const { screenToDisplay, activeCcb } = useSelector(
    (state: RootState) => state.ccb
  );

  useEffect(() => {}, [activeCcb]);

  const PageToDisplay = useCallback(() => {
    switch (screenToDisplay) {
      case CCBScreenType.PreCCBInfo:
        return <MultiCCBInfoPage />;
      case CCBScreenType.CCBLauchScreen:
        return <LauchingPage />;
      case CCBScreenType.PostCCB:
        return <CompletedTraining />;
      case CCBScreenType.Statistics:
        return <StatisticsPage />;
      default:
        return <MultiCCBInfoPage />;
    }
  }, [screenToDisplay]);

  return (
    <Layout>
      <PageToDisplay />
    </Layout>
  );
};
