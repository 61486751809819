import {
  PendingTableContainer,
  FirstHeaderCell,
  HeaderCell,
  HeaderCellText,
  LastHeaderCell,
  Cell,
} from './uiElements';
import styled from 'styled-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { routes } from 'routing/constants';
import { useNavigate } from 'react-router-dom';
import { CopyLined } from 'ui/icons/CopyLined';
import { RootState } from 'store';
import { InfoForSession } from 'http/ccb/type';

export const CCBCopiesTable = () => {
  const navigate = useNavigate();
  const { activeCcb } = useSelector((state: RootState) => state.ccb);

  const handleClick = (correctionId: string) => {
    navigate({
      pathname: routes.TASK_CORRECTION,
      search: `?id=${correctionId}`,
    });
  };

  return (
    <PendingTableContainer>
      <FirstHeaderCell>
        <HeaderCellText>Session</HeaderCellText>
      </FirstHeaderCell>
      <HeaderCell>
        <HeaderCellText>Ma note</HeaderCellText>
      </HeaderCell>
      <HeaderCell>
        <HeaderCellText>Rang sur la période</HeaderCellText>
      </HeaderCell>
      <HeaderCell>
        <HeaderCellText>Rang général</HeaderCellText>
      </HeaderCell>
      <LastHeaderCell>
        <HeaderCellText>Correction</HeaderCellText>
      </LastHeaderCell>
      <>
        {activeCcb?.ccbCorrection &&
          activeCcb?.ccbCorrection?.composition_unit_ranking.length !== 0 &&
          activeCcb?.ccbCorrection?.composition_unit_ranking.map(
            (copy: InfoForSession, index: number) => (
              <React.Fragment key={copy.task_id + index}>
                <Cell>{copy.composition_unit}</Cell>
                <Cell>{copy.grade}</Cell>
                <Cell>
                  {copy.period_rank !== null ? copy.period_rank : '-'}
                </Cell>
                <Cell>{copy.global_rank}</Cell>
                <Cell>
                  {
                    <IconContainer
                      onClick={() => handleClick(copy?.task_id.toString())}
                    >
                      <CopyLined />
                    </IconContainer>
                  }
                </Cell>
              </React.Fragment>
            )
          )}
      </>
    </PendingTableContainer>
  );
};

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.primary.contrastText};
  background-color: ${({ theme }) => theme.colors.primary.dark};
`;
