import { RelatedQuestions } from 'pages/correction/active-correction-group/questions-correction-blocks/components/RelatedQuestions';
import React from 'react';
import { GreyCard } from 'ui/Cards/GreyCard';
import { StyledMathJax } from 'ui/MathJax';
import { FQQCMCorrectionMapper } from './FQQCMCorrectionMapper';
import { buttonReset } from 'helpers/css/button-reset';
import styled, { css } from 'styled-components';
import { DownChevron } from 'ui/icons/Arrows/DownChevron';
import parse from 'html-react-parser';
import { FlashCard, DoFQCardType } from 'types/flash.type';
import { FQCardCorrection } from 'types/correction.type';
import { RelatedLessonCard } from 'components/RelatedLesson/RelatedLessonCard';
import { useGetLessonsByIds } from 'http/lessons/connector';

type FQCorrectionProps = {
  card: (FlashCard & DoFQCardType) | null;
  correction: FQCardCorrection | null;
  isBodyShown: boolean;
  setIsBodyShown: (isBodyShown: boolean) => void;
};

export const FQQCMCorrection: React.FC<FQCorrectionProps> = ({
  card,
  correction,
  isBodyShown,
  setIsBodyShown,
}) => {
  const lessons = useGetLessonsByIds(
    correction?.correction_question.question.lessons &&
      correction?.correction_question.question.lessons.length >= 1
      ? correction?.correction_question.question.lessons
      : []
  );
  if (!card) return null;

  const hasMoreInfos =
    !!correction?.correction_question?.question?.similar_questions.length ||
    !!correction?.correction_question?.question?.lessons.length;

  return (
    <>
      {correction && (
        <GreyCard>
          <Title onClick={() => setIsBodyShown(!isBodyShown)}>
            Commentaire de la correction
            <StyledDownChevron isOpen={isBodyShown} />
          </Title>
          <BodyContainer $isBodyShown={isBodyShown}>
            <StyledMathJax inline>
              {parse(correction.correction_question.question.correction)}
            </StyledMathJax>
          </BodyContainer>
        </GreyCard>
      )}
      <div style={{ display: 'flex' }}>
        <OptionsContainter>
          <FQQCMCorrectionMapper />
        </OptionsContainter>
        {hasMoreInfos && (
          <MoreInfosContainer>
            <Right>
              <RelatedQuestions
                relatedQuestions={
                  correction.correction_question.question.similar_questions
                }
              />
              {lessons?.map((lesson) => (
                <RelatedLessonCard lesson={lesson} key={lesson.lesson} />
              ))}
            </Right>
          </MoreInfosContainer>
        )}
      </div>
    </>
  );
};

const Title = styled.button`
  ${buttonReset}
  border-radius: 8px;
  font-family: 'FreeSans', sans-serif;
  width: 100%;
  z-index: 1;
  font-size: 0.9rem;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BodyContainer = styled.div<{ $isBodyShown?: boolean }>`
  font-family: 'FreeSans', sans-serif;
  width: 100%;
  z-index: 1;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;
  max-height: 0px;
  transition: none;
  overflow: hidden;

  ${({ $isBodyShown }) =>
    $isBodyShown &&
    css`
      max-height: 2000px;
      transition: all 1s ease-in-out;
    `}

  img {
    max-width: 100%;
  }
`;

const StyledDownChevron = styled(({ isOpen, ...props }) => (
  <DownChevron {...props} />
))<{ isOpen: boolean }>`
  width: 16px;
  flex-shrink: 0;

  transition: transform 0.2s ease-in-out;
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `}
`;
const MoreInfosContainer = styled.div`
  margin-top: '.25rem';
  width: 300;
`;

// const ErrorMessage = styled(TextBody2)`
//   text-align: center;
//   margin: 1rem;
//   color: ${({ theme }) => theme.colors.error.main};
//   display: inline;
// `;

const OptionsContainter = styled.div<{ hasMoreInfos?: boolean }>`
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';
  flex-grow: 1;
  max-width: ${({ hasMoreInfos }) =>
    hasMoreInfos ? '100%' : 'calc(100% - 300px)'};
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
`;
