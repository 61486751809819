import { FC } from 'react';
import styled from 'styled-components';
import RankLetter from 'ui/Misc/RankLetter';

interface Props {
  grade: number;
  text: string;
}

export const GradeContainer: FC<Props> = ({ text, grade }) => {
  return (
    <Container>
      <RanksContainer>
        <RankLetter letter={'A'}>{'A'}</RankLetter>
        <RankLetter letter={'B'}>{'B'}</RankLetter>
        <h5>{grade}/20</h5>
      </RanksContainer>
      <StyledText>{text}</StyledText>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  max-width: 310px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  border-radius: 12px;
  background: var(--light-secondary-blue-lighter, #e1ecfa);
`;

const RanksContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StyledText = styled.div`
  color: var(--Light-Text-secondary, #576068);
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;
