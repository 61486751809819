import { Link } from 'react-router-dom';
import { routes } from 'routing/constants';
import styled from 'styled-components/macro';

import { LeftChevron } from 'ui/icons/Arrows/LeftChevron';

export const GoBackToSelectionLink = () => {
  return (
    <Container to={routes.SELECTION}>
      <Chevron />
      <span>Retourner à la sélection</span>
    </Container>
  );
};

const Container = styled(Link)`
  display: flex;
  align-items: center;
  gap: 16px;

  color: ${({ theme }) => theme.colors.secondary.dark} !important;
  ${({ theme }) => theme.typography.h5}
`;

const Chevron = styled(LeftChevron)`
  height: 16px;
`;
