import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  FormatedCCBSession,
  CCBTask,
  Statstabs,
  Option,
  availableCCB,
  selectedCCB,
  CCBScreenType,
  Collection,
} from 'http/ccb/type';
import { isCurrentDateTimeInRange, isPastDate } from 'helpers/dates/utils';
import {
  getCCBlist,
  getCCBCorrection,
  getCCBstatus,
  getCCBStats,
  getCCBRanking,
  getCCBbyId,
} from 'http/ccb/api';
import { RootState } from 'store';

interface FetchSessionsParams {
  ccbId: number;
  trainingDuration: string;
  ucDuration: number;
}

interface FetchStatsParams {
  ccbId: number;
  promotion: string | null;
}

interface UpdateTaskPayload {
  ccbId?: number;
  task: CCBTask | undefined;
}

export const fetchCCBList = createAsyncThunk('ccb/fetchCCBList', async () => {
  const ccbList: availableCCB[] | undefined = await getCCBlist();
  return ccbList;
});

export const fetchFormatedSessionsStatus = createAsyncThunk(
  'ccb/fetchFormatedSessionsStatus',
  async (
    { ccbId, ucDuration, trainingDuration }: FetchSessionsParams,
    { getState }
  ) => {
    const state = getState() as RootState;
    const targetCcb = state.ccb.ccbList?.find(
      (ccb: selectedCCB) => ccb.id === ccbId
    );
    if (!targetCcb) return null;

    const currentCCB = await getCCBbyId(ccbId);
    const ccbStatus = currentCCB && (await getCCBstatus(ccbId));
    const ccbStatsAvailable = currentCCB?.correction ?? false;

    if (!ccbStatus || !currentCCB) {
      return {
        ccbId,
        sessionData: {
          sessions: [],
          ccbStatsAvailable: false,
          isCCBActivatedFree: false,
        },
      };
    }
    const sessions: FormatedCCBSession[] = Object.keys(ccbStatus).map((key) => {
      const taskStatus = ccbStatus[key as keyof typeof ccbStatus];
      const taskId =
        typeof taskStatus === 'object' && taskStatus !== null
          ? taskStatus.task_id
          : taskStatus;

      return {
        taskId: taskId as number | null,
        finished:
          taskStatus === null
            ? false
            : (taskStatus as { task_id: number; finished: boolean }).finished,
        composition_unit: key,
        start_date: currentCCB.start_date,
        end_date: currentCCB.end_date,
        duration: `${ucDuration / 60}h`,
        hasBeenStarted: taskStatus !== null,
        timeRange: trainingDuration === 'extraTime' ? 'Oui' : 'Non',
        hasUpdatedPromotion: false,
      };
    });

    return {
      ccbId,
      sessionData: {
        sessions,
        ccbStatsAvailable,
        isCCBActivatedPremium: isPastDate(currentCCB.end_date),
        isCCBActivatedFree: isCurrentDateTimeInRange(
          currentCCB.start_date,
          currentCCB.end_date
        ),
      },
    };
  }
);

export const fetchFormatedCCBStats = createAsyncThunk(
  'ccb/fetchCCBStats',
  async ({ ccbId, promotion }: FetchStatsParams, { getState }) => {
    const state = getState() as RootState;
    const targetCcb = state.ccb.ccbList?.find(
      (ccb: selectedCCB) => ccb.id === ccbId
    );
    if (!targetCcb) return null;
    const ccbStats = await getCCBStats(ccbId, promotion);
    const ccbRanking = await getCCBRanking(ccbId, promotion);
    const ccbCorrection = await getCCBCorrection(ccbId, promotion);

    return {
      ccbId,
      statsData: {
        ccbStats,
        ccbRanking,
        ccbCorrection,
      },
    };
  }
);

export interface CCBState {
  activeCcb: selectedCCB | null;
  ccbList: selectedCCB[];
  screenToDisplay: CCBScreenType;
  hasUpdatedPromotion: boolean;
  promotions_updated_at: string | undefined;
  collection: Collection | null;
}

const initialState: CCBState = {
  activeCcb: null,
  ccbList: [],
  screenToDisplay: CCBScreenType.PreCCBInfo,
  hasUpdatedPromotion: false,
  promotions_updated_at: undefined,
  collection: null,
};

export const ccbSlice = createSlice({
  name: 'ccb',
  initialState,
  reducers: {
    updateScreenToDisplay(state, action: PayloadAction<CCBScreenType>) {
      state.screenToDisplay = action.payload;
    },
    updateActiveCcb(state, action: PayloadAction<selectedCCB | null>) {
      state.activeCcb = action.payload;
    },
    updateNextTask(state, action: PayloadAction<UpdateTaskPayload>) {
      state.ccbList = state.ccbList.map((ccb) =>
        ccb.id === action.payload.ccbId
          ? { ...ccb, nextTask: action.payload.task }
          : ccb
      );
      if (state.activeCcb && state.activeCcb.id === action.payload.ccbId) {
        state.activeCcb = {
          ...state.activeCcb,
          nextTask: action.payload.task,
        };
      }
    },
    updateLastTask(state, action: PayloadAction<UpdateTaskPayload>) {
      state.ccbList = state.ccbList.map((ccb) =>
        ccb.id === action.payload.ccbId
          ? { ...ccb, lastTask: action.payload.task }
          : ccb
      );
      if (state.activeCcb && state.activeCcb.id === action.payload.ccbId) {
        state.activeCcb = {
          ...state.activeCcb,
          lastTask: action.payload.task,
        };
      }
    },
    updateAllDone(
      state,
      action: PayloadAction<{ ccbId: number; value: boolean }>
    ) {
      state.ccbList = state.ccbList.map((ccb) =>
        ccb.id === action.payload.ccbId
          ? { ...ccb, allDone: action.payload.value }
          : ccb
      );
      if (state.activeCcb && state.activeCcb.id === action.payload.ccbId) {
        state.activeCcb = {
          ...state.activeCcb,
          allDone: action.payload.value,
        };
      }
    },
    addTaskIdToSession(
      state,
      action: PayloadAction<{
        ccbId: number;
        taskComposition_id: string;
        taskId: number;
      }>
    ) {
      state.ccbList = state.ccbList.map((ccb) => {
        if (ccb.id === action.payload.ccbId) {
          return {
            ...ccb,
            sessions: ccb.sessions?.map((session) =>
              session.composition_unit === action.payload.taskComposition_id
                ? { ...session, taskId: action.payload.taskId }
                : session
            ),
          };
        }
        return ccb;
      });
      if (state.activeCcb && state.activeCcb.id === action.payload.ccbId) {
        state.activeCcb.sessions = state.activeCcb.sessions?.map((session) =>
          session.composition_unit === action.payload.taskComposition_id
            ? { ...session, taskId: action.payload.taskId }
            : session
        );
      }
    },
    updateSessions(
      state,
      action: PayloadAction<{ ccbId: number; sessions: FormatedCCBSession[] }>
    ) {
      state.ccbList = state.ccbList.map((ccb) =>
        ccb.id === action.payload.ccbId
          ? { ...ccb, sessions: action.payload.sessions }
          : ccb
      );
      if (state.activeCcb && state.activeCcb.id === action.payload.ccbId) {
        state.activeCcb = {
          ...state.activeCcb,
          sessions: action.payload.sessions,
        };
      }
    },

    updateSessionsLeftToDo(
      state,
      action: PayloadAction<{ ccbId: number; sessions: FormatedCCBSession[] }>
    ) {
      state.ccbList = state.ccbList.map((ccb) =>
        ccb.id === action.payload.ccbId
          ? {
              ...ccb,
              sessionsLeftToDo: [
                ...action.payload.sessions.filter((task) => !task.finished),
              ], // Unnecessary spread
            }
          : ccb
      );
      if (state.activeCcb && state.activeCcb.id === action.payload.ccbId) {
        state.activeCcb = {
          ...state.activeCcb,
          sessionsLeftToDo: [
            ...action.payload.sessions.filter((task) => !task.finished),
          ], // Unnecessary spread
        };
      }
    },
    updateStatsActivePromotion(
      state,
      action: PayloadAction<{ ccbId?: number; promotion: Option }>
    ) {
      state.ccbList = state.ccbList.map((ccb) =>
        ccb.id === action.payload.ccbId
          ? { ...ccb, statsActivePromotion: action.payload.promotion }
          : ccb
      );
      if (state.activeCcb && state.activeCcb.id === action.payload.ccbId) {
        state.activeCcb = {
          ...state.activeCcb,
          statsActivePromotion: action.payload.promotion,
        };
      }
    },
    updateStatsActiveTab(
      state,
      action: PayloadAction<{ ccbId?: number; statsActiveTab: Statstabs }>
    ) {
      state.ccbList = state.ccbList.map((ccb) =>
        ccb.id === action.payload.ccbId
          ? { ...ccb, statsActiveTab: action.payload.statsActiveTab }
          : ccb
      );
      if (state.activeCcb && state.activeCcb.id === action.payload.ccbId) {
        state.activeCcb = {
          ...state.activeCcb,
          statsActiveTab: action.payload.statsActiveTab,
        };
      }
    },
    updateSpecialitiesFilter: (
      state,
      action: PayloadAction<{ ccbId: number; specialitiesFilter: string[] }>
    ) => {
      state.ccbList = state.ccbList.map((ccb) =>
        ccb.id === action.payload.ccbId
          ? { ...ccb, specialitiesFilter: action.payload.specialitiesFilter }
          : ccb
      );
      if (state.activeCcb && state.activeCcb.id === action.payload.ccbId) {
        state.activeCcb = {
          ...state.activeCcb,
          specialities: action.payload.specialitiesFilter,
        };
      }
    },
    updateGradeFilter: (
      state,
      action: PayloadAction<{ ccbId: number; grades: string[] }>
    ) => {
      state.ccbList = state.ccbList.map((ccb) =>
        ccb.id === action.payload.ccbId
          ? { ...ccb, grades: action.payload.grades }
          : ccb
      );
      if (state.activeCcb && state.activeCcb.id === action.payload.ccbId) {
        state.activeCcb = {
          ...state.activeCcb,
          grades: action.payload.grades,
        };
      }
    },
    updatePeriodRankFilter: (
      state,
      action: PayloadAction<{ ccbId: number; periodRank: string[] }>
    ) => {
      state.ccbList = state.ccbList.map((ccb) =>
        ccb.id === action.payload.ccbId
          ? { ...ccb, periodRank: action.payload.periodRank }
          : ccb
      );

      if (state.activeCcb && state.activeCcb.id === action.payload.ccbId) {
        state.activeCcb = {
          ...state.activeCcb,
          periodRank: action.payload.periodRank,
        };
      }
    },
    updateGeneralRankFilter: (
      state,
      action: PayloadAction<{ ccbId: number; generalRank: string[] }>
    ) => {
      state.ccbList = state.ccbList.map((ccb) =>
        ccb.id === action.payload.ccbId
          ? { ...ccb, generalRank: action.payload.generalRank }
          : ccb
      );
      if (state.activeCcb && state.activeCcb.id === action.payload.ccbId) {
        state.activeCcb = {
          ...state.activeCcb,
          generalRank: action.payload.generalRank,
        };
      }
    },
    updateHasUpdatedPromotion(
      state,
      action: PayloadAction<{ ccbId?: number; chosenPromotion: string }>
    ) {
      const todayDate = new Date();
      const todayString = todayDate.toString();
      state.ccbList = state.ccbList.map((ccb) =>
        ccb.id === action.payload.ccbId
          ? {
              ...ccb,
              hasUpdatedPromotion: true,
              promotions_updated_at: todayString,
            }
          : ccb
      );

      // Update activeCcb if it matches the ccbId
      if (state.activeCcb && state.activeCcb.id === action.payload.ccbId) {
        state.activeCcb = {
          ...state.activeCcb,
          hasUpdatedPromotion: true,
          promotions_updated_at: todayString,
        };
      }
    },
    resetHasUpdatedPromotion(state, action: PayloadAction<{ ccbId: number }>) {
      state.ccbList = state.ccbList.map((ccb) =>
        ccb.id === action.payload.ccbId
          ? {
              ...ccb,
              hasUpdatedPromotion: false,
              promotions_updated_at: '',
            }
          : ccb
      );

      if (state.activeCcb && state.activeCcb.id === action.payload.ccbId) {
        state.activeCcb = {
          ...state.activeCcb,
          hasUpdatedPromotion: false,
          promotions_updated_at: '',
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCCBList.fulfilled, (state, action) => {
      state.ccbList = action.payload;
    });

    builder.addCase(fetchFormatedSessionsStatus.fulfilled, (state, action) => {
      if (!action.payload) return;
      const { ccbId, sessionData } = action.payload;
      state.ccbList = state.ccbList.map((ccb) =>
        ccb.id === ccbId
          ? {
              ...ccb,
              sessions: sessionData.sessions,
              sessionsLeftToDo: sessionData.sessions.filter(
                (task) => !task.finished
              ),
              allDone:
                sessionData.sessions.every((session) => session.finished) ??
                false,
            }
          : ccb
      );

      const updatedCcb = state.ccbList.find((ccb) => ccb.id === ccbId);
      if (updatedCcb) {
        state.activeCcb = updatedCcb;
      }
    });
    builder.addCase(fetchFormatedCCBStats.fulfilled, (state, action) => {
      if (!action.payload) return;

      const { ccbId, statsData } = action.payload;

      state.ccbList = state.ccbList.map((ccb) =>
        ccb.id === ccbId ? { ...ccb, ...statsData } : ccb
      );

      const updatedCcb = state.ccbList.find((ccb) => ccb.id === ccbId);
      if (updatedCcb) {
        state.activeCcb = updatedCcb;
      }
    });
  },
});

export const {
  updateActiveCcb,
  updateNextTask,
  updateLastTask,
  updateAllDone,
  updateSessions,
  updateScreenToDisplay,
  updateSessionsLeftToDo,
  updateStatsActivePromotion,
  updateHasUpdatedPromotion,
  resetHasUpdatedPromotion,
  updateStatsActiveTab,
  addTaskIdToSession,
  updateSpecialitiesFilter,
  updateGradeFilter,
  updatePeriodRankFilter,
  updateGeneralRankFilter,
} = ccbSlice.actions;

export const ccbReducer = ccbSlice.reducer;
