import { FC } from 'react';
import styled from 'styled-components';

export interface Props {
  className?: string;
  header: {
    first_column: string;
    second_column: string;
    third_column: string;
    legend?: string;
  };
  answers: Array<{
    id: number;
    answer: number;
    question_item: {
      num: number;
      first_column: string;
      second_column: string;
    };
  }>;
  handleChange(index: number, number: number): void;
  disabled?: boolean;
  saved?: boolean;
}

export const SingleAnswer: FC<Props> = (props) => {
  const radioOptions = [
    { value: -2, label: 'Très peu probable' },
    { value: -1, label: 'Peu probable' },
    { value: 0, label: 'Ni plus ni moins' },
    { value: 1, label: 'Probable' },
    { value: 2, label: 'Très probable' },
  ];

  return (
    <Container className={props.className}>
      {props.answers?.map((el, index) => (
        <QuestionBlock key={index}>
          {/* <QuestionTitle>
            Question {el.question_item.num} - Test de concordance de script
          </QuestionTitle> */}

          <HeaderRow>{props.header?.first_column}</HeaderRow>
          <ContentRow>{el.question_item.first_column}</ContentRow>

          <HeaderRow>{props.header?.second_column}</HeaderRow>
          <ContentRow>{el.question_item.second_column}</ContentRow>

          <HeaderRow>{props.header?.third_column}</HeaderRow>
          <RadioContainer>
            {radioOptions.map((option) => (
              <RadioOption key={option.value}>
                <RadioLabel>
                  <RadioInput
                    type="radio"
                    name={`answer-${el.id}`}
                    value={option.value}
                    checked={el.answer === option.value}
                    onChange={() => props.handleChange(index, option.value)}
                    disabled={props.disabled}
                  />
                  {option.label}
                </RadioLabel>
              </RadioOption>
            ))}
          </RadioContainer>
        </QuestionBlock>
      ))}
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  border-radius: 4px;
  font-family: 'FreeSans';
  font-size: 14px;
`;

const QuestionBlock = styled.div`
  margin-bottom: 24px;
  overflow: hidden;
`;

// const QuestionTitle = styled.div`
//   font-weight: bold;
//   padding: 12px;
//   padding-left: 0;
//   font-size: 16px;
//   font-family: 'FreeSans';
// `;

const HeaderRow = styled.div`
  background-color: rgb(205, 224, 246);
  padding: 0.5rem;
  font-weight: bold;
  font-family: 'FreeSans';
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const ContentRow = styled.div`
  padding: 0.5rem;
  font-family: 'FreeSans' !important;
  background-color: white;
`;

const RadioContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 16px;
  background-color: white;
  gap: 12px;
`;

const RadioOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const RadioLabel = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 13px;
  font-family: 'FreeSans' !important;
`;

const RadioInput = styled.input`
  margin: 0;
  cursor: pointer;
`;
