import { FC } from 'react';
import styled from 'styled-components/macro';
import { propositionStateToString } from './utils';
import { QCMPropositionState } from 'types/correction.type';
import { SmallArrow } from 'components/OpeningArrow/SmallArrow';

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  propositionState: QCMPropositionState;
}

export const Toggler: FC<Props> = ({
  isOpen,
  toggleOpen,
  propositionState,
}) => {
  return (
    <Container type="button" onClick={toggleOpen}>
      <span>{propositionStateToString(propositionState)}</span>{' '}
      <SmallArrow
        $open={isOpen}
        $defaultDirection={'down'}
        $openedDirection={'up'}
      />
    </Container>
  );
};

const Container = styled.button`
  all: unset;

  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.background.paper};
  padding: 2px 12px;
  gap: 8px;
  display: flex;
  align-items: center;
  border-radius: 8px;
`;
