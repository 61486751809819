import React, { FC, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { useInView } from 'react-intersection-observer';
import { Table, Thead, Th, Tbody, Tr } from 'ui/TableElements';
import { TableLoading } from 'pages/selection/components/TableLoading';
import { TableNoResults } from 'pages/selection/components/TableNoResults';
import { FQTableRow } from './FQTableRows/FQTableRow';
import { useGetFQTableValues } from './useGetFQTableValues';
import { TableError } from 'pages/selection/components/TableError';
import { CheckboxHeader } from './TableHeaders/CheckboxHeader';
import { SessionHeader } from './TableHeaders/SessionHeader';
import { GenericSortHeader } from './TableHeaders/GenericSortHeader';
import { SpecialitiesHeader } from './TableHeaders/SpecialitiesHeader';
import { RanksHeader } from './TableHeaders/RanksHeader';
import { ItemsHeader } from './TableHeaders/ItemsHeader';
import { CategoriesHeader } from './TableHeaders/CategoriesHeader';
import { TypeHeader } from './TableHeaders/TypeHeader';
import {
  TableConfigContextProvider,
  useTableConfigContext,
} from './contexts/tableConfigContext';
import { useShowSelectedItemsContext } from './contexts/showSelectedItemsContext';
import { ReadonlyFQTable } from './ReadonlyFQTable';
import {
  sortBy,
  setPageSize,
  updateItemSelection,
  toggleItemSelection,
  openRow,
  setPage,
} from 'store/slices/fqTableSlice';
import { FQResultsType } from 'http/flash-questions/fqdeckcreation/types';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { selectDeck } from 'store/slices/fqCurrentSlice';
import { getFQCardsFromAPI } from 'http/flash-questions/fqdeckcreation/api';
import { useDispatch } from 'react-redux';

const COL_NUMBER = 9;

const FQTable: FC = () => {
  const {
    formatedData,
    isLoading,
    isError,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetFQTableValues();

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '0px',
  });

  const { updateSelectionCreator } = useTableConfigContext();
  const deck = useSelector(selectDeck);
  const dispatch = useDispatch();

  const alreadySelectedIds = useMemo(() => {
    if (!deck) return [];
    return deck.cards.map((card) => Number(card.question));
  }, [deck]);

  const getSelectedItems = async () => {
    const items = await getFQCardsFromAPI({
      type: ['QRM', 'QRU', 'QRP', 'QRPL', 'QZP', 'TCS', 'QROC'],
      question_ids: alreadySelectedIds,
    });
    if (items && items.count > 0) {
      dispatch(updateSelectionCreator(items.results));
    }
  };

  useEffect(() => {
    if (alreadySelectedIds.length > 0) {
      getSelectedItems();
    }
  }, [alreadySelectedIds]); // eslint-disable-line react-hooks/exhaustive-deps

  const { shouldShowSelectedItems } = useShowSelectedItemsContext();

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage, isFetchingNextPage]);

  return (
    <Container>
      <TableContainer>
        {shouldShowSelectedItems ? (
          <ReadonlyFQTable />
        ) : (
          <Table>
            <Thead sticky={true}>
              <Tr>
                <Th>
                  <CheckboxHeader pageItemInfos={formatedData ?? []} />
                </Th>
                <Th>
                  <SessionHeader />
                </Th>
                <Th>
                  <GenericSortHeader columnName="N°" sortColumn="num" />
                </Th>
                <Th>
                  <SpecialitiesHeader />
                </Th>
                <Th>
                  <ItemsHeader />
                </Th>
                <Th>
                  <CategoriesHeader />
                </Th>
                <Th>
                  <RanksHeader />
                </Th>
                <Th>
                  <TypeHeader />
                </Th>
                <Th>
                  <HeaderContainer>Prochaine révision</HeaderContainer>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading && !isFetchingNextPage ? (
                <TableLoading colNumber={COL_NUMBER} />
              ) : formatedData && formatedData.length > 0 ? (
                <>
                  {formatedData.map((rowData: FQResultsType, index: number) => (
                    <>
                      {index === formatedData.length - 15 && (
                        <tr ref={ref}></tr>
                      )}
                      <FQTableRow
                        rowData={rowData}
                        index={index}
                        key={rowData.id}
                      />
                    </>
                  ))}
                  {isFetchingNextPage && (
                    <TableLoading colNumber={COL_NUMBER} />
                  )}
                </>
              ) : formatedData && formatedData.length === 0 ? (
                <TableNoResults colNumber={COL_NUMBER} />
              ) : (
                isError && <TableError colNumber={COL_NUMBER} retry={refetch} />
              )}
            </Tbody>
          </Table>
        )}
      </TableContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const TableContainer = styled.div`
  position: relative;
  z-index: 1;
  border-left: ${({ theme }) => theme.colors.primary.main} 2px solid;
  border-right: none;
  border-radius: 12px;
  width: 100%;

  & tr:last-child td {
    border-bottom: ${({ theme }) => theme.colors.primary.main} 2px solid;
  }

  & tr td:last-child {
    border-right: ${({ theme }) => theme.colors.primary.main} 2px solid;
    border-radius: 0;
  }
`;

const withContext = (MemoComponent: FC) => {
  const Wrapper: FC = () => {
    return (
      <TableConfigContextProvider
        value={{
          sortByCreator: sortBy,
          tableStateSelector: (state: RootState) => state.fqTable,
          updateSelectionCreator: updateItemSelection,
          toggleSelectionCreator: toggleItemSelection,
          openRowCreator: openRow,
          setPageSizeCreator: setPageSize,
          setPageCreator: setPage,
        }}
      >
        <MemoComponent />
      </TableConfigContextProvider>
    );
  };

  return React.memo(Wrapper);
};

export default withContext(FQTable);

const HeaderContainer = styled.div`
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.primary.dark};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`;
