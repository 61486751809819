import { CorrectionQuestion } from 'types/correction.type';
import { TCSCorrectionData } from './types';

export const adaptTCSCorrectionData = (
  questionData: CorrectionQuestion
): TCSCorrectionData => {
  const headers = questionData.tcs_header;
  return {
    firstColumnHeader: headers?.first_column ?? '',
    secondColumnHeader: headers?.second_column ?? '',
    thirdColumnHeader: headers?.third_column ?? '',
    rows: [...(questionData.correction_items_tcs ?? [])] // Crée une copie du tableau
      .sort((a, b) => a.question_item.num - b.question_item.num)
      .map((rawRow) => ({
        firstColumn: rawRow.question_item.first_column,
        secondColumn: rawRow.question_item.second_column,
        rangePoints: {
          [-2]: rawRow.question_item.minus_two,
          [-1]: rawRow.question_item.minus_one,
          0: rawRow.question_item.zero,
          1: rawRow.question_item.plus_one,
          2: rawRow.question_item.plus_two,
        },
        userAnswer: rawRow.answer,
        commentary: rawRow.question_item.correction,
        id: rawRow.id,
      })),
  };
};
