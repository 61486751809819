import { forwardRef, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

import { SingleAnswer, Props as SingleAnswerProps } from './SingleAnswer';
import { ValidateButton } from '../ui/ValidateButton';
import { ValidationModal } from '../ValidationModal/ValidationModal';
import { StyledMathJax } from 'ui/MathJax';
import { toast } from 'react-toastify';
import { useSaveQuestionTCS } from 'pages/new_training/hooks/saveHooks/useSaveTCS';

interface Props {
  question: string;
  questionNumber: number;
  questionId: number;
  header: SingleAnswerProps['header'];
  answers: SingleAnswerProps['answers'];
  show: boolean;
  saved: boolean;
  isQI: boolean;
  onSave(): void;
  onModification?(): void;
  anonymous: boolean;
  collection: string | undefined;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export const TCS = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const [answers, setAnswers] = useState<Props['answers']>(props.answers);
  const answersRef = useRef<Props['answers']>(props.answers);

  const [header, setHeader] = useState<Props['header']>(props.header);

  const [validationModal, setValidationModal] = useState<boolean>(false);

  const saveQuestion = useSaveQuestionTCS(props.onSave);

  const handleSaveQuestion = async () => {
    props.setIsLoading(true);
    const params = {
      id: props.questionId,
      answers: answers.map((el) => ({ id: el.id, answer: el.answer || 0 })),
    };

    try {
      await saveQuestion(params);
      answersRef.current = answers;
    } catch (err) {
      toast.error(
        'Erreur lors de la sauvegarde de la question, veuillez réessayer'
      );
    }
    props.setIsLoading(false);
  };

  useEffect(() => {
    if (props.answers !== answers) {
      setAnswers(props.answers);
    }
    if (props.header !== header) {
      setHeader(props.header);
    }
    // eslint-disable-next-line
  }, [props.answers, props.header]);

  useEffect(() => {
    if (props.show && !props.isQI) {
      titleRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line
  }, [props.show]);

  const handleValidationClick = () => {
    if (props.isQI) {
      handleSaveQuestion();
    } else {
      setValidationModal(true);
    }
  };

  const handleChange = (index: number, number: number) => {
    const newAnswers = [...answers];
    newAnswers[index].answer = number;
    setAnswers(newAnswers);
  };

  useEffect(() => {
    if (answersRef.current !== answers) {
      props.isQI && props.onModification && props.onModification();
    }
    // eslint-disable-next-line
  }, [answers]);

  return (
    <Container ref={ref} show={props.show}>
      <Title ref={titleRef}>
        {(props.anonymous ? '' : props.isQI ? props.collection + ' ' : '') +
          ('Question ' + props.questionNumber) +
          ' - Test de concordance de script'}
      </Title>
      <Statement>
        <StyledMathJax inline>{parse(props.question)}</StyledMathJax>
      </Statement>

      <StyledSingleAnswer
        header={header}
        answers={answers}
        handleChange={handleChange}
        disabled={props.saved && !props.isQI}
        saved={props.saved}
      />

      <ValidateButton
        show={props.isQI || !props.saved}
        onClick={handleValidationClick}
        isLoading={props.isLoading}
      />
      <ValidationModal
        isOpen={validationModal}
        onCancel={() => setValidationModal(false)}
        onAccept={() => {
          setValidationModal(false);
          handleSaveQuestion();
        }}
        title="Réponse définitive"
        text="En cliquant sur le bouton OK vous confirmez vos réponses. Elles ne seront plus modifiables."
      />
    </Container>
  );
});

const Container = styled.div<{ show?: boolean }>`
  margin-bottom: 50px;
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

const Title = styled.div`
  display: block;
  font-family: 'FreeSans';
  font-style: bold;
  font-weight: 700;
  font-size: 16px;
  text-align: justify;
`;

const Statement = styled.label`
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  font-family: 'FreeSans' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: justify;
  sup {
    font-size: 0.83em;
  }

  p,
  span {
    font-family: 'FreeSans' !important;
  }

  img {
    max-width: 60%;
    height: auto;
  }
`;

const StyledSingleAnswer = styled(SingleAnswer)``;
