import { css } from 'styled-components/macro';

import { device } from 'helpers/css/responsive';

export const typographies = {
  tiny: css`
    font-family: 'FreeSans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
  `,
  small: css`
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    @media ${device.desktop} {
      font-size: 14px;
      line-height: 16px;
    }
  `,
  textBody2: css`
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;

    @media ${device.desktop} {
      font-size: 16px;
      line-height: 20px;
    }
  `,
  label: css`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    @media ${device.desktop} {
      font-size: 20px;
      line-height: 24px;
    }
  `,
  boldLabel: css`
    font-weight: 900;
    font-size: 16px;
    line-height: 20px;

    @media ${device.desktop} {
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
    }
  `,
  h1: css`
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;

    @media ${device.desktop} {
      font-size: 38px;
      line-height: 44px;
    }
  `,
  h2: css`
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;

    @media ${device.desktop} {
      font-size: 32px;
      line-height: 36px;
    }
  `,
  h3: css`
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;

    @media ${device.desktop} {
      font-size: 32px;
      line-height: 40px;
    }
  `,
  h4: css`
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;

    @media ${device.desktop} {
      font-size: 24px;
      line-height: 28px;
      font-weight: 700;
    }
  `,
  h5: css`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    @media ${device.desktop} {
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
    }
  `,
  h6: css`
    font-family: 'FreeSans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  `,
  body: css`
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;

    @media ${device.desktop} {
      font-size: 20px;
      line-height: 24px;
    }
  `,
  button: css`
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    @media ${device.desktop} {
      font-size: 20px;
      line-height: 24px;
    }
  `,
  a: css`
    color: #0000ee;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:visited {
      color: #0000ee;
    }
  `,
  fontFamily: css`
    font-family: 'Urbanist', sans-serif;
  `,
} as const;

export type Typographies = typeof typographies;
