import React, { useMemo } from 'react';
import styled from 'styled-components';
import { PageTitle } from 'ui/Titles/PageTitle';
import { InfoModalButton } from '../Modales/InfoModalButton';
import { SoonAvailableSection } from './components/SoonAvailableSection';
import { Session } from './components/Session';
import { useSelector } from 'react-redux';
import { availableCCB } from 'http/ccb/type';
import { isPastDate } from 'helpers/dates/utils';
import { RootState } from 'store';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: 3rem;
`;

const SessionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  margin-bottom: 3rem;
`;

const SessionTitle = styled.h3`
  /* Add any specific styling for the h3 if needed */
`;

const SessionsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  width: 100%;
`;

export const MultiCCBInfoPage = () => {
  const { ccbList } = useSelector((state: RootState) => state.ccb);

  const { ccbSoonAvailable, ccbStarted, ccbFinished } = useMemo(() => {
    const sorted = {
      ccbSoonAvailable: [] as availableCCB[],
      ccbStarted: [] as availableCCB[],
      ccbFinished: [] as availableCCB[],
    };

    ccbList?.forEach((ccb: availableCCB) => {
      if (isPastDate(ccb.start_date)) {
        if (isPastDate(ccb.end_date)) {
          sorted.ccbFinished.push(ccb);
        } else {
          sorted.ccbStarted.push(ccb);
        }
      } else {
        sorted.ccbSoonAvailable.push(ccb);
      }
    });

    return sorted;
  }, [ccbList]);

  return (
    <PageContainer>
      <HeaderContainer>
        <PageTitle>Concours blanc</PageTitle>
        <InfoModalButton />
      </HeaderContainer>

      {ccbSoonAvailable.length > 0 &&
        ccbSoonAvailable.map((ccb: availableCCB) => (
          <SoonAvailableSection key={ccb.id} ccb={ccb} />
        ))}

      <SessionsContainer>
        <SessionTitle>Sessions disponibles</SessionTitle>

        {ccbStarted.length > 0 && (
          <SessionsList>
            {ccbStarted.map((ccb: availableCCB) => (
              <Session ccb={ccb} type="available" key={ccb.id} />
            ))}
          </SessionsList>
        )}

        {ccbFinished.length > 0 && (
          <SessionsList>
            {ccbFinished.map((ccb: availableCCB) => (
              <Session ccb={ccb} type="limited" key={ccb.id} />
            ))}
          </SessionsList>
        )}
      </SessionsContainer>
    </PageContainer>
  );
};

export default MultiCCBInfoPage;
