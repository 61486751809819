import { FC } from 'react';
import styled from 'styled-components/macro';
import parse from 'html-react-parser';
import { CorrectionThemeProvider } from 'components/CorrectionThemeProvider';
import { MultipleKnowledgeRank } from 'components/Knowledge/MultipleKnowledgeRank';
import { ModalHeader, Modal } from 'ui/Modal';
import { StyledMathJax } from 'ui/MathJax';
import { Knowledge } from 'types/knowledge.type';
import { ItemsFromKnowledges } from 'components/Item/ItemsFromKnowledges';
import { DotSeparator } from 'ui/DotSeparator';
interface Props {
  isOpen: boolean;
  closeModal: () => void;
  title: string;
  body: string;
  knowledges: Knowledge[];
}

export const RelatedLessonModal: FC<Props> = ({
  isOpen,
  closeModal,
  title,
  body,
  knowledges,
}) => {
  return (
    <CorrectionThemeProvider>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <ModalHeader onCloseButtonClick={closeModal}>
          <HeaderActions>
            <div>{title}</div>
            <DotSeparator />
            <StyledItem knowledges={knowledges} />
            <DotSeparator />
            <MultipleKnowledgeRank knowledges={knowledges} />
          </HeaderActions>
        </ModalHeader>
        <ModalContent>
          <ScrollableContainer>
            <Statement>
              <StyledMathJax inline>{body && parse(body)}</StyledMathJax>
            </Statement>
          </ScrollableContainer>
        </ModalContent>
      </Modal>
    </CorrectionThemeProvider>
  );
};

const ModalContent = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const Statement = styled.div`
  width: 100%;
  margin-bottom: 8px;
`;

const ScrollableContainer = styled.div`
  overflow: auto;
  padding-right: 4px;
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
`;

const StyledItem = styled(ItemsFromKnowledges)`
  ${({ theme }) => theme.typography.h2}
`;
