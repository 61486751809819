import { FC, LabelHTMLAttributes } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import ReactSelect, {
  components,
  DropdownIndicatorProps,
  GroupBase,
  Props,
} from 'react-select';
import { device } from 'helpers/css/responsive';
import { DownChevron } from 'ui/icons/Arrows/DownChevron';
import { getDropdownStyle } from './dropdownStyles';

import { Option, IsMulti } from './dropdownStyles';

type NativeLabelProps = LabelHTMLAttributes<HTMLLabelElement>;
interface SelectProps extends Props<Option, IsMulti, GroupBase<Option>> {
  label?: string;
  placeholder?: string;
  className?: string;
  labelProps?: NativeLabelProps;
  errorMessage?: string;
}

export const UnderlinedDropdown: FC<SelectProps> = (props) => {
  const theme = useTheme();
  const selectStyles = getDropdownStyle(theme);
  const {
    options,
    label,
    placeholder,
    className,
    labelProps,
    errorMessage,
    ...selectProps
  } = props;

  return (
    <Container className={className}>
      <LabelLine>{label ? <Label>{label}</Label> : null}</LabelLine>
      <SelectContainer>
        <ReactSelect<Option>
          options={options ? options : []}
          placeholder={placeholder ? placeholder : ''}
          styles={selectStyles}
          components={{ DropdownIndicator: DropdownIndicator }}
          {...selectProps}
        />
      </SelectContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary.dark};
  width: 100%;
  @media ${device.desktop} {
    font-size: 20px;
  }
`;

const SelectContainer = styled.div`
  display: block;
  height: 36px;
  @media ${device.desktop} {
    height: 32px;
  }
`;

const DropdownIndicator: FC<DropdownIndicatorProps<Option>> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <StyledDownArrow />
    </components.DropdownIndicator>
  );
};

const Label = styled.label``;

const StyledDownArrow = styled(DownChevron)`
  color: ${({ theme }) => theme.colors.primary.dark};
  object-fit: fill;
  width: 8px;
  height: 4px;
  @media ${device.desktop} {
    width: 16px;
    height: 8px;
  }
`;

const LabelLine = styled.div`
  @media ${device.desktop} {
  }

  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;
