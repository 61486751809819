import styled from 'styled-components/macro';
import { useNavigate, useParams } from 'react-router-dom';

import { device } from 'helpers/css/responsive';
import { useIsLoading } from 'hooks/useIsLoading';
import { routes } from 'routing/constants';

import { Card } from 'ui/Card';
import { Button } from 'ui/Buttons/Button';
import { PasswordInput } from 'ui/PasswordInput';
import { LoginRelatedBackground } from 'components/LoginRelatedBackground';

import {
  useChangePasswordForm,
  useChangePasswordFormSubmit,
} from './changePasswordForm';

export const ChangePassword = () => {
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();

  if (token === undefined) {
    navigate(routes.NEW_HOME);
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useChangePasswordForm();

  const { isLoading, startLoading, stopLoading } = useIsLoading();

  const onSubmit = useChangePasswordFormSubmit({
    token: token!,
    onFetch: startLoading,
    afterFetch: stopLoading,
  });

  return (
    <LoginRelatedBackground>
      <Container>
        <Card>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Title>Changement de mot de passe</Title>

            <PasswordInput
              label="Nouveau mot de passe"
              autoComplete="new-password"
              errorMessage={errors.password?.message}
              {...register('password')}
            />

            <PasswordInput
              label="Confirmation du mot de passe"
              autoComplete="new-password"
              errorMessage={errors.passwordConfirmation?.message}
              {...register('passwordConfirmation')}
            />

            <Button type="submit" isLoading={isLoading}>
              Valider le nouveau mot de passe
            </Button>
          </Form>
        </Card>
      </Container>
    </LoginRelatedBackground>
  );
};

const Container = styled.div`
  height: 100%;
  min-height: 100vh;

  display: grid;
  place-items: center;

  padding: 16px;
`;

const Title = styled.h1`
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  gap: 16px;

  @media ${device.desktop} {
    gap: 32px;
  }
`;
