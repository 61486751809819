import { reportErrorToSentry } from 'helpers/application/reportErrorToSentry';
import { useGetUnfinishedTasks } from 'http/task-history/connector';
import { TaskHistoryResponse } from 'http/task-history/types';
import { useDeleteTask } from 'http/training/delete-task/connector';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { routes } from 'routing/constants';
import styled, { useTheme } from 'styled-components';
import { Button } from 'ui/Buttons/Button';
import { Modal } from 'ui/Modal';
import { useSelector } from 'react-redux';

interface Props {}

export const OngoingTraining: FC<Props> = () => {
  const { activeCcb } = useSelector((state: any) => state.ccb);
  const { data: unfinishedTasks } = useGetUnfinishedTasks();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const theme = useTheme();
  const [loadingCancelButton, setLoadingCancelButton] =
    useState<boolean>(false);
  const [loadingGetBackButton, setLoadingGetBackButton] =
    useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if activeCcb exists first
    if (
      unfinishedTasks &&
      unfinishedTasks.length > 0 &&
      activeCcb &&
      activeCcb.sessionsLeftToDo &&
      activeCcb.nextTask &&
      activeCcb.nextTask.finished === false
    ) {
      setIsModalOpen(true);
    }
  }, [unfinishedTasks, activeCcb]);

  const goToLastUnfinishedTask = async () => {
    setLoadingGetBackButton(true);
    if (unfinishedTasks) {
      const sortedUnfinishedTasks = unfinishedTasks?.sort((a, b) =>
        a.date_creation > b.date_creation ? 1 : -1
      );
      const taskId = sortedUnfinishedTasks.pop()?.id;
      if (sortedUnfinishedTasks && sortedUnfinishedTasks.length > 0) {
        await deleteTasks(sortedUnfinishedTasks);
      }
      setIsModalOpen(false);
      setLoadingGetBackButton(false);
      navigate({ pathname: routes.TRAINING, search: `?id=${taskId}` });
    }
  };

  const deleteUnfinishedTasks = async () => {
    if (unfinishedTasks) {
      setLoadingCancelButton(true);
      await deleteTasks(unfinishedTasks);
      setLoadingCancelButton(false);
      setIsModalOpen(false);
    }
  };

  const deleteTask = useDeleteTask();

  const deleteTasks = async (tasks: TaskHistoryResponse) => {
    try {
      for (let i = 0; i < tasks.length; i++) {
        await deleteTask(tasks[i].id);
      }
      setIsModalOpen(false);
    } catch (e) {
      reportErrorToSentry(e);
      toast.error('Une erreur est survenue.');
      setIsModalOpen(false);
    }
  };

  return (
    <Modal isOpen={isModalOpen}>
      <ModalBody>
        <h3>Vous avez une épreuve en cours.</h3>
        <h5>Souhaitez-vous la reprendre ?</h5>
        <ButtonContainer>
          <GetBackButton
            onClick={goToLastUnfinishedTask}
            isLoading={loadingGetBackButton}
          >
            Reprendre
          </GetBackButton>
          <CancelButton
            style={{
              backgroundColor: theme.colors.secondary.main,
            }}
            onClick={deleteUnfinishedTasks}
            isLoading={loadingCancelButton}
          >
            Supprimer
          </CancelButton>
        </ButtonContainer>
      </ModalBody>
    </Modal>
  );
};

const ModalBody = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 30px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const GetBackButton = styled(Button)``;

const CancelButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.secondary.main};
`;
