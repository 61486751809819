import {
  createContext,
  FC,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getHomeItems, getHomeSpecialties } from 'http/new-home/api';
import { NewRecapItem, NewRecapSpecialty } from 'http/new-home/types';
import { useAccount } from 'pages/account/useAccount';

export type ActiveTabType = 'specialties' | 'items';

interface HomeInfoContextProps {
  activeTab: ActiveTabType;
  handleChangeTab: (activeTab: ActiveTabType) => void;
  activeSpecialtyId?: string;
  setActiveSpecialtyId: (activeSpecialtyId: string) => void;
  activeSpecialty?: NewRecapSpecialty;
  setActiveSpecialty: (activeSpecialty: NewRecapSpecialty) => void;
  activeItem: NewRecapItem | undefined;
  setActiveItem: (activeItem: NewRecapItem) => void;
  activeItemId?: number;
  setActiveItemId: (activeItemId: number) => void;
  goToHome: (activeTab?: ActiveTabType) => void;
  shownPage: 'main' | 'sub';
  updatePreferredTab: (tab: ActiveTabType) => void;
  isLoading: boolean;
}

const contextDefaultValue: HomeInfoContextProps = {
  activeTab: 'specialties',
  handleChangeTab: () => {},
  activeSpecialty: undefined,
  setActiveSpecialty: () => {},
  activeSpecialtyId: undefined,
  setActiveSpecialtyId: () => {},
  activeItem: undefined,
  setActiveItem: () => {},
  activeItemId: undefined,
  setActiveItemId: () => {},
  goToHome: () => {},
  shownPage: 'main',
  updatePreferredTab: () => {},
  isLoading: false,
};

const HomeInfoContext = createContext(contextDefaultValue);

export const HomeInfoProvider: FC = ({ children }) => {
  const { userOptions, updateUserOptions } = useAccount();
  const [activeTab, setActiveTab] = useState<ActiveTabType>(
    userOptions?.home_preferred_tabs ?? 'specialties'
  );
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shownPage, setShownPage] = useState<'main' | 'sub'>('main');
  const [activeSpecialty, setActiveSpecialty] = useState<NewRecapSpecialty>();
  const [activeSpecialtyId, setActiveSpecialtyId] = useState<
    string | undefined
  >();
  const [activeItem, setActiveItem] = useState<NewRecapItem | undefined>();
  const [activeItemId, setActiveItemId] = useState<number | undefined>();

  const navigate = useNavigate();
  const location = useLocation();

  const parseUrlParams = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    return {
      selected: params.get('selected') as ActiveTabType | null,
      activeSpeciality: params.get('activeSpeciality'),
      activeItem: params.get('activeItem'),
    };
  }, []);

  const updateStateFromUrl = useCallback(async () => {
    setIsLoading(true);
    const { selected, activeSpeciality, activeItem } = parseUrlParams();

    // Update active tab
    if (selected) {
      setActiveTab(selected);
    } else if (userOptions?.home_preferred_tabs) {
      setShownPage('main');
      setActiveTab(userOptions.home_preferred_tabs);
    }

    // Handle items tab
    if (selected === 'items') {
      if (activeItem) {
        const itemId = parseInt(activeItem);
        setActiveItemId(itemId);
        const results = await getHomeItems({ search: activeItem });
        const foundItem = results?.results?.find(
          (item) => item.item.item === itemId
        );
        if (foundItem) {
          setActiveItem(foundItem);
          setShownPage('sub');
        } else {
          setShownPage('main');
        }
      } else {
        setActiveItem(undefined);
        setActiveItemId(undefined);
        setShownPage('main');
      }
    }

    // Handle specialties tab
    if (selected === 'specialties') {
      if (activeSpeciality) {
        setActiveSpecialtyId(activeSpeciality);
        const results = await getHomeSpecialties({ search: activeSpeciality });
        const foundSpecialty = results?.results?.find(
          (specialty) => specialty.specialty.trigram === activeSpeciality
        );
        if (foundSpecialty) {
          setActiveSpecialty(foundSpecialty);
          setShownPage('sub');
        } else {
          setShownPage('main');
        }
      } else {
        setActiveSpecialty(undefined);
        setActiveSpecialtyId(undefined);
        setShownPage('main');
      }
    }

    setIsLoading(false);
    setHasLoaded(true);
  }, [userOptions?.home_preferred_tabs, parseUrlParams]);

  // Handle browser navigation events
  useEffect(() => {
    updateStateFromUrl();
  }, [location, updateStateFromUrl]);

  const goToHome = useCallback(
    (tab?: ActiveTabType) => {
      const newTab = tab || activeTab;
      setActiveTab(newTab);
      setActiveSpecialty(undefined);
      setActiveItem(undefined);
      setActiveItemId(undefined);
      setActiveSpecialtyId(undefined);
      setShownPage('main');
      navigate(`/?selected=${newTab}`);
    },
    [activeTab, navigate]
  );

  const updatePreferredTab = useCallback(
    (tab: ActiveTabType) => {
      updateUserOptions({
        ...userOptions,
        home_preferred_tabs: tab,
      });
      setActiveTab(tab);
    },
    [userOptions, updateUserOptions]
  );

  const handleChangeTab = useCallback(
    (tab: ActiveTabType) => {
      navigate(`/?selected=${tab}`);
    },
    [navigate]
  );

  return (
    <HomeInfoContext.Provider
      value={{
        activeTab,
        handleChangeTab,
        activeSpecialty,
        setActiveSpecialty,
        activeItem,
        setActiveItem,
        goToHome,
        shownPage,
        updatePreferredTab,
        activeItemId,
        setActiveItemId,
        activeSpecialtyId,
        setActiveSpecialtyId,
        isLoading: isLoading || !hasLoaded,
      }}
    >
      {children}
    </HomeInfoContext.Provider>
  );
};

export const useHomeInfoContext = () => useContext(HomeInfoContext);

export const STATEMENT_ID = -1;
