import styled from 'styled-components/macro';

import { device } from 'helpers/css/responsive';
import {
  detailBySessionView,
  detailBySpeView,
  generalView,
  progressViewOptions,
  updateView,
} from 'store/slices/progressSlice';

import { Option, ViewDropdown } from './components/ViewDropdown/ViewDropdown';
import { DisplayModeProvider } from './displayModeContext';

import { GeneralProgress } from './GeneralProgress';
import { DetailBySession } from './DetailBySession';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { COPYRIGHTS_HEIGHT } from 'components/Copyrights';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useTour } from '@reactour/tour';

export const Progress = () => {
  const currentView = useSelector((state: RootState) => state.progress.view);
  const dispatch = useDispatch();
  const setCurrentView = (newVal: Option) => dispatch(updateView(newVal));
  const [searchParams] = useSearchParams();
  const currentViewUrl = searchParams.get('currentView');
  const navigate = useNavigate();
  const { isOpen } = useTour();

  useEffect(() => {
    if (isOpen) {
      setCurrentView(generalView);
      return;
    }
    switch (currentViewUrl) {
      case 'general':
        setCurrentView(generalView);
        break;
      case 'sessionDetail':
        setCurrentView(detailBySessionView);
        break;
      case 'speDetail':
        setCurrentView(detailBySpeView);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentViewUrl, isOpen]);

  return (
    <DisplayModeProvider>
      <ScrollContainer>
        <Container>
          <TitleRow id="progress-title-container">
            <h1>Avancement</h1>
            <StyledViewDropdown
              options={progressViewOptions}
              value={currentView}
              onChange={(newVal) => {
                if (newVal && newVal.value !== currentView.value) {
                  navigate(`/progress?currentView=${newVal.value}`);
                }
              }}
            />
          </TitleRow>

          {currentView === generalView && <GeneralProgress />}

          {currentView === detailBySessionView && <DetailBySession />}
        </Container>
      </ScrollContainer>
    </DisplayModeProvider>
  );
};

const ScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - ${COPYRIGHTS_HEIGHT}px);
  padding-top: 12px;

  --horizontal-padding: 12px;

  @media ${device.desktop} {
    padding-top: 32px;
    --horizontal-padding: 32px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const TitleRow = styled(Row)`
  margin-bottom: 20px;
  gap: 8px;
  flex-wrap: wrap;

  padding: 0 var(--horizontal-padding);

  @media ${device.desktop} {
    gap: 32px;
  }
`;

const StyledViewDropdown = styled(ViewDropdown)`
  min-width: 220px;
`;
