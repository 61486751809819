import styled, { css } from 'styled-components/macro';
import { DownChevron } from 'ui/icons/Arrows/DownChevron';

export const DropdownChevron = styled(({ isOpen, ...props }) => (
  <DownChevron {...props} />
))<{ isOpen: boolean }>`
  width: 16px;
  flex-shrink: 0;
  margin-left: 16px;

  transition: transform 0.2s ease-in-out;
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `}
`;
