import { FC } from 'react';
import styled from 'styled-components/macro';

import { Button } from 'ui/Buttons/Button';
import { useIsDPAlreadySelected } from '../hooks/useIsDPAlreadySelected';
import { useToggleDPInCart } from '../hooks/useToggleDPInCart';
import { DetailGroupResponse } from 'http/progress/detail/group/types';

interface Props {
  group: DetailGroupResponse;
}

export const AddDPToSelectionButton: FC<Props> = ({ group }) => {
  const isQuestionAlreadySelected = useIsDPAlreadySelected(
    group ?? { id: -1, mode: 'OFF', type: 'DP' }
  );
  const toggleDPInCart = useToggleDPInCart();

  const toggleGroup = () => {
    if (group === undefined) return;
    toggleDPInCart(group);
  };

  return (
    <AddButton onClick={toggleGroup}>
      {isQuestionAlreadySelected
        ? 'Supprimer le DP de la sélection'
        : 'Ajouter le DP à la sélection'}
    </AddButton>
  );
};

const AddButton = styled(Button)`
  margin-top: 32px;
`;
