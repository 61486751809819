import { FC } from 'react';
import styled from 'styled-components';
import { LeftChevron } from 'ui/icons/Arrows/LeftChevron';

interface BackwardsButtonProps {
  handleClick: () => void;
  text: string;
}

export const BackwardsButton: FC<BackwardsButtonProps> = ({
  handleClick,
  text,
}) => {
  return (
    <BackArrow onClick={handleClick}>
      <StyledLeftChevron />
      {text}
    </BackArrow>
  );
};

const StyledLeftChevron = styled(LeftChevron)`
  width: 10px;
  height: auto;
  margin-left: 0.5em;
`;

const BackArrow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  gap: 0.5rem;
  :hover {
    text-decoration: underline;
  }
`;
