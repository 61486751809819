import { FC, ReactNode, useEffect } from 'react';

interface ScrollToTopProps {
  children?: ReactNode;
  triggers?: any[];
}

export const ScrollToTop: FC<ScrollToTopProps> = ({
  children,
  triggers,
}): JSX.Element | null => {
  useEffect(() => {
    const container = document.getElementById('scrollable-container');
    if (container) {
      container.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [triggers]);

  return children as JSX.Element | null;
};

export default ScrollToTop;
