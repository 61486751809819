import { FC, useEffect, useState } from 'react';

import { ContainedLoader } from 'components/ContainedLoader';

import { DPProgressYearBySession } from './DPProgressYearBySession';
import { GenericErrorPlaceholder } from 'components/GenericErrorPlaceholder';
import { useGetDPProgressByCompositionUnits } from 'modules/progress/general/dp/hooks/useGetDPProgressByCompositionUnits';
import { useTour } from '@reactour/tour';
import { DPProgressByCompositionUnits } from 'modules/progress/general/dp/domain/entities/DPProgressByCompositionUnits';
import { progressMocks } from '../mocks/progressMocks';
import styled from 'styled-components';

export const DPProgressBySession: FC = () => {
  const [shownData, setShownData] = useState<
    DPProgressByCompositionUnits[] | undefined
  >([]);
  const { data, isLoading, error } = useGetDPProgressByCompositionUnits();
  const { isOpen } = useTour();
  const [tempoLoading, setTempoLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setTempoLoading(true);
      setShownData(progressMocks);
      setTimeout(() => {
        setTempoLoading(false);
      }, 100);
    } else {
      setShownData(data);
    }
  }, [isOpen, data]);

  if (isLoading || tempoLoading) {
    return <ContainedLoader loadingText="Veuillez patienter..." />;
  }

  if (error) {
    return <GenericErrorPlaceholder />;
  }

  if (shownData) {
    return (
      <Container id="dp-progress-div">
        {shownData.map((progress) => {
          if (Object.keys(progress.compositionUnits).length === 0) {
            return null;
          }
          return (
            <DPProgressYearBySession
              progress={progress}
              key={progress.collection}
            />
          );
        })}
      </Container>
    );
  }

  return <div>Un problème est survenu</div>;
};

const Container = styled.div`
  display: flex;
  gap: 16px;
`;
