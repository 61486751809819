import styled, { css } from 'styled-components/macro';
import { device } from 'helpers/css/responsive';

export const ButtonBase = styled.button<{
  size: string;
  disabled: boolean;
  styled: string;
  iconOnly?: boolean;
  hasPadding: boolean;
}>`
  font-family: 'Urbanist';
  display: flex;
  gap: 10px;
  cursor: pointer;
  font-family: 'FreeSans';
  font-style: normal;
  border-radius: 12px;
  width: fit-content;
  align-items: center;
  justify-content: center;
  border: none;
  white-space: nowrap;
  box-sizing: border-box;
  ${({ theme }) => theme.typography.label};
  ${({ styled, disabled, theme }) => {
    switch (styled) {
      case 'primary':
        return css`
          background-color: ${disabled
            ? theme.colors.inactive
            : theme.colors.primary.dark};
          &:hover {
            background-color: ${({ theme }) => theme.colors.inactive};
          }
          color: ${theme.colors.background.paper};
        `;
      case 'secondary':
        return css`
          background-color: transparent;
          border: 2px solid
            ${disabled ? theme.colors.inactive : theme.colors.primary.dark};
          color: ${theme.colors.primary.dark};
          &:hover {
            background-color: ${theme.colors.primary.light};
          }
        `;
      case 'activated':
        return css`
          background-color: ${theme.colors.primary.main};
          color: ${theme.colors.primary.dark};
          &:hover {
            background-color: ${theme.colors.primary.light};
          }
        `;
      case 'link-red':
        return css`
          background-color: ${theme.colors.background.main};
          color: ${theme.colors.secondary.main};
          &:hover {
            text-decoration: underline;
          }
        `;
      case 'important':
        return css`
          background-color: ${theme.colors.secondary.main};
          color: ${theme.colors.secondary.contrastText};
        `;
      default:
        return '';
    }
  }}
  ${({ size, iconOnly, hasPadding }) => {
    switch (size) {
      case 'large':
        return css`
          font-weight: 700;
          padding: ${hasPadding ? (iconOnly ? '12px 16px' : '12px 32px') : 0};
          font-size: 20px;
          line-height: 24px;
          max-height: 2rem;
          @media ${device.desktop} {
            font-size: 24px;
            line-height: 28px;
            max-height: 3rem;
          }
        `;
      case 'medium':
        return css`
          font-weight: 600;
          font-size: 20px;
          line-height: 32px;
          padding: 8px 32px;
          padding: ${hasPadding ? (iconOnly ? '12px 16px' : '8px 32px') : 0};
        `;
      case 'small':
        return css`
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          padding: ${hasPadding ? (iconOnly ? '8px 12px' : '6px 12px') : 0};
        `;
      case 'x-small':
        return css`
          border: 1px solid ${({ theme }) => theme.colors.primary.dark};
          font-weight: 500;
          font-size: 16px;
          line-height: 19.2px;
          padding: ${hasPadding ? (iconOnly ? ' 8px 12px' : '12px 16px') : 0};
        `;
      default:
        return '';
    }
  }}
`;
