import { useCallback, useMemo } from 'react';

import { useGetSpecialities } from 'http/selection/specialities-list/connectors';
import { useGetCategoriesList } from 'http/selection/categories-list/connector';
import { CorrectionQuestion, Type, QuoteType } from 'types/correction.type';

export interface AdaptedCorrectionQuestion {
  num: number;
  type: string;
  specialty1: string | null;
  spe1Trigram: string;
  specialty2: string | null;
  spe2Trigram: string;
  item?: string;
  coefficient: number;
  grade: number;
  statement: string;
  correction: string;
  quotes: QuoteType[];
  collection: string;
  knowledgeId: string;
  category: string;
}

const getCategoryfromId = (
  categoryIds: string[],
  data: { name: string; label: string; value: string }[]
): string[] => {
  const categories: string[] = categoryIds.reduce(
    (accumulator, currentValue) => {
      const foundCategory = data.find(
        (category) => category.value === currentValue
      );
      if (foundCategory) {
        accumulator.push(foundCategory.name);
      }
      return accumulator;
    },
    [] as string[]
  );

  return categories;
};

export const useAdaptedCorrectionQuestion = (rawData: CorrectionQuestion) => {
  const specialtiesQuery = useGetSpecialities();
  const { data } = useGetCategoriesList();
  const categories = data?.options;

  const mapSpeTrigramToDetails = useMemo(() => {
    const spes = specialtiesQuery.data;
    if (!spes) return {};

    return spes.reduce<{
      [trigram: string]: {
        name: string;
        trigram: string;
      };
    }>(
      (prev, spe) => ({
        ...prev,
        [spe.trigram]: {
          name: spe.name,
          trigram: spe.trigram,
        },
      }),
      {}
    );
  }, [specialtiesQuery.data]);

  const adaptCorrectionQuestion = useCallback(
    (rawData: CorrectionQuestion): AdaptedCorrectionQuestion => ({
      num: rawData.question.num,
      type: typeToString(rawData.question.type),
      quotes: rawData.question.quotes,
      specialty1: rawData.question.specialty1
        ? mapSpeTrigramToDetails[rawData.question.specialty1]?.name
        : '',
      spe1Trigram: rawData.question.specialty1 ?? '',
      specialty2: rawData.question.specialty2
        ? mapSpeTrigramToDetails[rawData.question.specialty2]?.name
        : '',
      spe2Trigram: rawData.question.specialty2 ?? '',
      item: rawData.question.item ?? undefined,
      coefficient: rawData.question.coefficient,
      grade: rawData.grade ?? 0,
      statement: rawData.question.statement,
      correction: rawData.question.correction,
      collection: rawData.question.collection,
      knowledgeId: rawData.question.knowledge?.knowledge,
      category: categories
        ? getCategoryfromId(rawData.question.categories, categories).join(' ')
        : '',
    }),
    [mapSpeTrigramToDetails, categories]
  );

  return useMemo(
    () => adaptCorrectionQuestion(rawData),
    [adaptCorrectionQuestion, rawData]
  );
};

const typeToString = (type: Type): string => {
  switch (type) {
    case 'QRM':
      return 'QRM';
    case 'QRPL':
      return 'QRPL';
    case 'QROC':
      return 'QROC';
    case 'TCS':
      return 'TCS';
    case 'QZP':
      return 'Zone à pointer';
    default:
      return type;
  }
};
