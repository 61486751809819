import { DPProgressByCompositionUnits } from 'modules/progress/general/dp/domain/entities/DPProgressByCompositionUnits';

export const progressMocks: DPProgressByCompositionUnits[] = [
  {
    collection: 'Concours blanc asclepia 2024',
    compositionUnits: {},
  },
  {
    collection: 'EDN 2023',
    compositionUnits: {},
  },
  {
    collection: 'ECNi 2023',
    compositionUnits: {
      '1': [
        {
          id: 532,
          num: 8,
          compositionUnit: 1,
          speTrigram1: 'MIT',
          lastCorrectionId: 1736194,
          hasBeenDone: true,
          isFree: true,
        },
        {
          id: 533,
          num: 20,
          compositionUnit: 1,
          speTrigram1: 'DER',
          lastCorrectionId: 1736281,
          hasBeenDone: true,
          isFree: false,
        },
        {
          id: 534,
          num: 23,
          compositionUnit: 1,
          speTrigram1: 'DER',
          lastCorrectionId: 1736193,
          hasBeenDone: true,
          isFree: false,
        },
        {
          id: 535,
          num: 34,
          compositionUnit: 1,
          speTrigram1: 'CVD',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 536,
          num: 43,
          compositionUnit: 1,
          speTrigram1: 'MPR',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 537,
          num: 45,
          compositionUnit: 1,
          speTrigram1: 'COT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 538,
          num: 47,
          compositionUnit: 1,
          speTrigram1: 'ORL',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 539,
          num: 53,
          compositionUnit: 1,
          speTrigram1: 'CMF',
          lastCorrectionId: 1736193,
          hasBeenDone: true,
          isFree: true,
        },
        {
          id: 540,
          num: 55,
          compositionUnit: 1,
          speTrigram1: 'REA',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 541,
          num: 59,
          compositionUnit: 1,
          speTrigram1: 'URO',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 590,
          num: 20,
          compositionUnit: 1,
          speTrigram1: 'DER',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 591,
          num: 23,
          compositionUnit: 1,
          speTrigram1: 'DER',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 592,
          num: 43,
          compositionUnit: 1,
          speTrigram1: 'MPR',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 593,
          num: 55,
          compositionUnit: 1,
          speTrigram1: 'REA',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 594,
          num: 59,
          compositionUnit: 1,
          speTrigram1: 'URO',
          hasBeenDone: false,
          isFree: false,
        },
      ],
      '2': [
        {
          id: 543,
          num: 8,
          compositionUnit: 2,
          speTrigram1: 'OPH',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 544,
          num: 9,
          compositionUnit: 2,
          speTrigram1: 'OPH',
          hasBeenDone: false,
          isFree: true,
        },
        {
          id: 545,
          num: 15,
          compositionUnit: 2,
          speTrigram1: 'MII',
          hasBeenDone: false,
          isFree: true,
        },
        {
          id: 546,
          num: 16,
          compositionUnit: 2,
          speTrigram1: 'RHU',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 547,
          num: 32,
          compositionUnit: 2,
          speTrigram1: 'NEU',
          hasBeenDone: false,
          isFree: true,
        },
        {
          id: 548,
          num: 33,
          compositionUnit: 2,
          speTrigram1: 'NEP',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 549,
          num: 38,
          compositionUnit: 2,
          speTrigram1: 'MCV',
          speTrigram2: 'NEP',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 550,
          num: 50,
          compositionUnit: 2,
          speTrigram1: 'GYN',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 551,
          num: 51,
          compositionUnit: 2,
          speTrigram1: 'MIT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 552,
          num: 54,
          compositionUnit: 2,
          speTrigram1: 'MIT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 595,
          num: 33,
          compositionUnit: 2,
          speTrigram1: 'NEP',
          hasBeenDone: false,
          isFree: false,
        },
      ],
      '3': [
        {
          id: 553,
          num: 1,
          compositionUnit: 3,
          speTrigram1: 'URO',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 555,
          num: 4,
          compositionUnit: 3,
          speTrigram1: 'GER',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 556,
          num: 7,
          compositionUnit: 3,
          speTrigram1: 'GER',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 557,
          num: 9,
          compositionUnit: 3,
          speTrigram1: 'MLE',
          hasBeenDone: false,
          isFree: true,
        },
        {
          id: 558,
          num: 11,
          compositionUnit: 3,
          speTrigram1: 'MLE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 559,
          num: 13,
          compositionUnit: 3,
          speTrigram1: 'MII',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 560,
          num: 14,
          compositionUnit: 3,
          speTrigram1: 'MCV',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 561,
          num: 18,
          compositionUnit: 3,
          speTrigram1: 'GYN',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 562,
          num: 19,
          compositionUnit: 3,
          speTrigram1: 'SPU',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 563,
          num: 20,
          compositionUnit: 3,
          speTrigram1: 'SPU',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 564,
          num: 27,
          compositionUnit: 3,
          speTrigram1: 'ONC',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 565,
          num: 28,
          compositionUnit: 3,
          speTrigram1: 'ONC',
          speTrigram2: 'URO',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 566,
          num: 31,
          compositionUnit: 3,
          speTrigram1: 'PED',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 567,
          num: 32,
          compositionUnit: 3,
          speTrigram1: 'PED',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 568,
          num: 46,
          compositionUnit: 3,
          speTrigram1: 'PSY',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 596,
          num: 7,
          compositionUnit: 3,
          speTrigram1: 'GER',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 597,
          num: 11,
          compositionUnit: 3,
          speTrigram1: 'MLE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 598,
          num: 13,
          compositionUnit: 3,
          speTrigram1: 'MII',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 599,
          num: 32,
          compositionUnit: 3,
          speTrigram1: 'PED',
          hasBeenDone: false,
          isFree: false,
        },
      ],
    },
  },
  {
    collection: 'CCB 2023',
    compositionUnits: {
      '1': [
        {
          id: 374,
          num: 1,
          compositionUnit: 1,
          speTrigram1: 'DSP',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 375,
          num: 3,
          compositionUnit: 1,
          speTrigram1: 'NEP',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 411,
          num: 6,
          compositionUnit: 1,
          speTrigram1: 'MCV',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 412,
          num: 11,
          compositionUnit: 1,
          speTrigram1: 'GYN',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 413,
          num: 12,
          compositionUnit: 1,
          speTrigram1: 'EDN',
          hasBeenDone: false,
          isFree: true,
        },
        {
          id: 414,
          num: 17,
          compositionUnit: 1,
          speTrigram1: 'DER',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 415,
          num: 18,
          compositionUnit: 1,
          speTrigram1: 'DER',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 416,
          num: 25,
          compositionUnit: 1,
          speTrigram1: 'HGE',
          hasBeenDone: false,
          isFree: true,
        },
        {
          id: 417,
          num: 35,
          compositionUnit: 1,
          speTrigram1: 'RHU',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 418,
          num: 37,
          compositionUnit: 1,
          speTrigram1: 'COT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 419,
          num: 46,
          compositionUnit: 1,
          speTrigram1: 'CMF',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 420,
          num: 50,
          compositionUnit: 1,
          speTrigram1: 'REA',
          hasBeenDone: false,
          isFree: false,
        },
      ],
      '2': [
        {
          id: 421,
          num: 1,
          compositionUnit: 2,
          speTrigram1: 'PSY',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 423,
          num: 4,
          compositionUnit: 2,
          speTrigram1: 'PSY',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 424,
          num: 8,
          compositionUnit: 2,
          speTrigram1: 'ACP',
          speTrigram2: 'MDT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 425,
          num: 11,
          compositionUnit: 2,
          speTrigram1: 'PNE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 426,
          num: 12,
          compositionUnit: 2,
          speTrigram1: 'PNE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 427,
          num: 13,
          compositionUnit: 2,
          speTrigram1: 'PED',
          hasBeenDone: false,
          isFree: true,
        },
        {
          id: 428,
          num: 14,
          compositionUnit: 2,
          speTrigram1: 'PED',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 429,
          num: 16,
          compositionUnit: 2,
          speTrigram1: 'PED',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 430,
          num: 19,
          compositionUnit: 2,
          speTrigram1: 'DSP',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 431,
          num: 20,
          compositionUnit: 2,
          speTrigram1: 'ONC',
          hasBeenDone: false,
          isFree: true,
        },
        {
          id: 432,
          num: 29,
          compositionUnit: 2,
          speTrigram1: 'NEU',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 433,
          num: 32,
          compositionUnit: 2,
          speTrigram1: 'NEP',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 434,
          num: 33,
          compositionUnit: 2,
          speTrigram1: 'MIT',
          speTrigram2: 'URO',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 435,
          num: 37,
          compositionUnit: 2,
          speTrigram1: 'MLE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 436,
          num: 39,
          compositionUnit: 2,
          speTrigram1: 'MII',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 437,
          num: 41,
          compositionUnit: 2,
          speTrigram1: 'MPR',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 438,
          num: 42,
          compositionUnit: 2,
          speTrigram1: 'RHU',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 439,
          num: 43,
          compositionUnit: 2,
          speTrigram1: 'NEU',
          hasBeenDone: false,
          isFree: false,
        },
      ],
      '3': [
        {
          id: 571,
          num: 1,
          compositionUnit: 3,
          speTrigram1: 'HGE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 572,
          num: 13,
          compositionUnit: 3,
          speTrigram1: 'PNE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 573,
          num: 14,
          compositionUnit: 3,
          speTrigram1: 'NEP',
          speTrigram2: 'PED',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 574,
          num: 15,
          compositionUnit: 3,
          speTrigram1: 'CVD',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 575,
          num: 16,
          compositionUnit: 3,
          speTrigram1: 'COT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 576,
          num: 17,
          compositionUnit: 3,
          speTrigram1: 'DER',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 577,
          num: 18,
          compositionUnit: 3,
          speTrigram1: 'PSY',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 511,
          num: 19,
          compositionUnit: 3,
          speTrigram1: 'PNE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 525,
          num: 37,
          compositionUnit: 3,
          speTrigram1: 'PED',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 503,
          num: 38,
          compositionUnit: 3,
          speTrigram1: 'MCV',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 504,
          num: 39,
          compositionUnit: 3,
          speTrigram1: 'PSY',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 526,
          num: 40,
          compositionUnit: 3,
          speTrigram1: 'DER',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 527,
          num: 41,
          compositionUnit: 3,
          speTrigram1: 'DER',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 528,
          num: 42,
          compositionUnit: 3,
          speTrigram1: 'GYN',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 529,
          num: 47,
          compositionUnit: 3,
          speTrigram1: 'HEM',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 530,
          num: 51,
          compositionUnit: 3,
          speTrigram1: 'MLE',
          hasBeenDone: false,
          isFree: false,
        },
      ],
      '4': [
        {
          id: 476,
          num: 1,
          compositionUnit: 4,
          speTrigram1: 'MCV',
          hasBeenDone: false,
          isFree: true,
        },
        {
          id: 478,
          num: 7,
          compositionUnit: 4,
          speTrigram1: 'MCV',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 479,
          num: 9,
          compositionUnit: 4,
          speTrigram1: 'ORL',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 480,
          num: 10,
          compositionUnit: 4,
          speTrigram1: 'SPU',
          hasBeenDone: false,
          isFree: true,
        },
        {
          id: 481,
          num: 11,
          compositionUnit: 4,
          speTrigram1: 'MII',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 482,
          num: 13,
          compositionUnit: 4,
          speTrigram1: 'HGE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 483,
          num: 14,
          compositionUnit: 4,
          speTrigram1: 'NEU',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 512,
          num: 15,
          compositionUnit: 4,
          speTrigram1: 'URO',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 485,
          num: 16,
          compositionUnit: 4,
          speTrigram1: 'MIT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 513,
          num: 17,
          compositionUnit: 4,
          speTrigram1: 'MIT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 514,
          num: 18,
          compositionUnit: 4,
          speTrigram1: 'MIT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 515,
          num: 23,
          compositionUnit: 4,
          speTrigram1: 'MII',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 516,
          num: 24,
          compositionUnit: 4,
          speTrigram1: 'HEM',
          hasBeenDone: false,
          isFree: true,
        },
        {
          id: 517,
          num: 25,
          compositionUnit: 4,
          speTrigram1: 'HEM',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 518,
          num: 26,
          compositionUnit: 4,
          speTrigram1: 'GYN',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 519,
          num: 27,
          compositionUnit: 4,
          speTrigram1: 'GYN',
          hasBeenDone: false,
          isFree: false,
        },
      ],
    },
  },
  {
    collection: 'ECNi 2022',
    compositionUnits: {
      '0': [
        {
          id: 337,
          num: 1,
          compositionUnit: null,
          speTrigram1: 'HGE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 347,
          num: 2,
          compositionUnit: null,
          speTrigram1: 'HEM',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 338,
          num: 3,
          compositionUnit: null,
          speTrigram1: 'MIT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 339,
          num: 4,
          compositionUnit: null,
          speTrigram1: 'MCV',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 353,
          num: 5,
          compositionUnit: null,
          speTrigram1: 'DER',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 354,
          num: 6,
          compositionUnit: null,
          speTrigram1: 'EDN',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 363,
          num: 7,
          compositionUnit: null,
          speTrigram1: 'CVD',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 336,
          num: 8,
          compositionUnit: null,
          speTrigram1: 'PNE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 349,
          num: 9,
          compositionUnit: null,
          speTrigram1: 'MII',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 364,
          num: 10,
          compositionUnit: null,
          speTrigram1: 'MPR',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 292,
          num: 11,
          compositionUnit: null,
          speTrigram1: 'NEP',
          hasBeenDone: false,
          isFree: true,
        },
        {
          id: 365,
          num: 12,
          compositionUnit: null,
          speTrigram1: 'COT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 293,
          num: 13,
          compositionUnit: null,
          speTrigram1: 'RHU',
          speTrigram2: 'PNE',
          hasBeenDone: false,
          isFree: true,
        },
        {
          id: 334,
          num: 14,
          compositionUnit: null,
          speTrigram1: 'GER',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 294,
          num: 15,
          compositionUnit: null,
          speTrigram1: 'NEU',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 366,
          num: 16,
          compositionUnit: null,
          speTrigram1: 'PED',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 335,
          num: 17,
          compositionUnit: null,
          speTrigram1: 'GYN',
          speTrigram2: 'ONC',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 352,
          num: 18,
          compositionUnit: null,
          speTrigram1: 'PSY',
          hasBeenDone: false,
          isFree: false,
        },
      ],
    },
  },
  {
    collection: 'ECNi 2021',
    compositionUnits: {
      '0': [
        {
          id: 276,
          num: 1,
          compositionUnit: null,
          speTrigram1: 'GYN',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 277,
          num: 2,
          compositionUnit: null,
          speTrigram1: 'GER',
          hasBeenDone: false,
          isFree: true,
        },
        {
          id: 278,
          num: 3,
          compositionUnit: null,
          speTrigram1: 'PNE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 279,
          num: 4,
          compositionUnit: null,
          speTrigram1: 'MCV',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 280,
          num: 5,
          compositionUnit: null,
          speTrigram1: 'HGE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 281,
          num: 6,
          compositionUnit: null,
          speTrigram1: 'RHU',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 270,
          num: 7,
          compositionUnit: null,
          speTrigram1: 'NEP',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 271,
          num: 8,
          compositionUnit: null,
          speTrigram1: 'MII',
          speTrigram2: 'NEU',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 272,
          num: 9,
          compositionUnit: null,
          speTrigram1: 'HEM',
          speTrigram2: 'MIT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 273,
          num: 10,
          compositionUnit: null,
          speTrigram1: 'DER',
          speTrigram2: 'NEU',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 274,
          num: 11,
          compositionUnit: null,
          speTrigram1: 'PED',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 275,
          num: 12,
          compositionUnit: null,
          speTrigram1: 'PSY',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 264,
          num: 13,
          compositionUnit: null,
          speTrigram1: 'MIT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 265,
          num: 14,
          compositionUnit: null,
          speTrigram1: 'REA',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 266,
          num: 15,
          compositionUnit: null,
          speTrigram1: 'NEU',
          hasBeenDone: false,
          isFree: true,
        },
        {
          id: 267,
          num: 16,
          compositionUnit: null,
          speTrigram1: 'SPU',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 268,
          num: 17,
          compositionUnit: null,
          speTrigram1: 'EDN',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 269,
          num: 18,
          compositionUnit: null,
          speTrigram1: 'ONC',
          speTrigram2: 'PNE',
          hasBeenDone: false,
          isFree: false,
        },
      ],
    },
  },
  {
    collection: 'ECNi 2020',
    compositionUnits: {
      '0': [
        {
          id: 5,
          num: 1,
          compositionUnit: null,
          speTrigram1: 'MCV',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 128,
          num: 2,
          compositionUnit: null,
          speTrigram1: 'GYN',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 129,
          num: 3,
          compositionUnit: null,
          speTrigram1: 'PSY',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 130,
          num: 4,
          compositionUnit: null,
          speTrigram1: 'MIT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 131,
          num: 5,
          compositionUnit: null,
          speTrigram1: 'HEM',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 132,
          num: 6,
          compositionUnit: null,
          speTrigram1: 'MPR',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 133,
          num: 7,
          compositionUnit: null,
          speTrigram1: 'MII',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 134,
          num: 8,
          compositionUnit: null,
          speTrigram1: 'MCV',
          speTrigram2: 'ONC',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 135,
          num: 9,
          compositionUnit: null,
          speTrigram1: 'NEP',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 136,
          num: 10,
          compositionUnit: null,
          speTrigram1: 'EDN',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 137,
          num: 11,
          compositionUnit: null,
          speTrigram1: 'DER',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 138,
          num: 12,
          compositionUnit: null,
          speTrigram1: 'MLE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 139,
          num: 13,
          compositionUnit: null,
          speTrigram1: 'HGE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 140,
          num: 14,
          compositionUnit: null,
          speTrigram1: 'PED',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 141,
          num: 15,
          compositionUnit: null,
          speTrigram1: 'NEU',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 169,
          num: 16,
          compositionUnit: null,
          speTrigram1: 'ORL',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 9,
          num: 17,
          compositionUnit: null,
          speTrigram1: 'PNE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 143,
          num: 18,
          compositionUnit: null,
          speTrigram1: 'RHU',
          hasBeenDone: false,
          isFree: false,
        },
      ],
    },
  },
  {
    collection: 'ECNi 2019',
    compositionUnits: {
      '0': [
        {
          id: 109,
          num: 1,
          compositionUnit: null,
          speTrigram1: 'MIT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 8,
          num: 2,
          compositionUnit: null,
          speTrigram1: 'PNE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 110,
          num: 3,
          compositionUnit: null,
          speTrigram1: 'COT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 170,
          num: 4,
          compositionUnit: null,
          speTrigram1: 'DER',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 171,
          num: 5,
          compositionUnit: null,
          speTrigram1: 'PSY',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 113,
          num: 6,
          compositionUnit: null,
          speTrigram1: 'NEU',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 114,
          num: 7,
          compositionUnit: null,
          speTrigram1: 'SPU',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 115,
          num: 8,
          compositionUnit: null,
          speTrigram1: 'CVD',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 172,
          num: 9,
          compositionUnit: null,
          speTrigram1: 'PED',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 117,
          num: 10,
          compositionUnit: null,
          speTrigram1: 'MII',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 173,
          num: 11,
          compositionUnit: null,
          speTrigram1: 'URO',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 174,
          num: 12,
          compositionUnit: null,
          speTrigram1: 'GYN',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 120,
          num: 13,
          compositionUnit: null,
          speTrigram1: 'MIT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 121,
          num: 14,
          compositionUnit: null,
          speTrigram1: 'NEP',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 122,
          num: 15,
          compositionUnit: null,
          speTrigram1: 'HEM',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 123,
          num: 16,
          compositionUnit: null,
          speTrigram1: 'GER',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 4,
          num: 17,
          compositionUnit: null,
          speTrigram1: 'MCV',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 175,
          num: 18,
          compositionUnit: null,
          speTrigram1: 'ORL',
          hasBeenDone: false,
          isFree: false,
        },
      ],
    },
  },
  {
    collection: 'ECNi 2018',
    compositionUnits: {
      '0': [
        {
          id: 90,
          num: 1,
          compositionUnit: null,
          speTrigram1: 'NEP',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 177,
          num: 2,
          compositionUnit: null,
          speTrigram1: 'GYN',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 92,
          num: 3,
          compositionUnit: null,
          speTrigram1: 'GER',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 93,
          num: 4,
          compositionUnit: null,
          speTrigram1: 'PNE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 94,
          num: 5,
          compositionUnit: null,
          speTrigram1: 'COT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 178,
          num: 6,
          compositionUnit: null,
          speTrigram1: 'PED',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 179,
          num: 7,
          compositionUnit: null,
          speTrigram1: 'EDN',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 97,
          num: 8,
          compositionUnit: null,
          speTrigram1: 'CVD',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 180,
          num: 9,
          compositionUnit: null,
          speTrigram1: 'ORL',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 99,
          num: 10,
          compositionUnit: null,
          speTrigram1: 'HEM',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 181,
          num: 11,
          compositionUnit: null,
          speTrigram1: 'OPH',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 101,
          num: 12,
          compositionUnit: null,
          speTrigram1: 'MII',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 102,
          num: 13,
          compositionUnit: null,
          speTrigram1: 'MLE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 103,
          num: 14,
          compositionUnit: null,
          speTrigram1: 'MIT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 104,
          num: 15,
          compositionUnit: null,
          speTrigram1: 'NEU',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 182,
          num: 16,
          compositionUnit: null,
          speTrigram1: 'DER',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 106,
          num: 17,
          compositionUnit: null,
          speTrigram1: 'HGE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 183,
          num: 18,
          compositionUnit: null,
          speTrigram1: 'PSY',
          hasBeenDone: false,
          isFree: false,
        },
      ],
    },
  },
  {
    collection: 'ECNi 2017',
    compositionUnits: {
      '0': [
        {
          id: 148,
          num: 1,
          compositionUnit: null,
          speTrigram1: 'NEU',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 3,
          num: 2,
          compositionUnit: null,
          speTrigram1: 'MCV',
          lastCorrectionId: 1736005,
          hasBeenDone: true,
          isFree: false,
        },
        {
          id: 72,
          num: 3,
          compositionUnit: null,
          speTrigram1: 'MIT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 185,
          num: 4,
          compositionUnit: null,
          speTrigram1: 'PED',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 74,
          num: 5,
          compositionUnit: null,
          speTrigram1: 'CMF',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 7,
          num: 6,
          compositionUnit: null,
          speTrigram1: 'PNE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 75,
          num: 7,
          compositionUnit: null,
          speTrigram1: 'HGE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 186,
          num: 8,
          compositionUnit: null,
          speTrigram1: 'CVD',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 187,
          num: 9,
          compositionUnit: null,
          speTrigram1: 'PSY',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 188,
          num: 10,
          compositionUnit: null,
          speTrigram1: 'URO',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 79,
          num: 11,
          compositionUnit: null,
          speTrigram1: 'MIT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 80,
          num: 12,
          compositionUnit: null,
          speTrigram1: 'NEU',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 189,
          num: 13,
          compositionUnit: null,
          speTrigram1: 'ONC',
          speTrigram2: 'URO',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 289,
          num: 14,
          compositionUnit: null,
          speTrigram1: 'DER',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 191,
          num: 15,
          compositionUnit: null,
          speTrigram1: 'PSY',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 84,
          num: 16,
          compositionUnit: null,
          speTrigram1: 'HEM',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 288,
          num: 17,
          compositionUnit: null,
          speTrigram1: 'MII',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 193,
          num: 18,
          compositionUnit: null,
          speTrigram1: 'OPH',
          hasBeenDone: false,
          isFree: false,
        },
      ],
    },
  },
  {
    collection: 'CCB 2017',
    compositionUnits: {
      '0': [
        {
          id: 195,
          num: 1,
          compositionUnit: null,
          speTrigram1: 'PNE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 196,
          num: 2,
          compositionUnit: null,
          speTrigram1: 'DER',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 287,
          num: 3,
          compositionUnit: null,
          speTrigram1: 'HGE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 358,
          num: 4,
          compositionUnit: null,
          speTrigram1: 'NEU',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 199,
          num: 5,
          compositionUnit: null,
          speTrigram1: 'MIT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 53,
          num: 6,
          compositionUnit: null,
          speTrigram1: 'NEP',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 200,
          num: 7,
          compositionUnit: null,
          speTrigram1: 'MII',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 201,
          num: 8,
          compositionUnit: null,
          speTrigram1: 'PED',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 202,
          num: 9,
          compositionUnit: null,
          speTrigram1: 'GYN',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 359,
          num: 10,
          compositionUnit: null,
          speTrigram1: 'PNE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 360,
          num: 11,
          compositionUnit: null,
          speTrigram1: 'PSY',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 351,
          num: 12,
          compositionUnit: null,
          speTrigram1: 'MCV',
          speTrigram2: 'MIT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 206,
          num: 13,
          compositionUnit: null,
          speTrigram1: 'REA',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 61,
          num: 14,
          compositionUnit: null,
          speTrigram1: 'HEM',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 207,
          num: 15,
          compositionUnit: null,
          speTrigram1: 'URO',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 361,
          num: 16,
          compositionUnit: null,
          speTrigram1: 'RHU',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 209,
          num: 17,
          compositionUnit: null,
          speTrigram1: 'OPH',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 210,
          num: 18,
          compositionUnit: null,
          speTrigram1: 'HGE',
          hasBeenDone: false,
          isFree: false,
        },
      ],
    },
  },
  {
    collection: 'ECNi 2016',
    compositionUnits: {
      '0': [
        {
          id: 1,
          num: 1,
          compositionUnit: null,
          speTrigram1: 'MCV',
          lastCorrectionId: 1736448,
          hasBeenDone: true,
          isFree: false,
        },
        {
          id: 32,
          num: 2,
          compositionUnit: null,
          speTrigram1: 'PNE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 213,
          num: 3,
          compositionUnit: null,
          speTrigram1: 'CVD',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 214,
          num: 4,
          compositionUnit: null,
          speTrigram1: 'GYN',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 362,
          num: 5,
          compositionUnit: null,
          speTrigram1: 'PED',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 36,
          num: 6,
          compositionUnit: null,
          speTrigram1: 'NEU',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 37,
          num: 7,
          compositionUnit: null,
          speTrigram1: 'MIT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 216,
          num: 8,
          compositionUnit: null,
          speTrigram1: 'DER',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 217,
          num: 9,
          compositionUnit: null,
          speTrigram1: 'ORL',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 218,
          num: 10,
          compositionUnit: null,
          speTrigram1: 'PSY',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 41,
          num: 11,
          compositionUnit: null,
          speTrigram1: 'RHU',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 219,
          num: 12,
          compositionUnit: null,
          speTrigram1: 'ONC',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 10,
          num: 13,
          compositionUnit: null,
          speTrigram1: 'HGE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 43,
          num: 14,
          compositionUnit: null,
          speTrigram1: 'COT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 220,
          num: 15,
          compositionUnit: null,
          speTrigram1: 'MII',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 221,
          num: 16,
          compositionUnit: null,
          speTrigram1: 'OPH',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 46,
          num: 17,
          compositionUnit: null,
          speTrigram1: 'NEP',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 2,
          num: 18,
          compositionUnit: null,
          speTrigram1: 'MCV',
          lastCorrectionId: 1736447,
          hasBeenDone: true,
          isFree: false,
        },
      ],
    },
  },
  {
    collection: 'CCB 2016',
    compositionUnits: {
      '0': [
        {
          id: 355,
          num: 1,
          compositionUnit: null,
          speTrigram1: 'PED',
          lastCorrectionId: 1736277,
          hasBeenDone: true,
          isFree: false,
        },
        {
          id: 224,
          num: 2,
          compositionUnit: null,
          speTrigram1: 'PNE',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 350,
          num: 3,
          compositionUnit: null,
          speTrigram1: 'MCV',
          lastCorrectionId: 1736006,
          hasBeenDone: true,
          isFree: false,
        },
        {
          id: 226,
          num: 4,
          compositionUnit: null,
          speTrigram1: 'EDN',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 356,
          num: 5,
          compositionUnit: null,
          speTrigram1: 'NEU',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 228,
          num: 6,
          compositionUnit: null,
          speTrigram1: 'REA',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 229,
          num: 7,
          compositionUnit: null,
          speTrigram1: 'ORL',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 230,
          num: 8,
          compositionUnit: null,
          speTrigram1: 'RHU',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 231,
          num: 9,
          compositionUnit: null,
          speTrigram1: 'OPH',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 232,
          num: 10,
          compositionUnit: null,
          speTrigram1: 'GYN',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 233,
          num: 11,
          compositionUnit: null,
          speTrigram1: 'GER',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 234,
          num: 12,
          compositionUnit: null,
          speTrigram1: 'PSY',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 235,
          num: 13,
          compositionUnit: null,
          speTrigram1: 'MIT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 236,
          num: 14,
          compositionUnit: null,
          speTrigram1: 'MII',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 25,
          num: 15,
          compositionUnit: null,
          speTrigram1: 'NEP',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 237,
          num: 16,
          compositionUnit: null,
          speTrigram1: 'MDT',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 27,
          num: 17,
          compositionUnit: null,
          speTrigram1: 'HEM',
          hasBeenDone: false,
          isFree: false,
        },
        {
          id: 357,
          num: 18,
          compositionUnit: null,
          speTrigram1: 'SPU',
          hasBeenDone: false,
          isFree: false,
        },
      ],
    },
  },
];
