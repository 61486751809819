import styled from 'styled-components';
import { AdCard } from 'ui/Cards/AdCard';
import { NewsletterButton } from './NewsletterButton';
import { useGetUserOptions } from 'modules/user-options/hooks/useGetUserOptions';

export const NewsletterCard = () => {
  const { data: userOptions, isLoading } = useGetUserOptions();

  if (!userOptions || isLoading) return null;
  if (userOptions.needs_newsletter_prompt === false) return null;
  if (userOptions.newsletter === true) return null;
  return (
    <AdCard>
      <Title>👀 Ne manquez aucune information importante !</Title>
      <TextContainer>
        Inscrivez-vous à notre newsletter et soyez les premiers informés des
        offres, des nouveautés et autres informations importantes concernant le
        concours des EDN. S’inscrire à la newsletters
      </TextContainer>
      <ButtonContainer>
        <NewsletterButton
          activeText={'Rester informé'}
          desactiveText={'Le rappel est activé'}
          color={''}
        />
      </ButtonContainer>
    </AdCard>
  );
};

const Title = styled.h4`
  word-wrap: break-word;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TextContainer = styled.div`
  word-wrap: break-word;
`;
