export interface CCBCreateTaskParams {
  ccb_id: number;
  composition_unit: string;
}

export interface CCBTask {
  id: number;
  user?: number;
  date_creation?: string;
  date_update?: string;
  finished: boolean;
  total_time?: number;
  timer?: number;
  last_update?: string;
  paused?: boolean;
  type?: string;
  composition_unit?: string;
}

export interface CurrentCCB {
  id: number;
  start_date: string;
  end_date: string;
  permission: 'free' | 'private' | 'premium';
  collection: Collection;
  correction: boolean;
  promotions: Option[];
  private_users: number[];
}

export interface FormattedCCBStats {
  ccbStats: CCBStats;
  ccbRanking: CCBRanking;
}

export interface Collection {
  collection: string;
  label: string;
  grading: string;
  order: number;
}

export interface CCBStatus {
  [TasksType.UC1]: number | null | { task_id: number; finished: boolean };
  [TasksType.UC2]: number | null | { task_id: number; finished: boolean };
  [TasksType.UC3]: number | null | { task_id: number; finished: boolean };
  [TasksType.LCA]: number | null | { task_id: number; finished: boolean };
}

export enum TasksType {
  UC1 = 'UC1',
  UC2 = 'UC2',
  UC3 = 'UC3',
  LCA = 'LCA',
}

export type Statstabs = 'Classement' | 'Statistique' | 'Correction';

export interface FormatedCCBSession {
  taskId: number | null;
  finished: boolean;
  composition_unit: string;
  start_date: string | undefined;
  end_date: string | undefined;
  duration: string;
  hasBeenStarted: boolean;
  timeRange: string;
}

export interface CCBStats {
  user_global_grade: number;
  user_rank_A_grade: number;
  user_UC1_grade: number;
  user_UC2_grade: number;
  user_UC3_grade: number;
  user_LCA_grade: number;
  user_LCA1_grade: number;
  user_LCA2_grade: number;
  period_participants: number;
  period_average_global_grade: number;
  period_passed_rank_A_percentage: number;
  total_participants: number;
  total_average_global_grade: number;
  total_passed_rank_A_percentage: number;
  user_specialty_responses: UserSpecialityResponses[];
  lca_total_pass: number;
  lca_partial_pass: number;
  lca_fail: number;
  period_rank_global: number;
  period_rank_uc1: number;
  period_rank_uc2: number;
  period_rank_uc3: number;
  period_rank_lca: number;
  period_rank_lca1: number;
  period_rank_lca2: number;
  total_rank_global: number;
  total_rank_uc1: number;
  total_rank_uc2: number;
  total_rank_uc3: number;
  total_rank_lca: number;
  total_rank_lca1: number;
  total_rank_lca2: number;
  period_grades_global: number[];
  period_grades: GradeCount[];
  total_grades_global: number[];
  total_grades: GradeCount[];
}

export type UserSpecialityResponses = {
  specialty: SpecialityInfo;
  fail: number;
  partial_pass: number;
  total_pass: number;
};

export type GradeCount = {
  grade: number;
  count_uc1: number;
  count_uc2: number;
  count_uc3: number;
  count_lca: number;
  count_lca1: number;
  count_lca2: number;
  myGrade?: number;
};

export type SpecialityInfo = {
  trigram: string;
  compressed_name: string;
  name: string;
  red: number;
  green: number;
  blue: number;
  rank: number;
  color: string;
  specialty: string;
  books: number[];
};

export interface CCBRanking {
  user_global_grade: number;
  user_rank_A_grade: number;
  period_participants: number;
  period_passed_rank_A_percentage: number;
  total_participants: number;
  period_average_global_grade: number;
  total_average_global_grade: number;
  total_passed_rank_A_percentage: number;
  des_group_ranking: DESGroupRanking[];
}

export type DESGroupRanking = {
  des_group: string;
  grade: number;
  period_rank: number;
  global_rank: number;
};

export interface CCBGetUserStatsParams {}

export type CCBStatsColumns =
  | 'specialities'
  | 'grades'
  | 'periodRank'
  | 'generalRank';

export interface LCAArticles {
  article1: string;
  article2: string;
}

export interface Option {
  code: string | null;
  name: string;
}

export interface CCBCorrection {
  composition_unit_ranking: InfoForSession[];
}

export interface InfoForSession {
  composition_unit: string;
  grade: number;
  period_rank: number;
  global_rank: number;
  task_id: number;
}

export interface Promotion {
  code: string;
  name: string;
}

export interface availableCCB {
  id: number;
  collection: {
    collection: string;
    label: string;
    grading: string;
    order: number;
  };
  start_date: string;
  end_date: string;
  permission: 'free' | 'private' | 'premium';
  correction: boolean;
  promotions: Promotion[];
  total_participants: number;
  period_participants: number;
  user_global_grade: number;
  period_average: number;
  private_users: number[];
  completed_tasks: number;
  completed_date: string | null;
  isCCBActivatedFree: boolean;
  isCCBActivatedPremium: boolean;
  hasUpdatedPromotion: boolean;
  promotions_updated_at?: string | null;
}
export interface selectedCCB extends availableCCB {
  sessions?: FormatedCCBSession[];
  sessionsLeftToDo?: FormatedCCBSession[];
  nextTask?: CCBTask | undefined;
  lastTask?: CCBTask | undefined;
  ccbStatsAvailable?: boolean;
  statsActivePromotion?: Option;
  statsActiveTab?: Statstabs;
  specialities?: string[];
  grades?: string[];
  periodRank?: string[];
  generalRank?: string[];
  ccbStats?: CCBStats | undefined;
  ccbRanking?: CCBRanking | undefined;
  ccbCorrection?: CCBCorrection | undefined;
  allDone?: boolean;
}

export enum CCBScreenType {
  PreCCBInfo = 'PreCCBInfo',
  CCBLauchScreen = 'CCBLauchScreen',
  PostCCB = 'PostCCB',
  Statistics = 'Statistics',
}
