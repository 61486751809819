import styled from 'styled-components/macro';
import { Arrow } from '../../../ui/icons/Arrows/Arrow';
import { useCompassInfoContext } from '../context/compassInfoContext';

export const BackwardsButton = () => {
  const { history, setActiveTab, setActiveBook, setActiveItem, setHistory } =
    useCompassInfoContext();

  const navigateToPrevious = () => {
    const index = history.length ?? 1;
    const destinationElement = history[index - 1];
    if (destinationElement.tab === 'books') {
      setActiveBook(destinationElement.id);
    }
    if (destinationElement.tab === 'items') {
      setActiveItem(destinationElement.id);
    }
    if (history.length > 1) {
      setHistory(history.slice(0, -1));
      setActiveTab(destinationElement.tab);
    } else {
      setHistory([{ tab: 'book', id: 1 }]);
      setActiveTab('books');
    }
  };

  return (
    <BackwardsContainer
      onClick={() => {
        navigateToPrevious();
      }}
    >
      <BackwardsIcon />
      <label>Retour</label>
    </BackwardsContainer>
  );
};

const BackwardsContainer = styled.div`
  margin-left: 52px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 16px;
  :hover {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const BackwardsIcon = styled(Arrow)`
  height: 16px;
  width: 8px;
  cursor: pointer;
`;
