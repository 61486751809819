import { FC, useState } from 'react';
import { ButtonBase } from 'ui/Buttons/ButtonBase';
import { useAccount } from '../../pages/account/useAccount';
import { useGetFastUserOptions } from 'http/account/connector';
import { toast } from 'react-toastify';

interface NewsletterButtonProps {
  activeText: string;
  desactiveText: string;
  color: string;
  handleClickComplement?: () => void;
}

export const NewsletterButton: FC<NewsletterButtonProps> = ({
  activeText,
  desactiveText,
  color,
  handleClickComplement,
}) => {
  const { updateUserOptions } = useAccount();
  const user = useGetFastUserOptions();
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const [text, setText] = useState<string>(activeText);

  const handleClick = () => {
    if (user) {
      updateUserOptions({
        ...user,
        newsletter: true,
        needs_newsletter_prompt: false,
      });
      setIsSubscribed(true); // Update state optimistically
      setText(desactiveText);
      toast.info('Vous êtes bien inscrit à la newsletter Asclepia !');
    }
    handleClickComplement && handleClickComplement();
  };

  return (
    <ButtonBase
      onClick={handleClick}
      size="large"
      disabled={isSubscribed}
      styled={color}
      iconOnly={false}
      hasPadding={true}
    >
      {text}
    </ButtonBase>
  );
};
