import { FC } from 'react';
import styled from 'styled-components';

interface Props {
  periodParticipants: number;
  totalParticipants?: number | undefined;
}

export const ParticipantsContainer: FC<Props> = ({
  periodParticipants,
  totalParticipants,
}) => {
  return (
    <Container>
      <RanksContainer>
        <h5>{periodParticipants}</h5>
        <StyledText>participants sur la période</StyledText>
      </RanksContainer>
      {totalParticipants !== undefined && (
        <RanksContainer>
          <h5>{totalParticipants}</h5>
          <StyledText>participants au total</StyledText>
        </RanksContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  max-width: fit-content;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`;

const RanksContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StyledText = styled.div`
  color: ${({ theme }) => theme.colors.inactive};
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;
