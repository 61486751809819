import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { device } from 'helpers/css/responsive';

import { Card } from 'ui/Card';
import { TextInput } from 'ui/TextInput';
import { Button } from 'ui/Buttons/Button';
import { LoginRelatedBackground } from 'components/LoginRelatedBackground';
import { useIsLoading } from 'hooks/useIsLoading';

import {
  useLostPasswordForm,
  useLostPasswordFormSubmit,
} from './lostPasswordForm';

export const LostPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useLostPasswordForm();

  const { isLoading, startLoading, stopLoading } = useIsLoading();

  const onSubmit = useLostPasswordFormSubmit({
    onFetch: startLoading,
    afterFetch: stopLoading,
  });

  return (
    <LoginRelatedBackground>
      <Container>
        <Card>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <h1>Mot de passe perdu</h1>

            <TextInput
              label="Adresse e-mail"
              type="email"
              autoComplete="email"
              errorMessage={errors.email?.message}
              {...register('email')}
            />

            <Button type="submit" isLoading={isLoading}>
              Réinitialiser le mot de passe
            </Button>

            <span>
              Besoin d'un compte ? <Link to="/">S'inscrire</Link>
            </span>
          </Form>
        </Card>
      </Container>
    </LoginRelatedBackground>
  );
};

const Container = styled.div`
  height: 100%;
  min-height: 100vh;

  display: grid;
  place-items: center;

  padding: 16px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  gap: 16px;

  @media ${device.desktop} {
    gap: 32px;
  }
`;
