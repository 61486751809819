import { FC } from 'react';
import styled from 'styled-components/macro';
import { SpecialtyIcon } from 'ui/icons/SpecialtyIcon/SpecialtyIcon';
import ItemFromId from 'components/Item/ItemFromId';
import { KnowledgeRank } from 'components/Knowledge/KnowledgeRank';
import { CorrectionThemeProvider } from 'components/CorrectionThemeProvider';
import { DotSeparator } from 'ui/DotSeparator';
import { Knowledge } from 'types/knowledge.type';

interface Props {
  collection: string;
  type: string;
  questionNum: number;
  examNumber?: number;
  specialty: string;
  item?: string;
  knowledge: Knowledge;
  composition?: number;
  category?: string;
  group_type: string;
  composition_unit?: string;
}

export const QuestionHeaders: FC<Props> = ({
  collection,
  type,
  questionNum,
  examNumber,
  specialty,
  item,
  knowledge,
  composition,
  category,
  group_type,
}) => {
  return (
    <CorrectionThemeProvider>
      <Container>
        <div>
          {collection}
          {composition ? ` UC${composition}` : ''}
        </div>
        <DotSeparator />
        {type === 'QI' ? (
          <div>
            {type} n°{questionNum}
          </div>
        ) : (
          <div>
            {type} {examNumber} Q n°
            {questionNum}
          </div>
        )}
        <DotSeparator />
        <div>{group_type}</div>
        <DotSeparator />
        <SpecialtyIcon trigram={specialty} />
        {item && (
          <>
            <ItemFromId itemId={item} />
            <DotSeparator />
          </>
        )}

        {knowledge && (
          <>
            <RankContainer>
              <KnowledgeRank label={knowledge.label} rank={knowledge.rank} />
            </RankContainer>
          </>
        )}

        {category && (
          <>
            <DotSeparator />
            <div>{category}</div>
          </>
        )}
      </Container>
    </CorrectionThemeProvider>
  );
};

const RankContainer = styled.div`
  ${({ theme }) => theme.typography.h2}
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  gap: 16px;
  ${({ theme }) => theme.typography.h2}
`;
