import styled from 'styled-components/macro';

import { device } from 'helpers/css/responsive';
import { useIsLoading } from 'hooks/useIsLoading';

import { Card } from 'ui/Card';
import { Button } from 'ui/Buttons/Button';
import { TextInput } from 'ui/TextInput';
import { LoginRelatedBackground } from 'components/LoginRelatedBackground';

import {
  useChangeEmailForm,
  useChangeEmailFormSubmit,
} from './changeEmailForm';

export const ChangeEmail = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useChangeEmailForm();

  const { isLoading, startLoading, stopLoading } = useIsLoading();

  const onSubmit = useChangeEmailFormSubmit({
    onFetch: startLoading,
    afterFetch: stopLoading,
  });

  return (
    <LoginRelatedBackground>
      <Container>
        <Card>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Title>Changement d'adresse email</Title>

            <TextInput
              label="Nouvelle adresse email"
              autoComplete="email"
              errorMessage={errors.newEmail?.message}
              {...register('newEmail')}
            />

            <Button type="submit" isLoading={isLoading}>
              Valider la nouvelle adresse email
            </Button>
          </Form>
        </Card>
      </Container>
    </LoginRelatedBackground>
  );
};

const Container = styled.div`
  height: 100%;
  min-height: 100vh;

  display: grid;
  place-items: center;

  padding: 16px;
`;

const Title = styled.h1`
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  gap: 16px;

  @media ${device.desktop} {
    gap: 32px;
  }
`;
