import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import {
  FQDeckName,
  FQResultsType,
} from 'http/flash-questions/fqdeckcreation/types';
import { FQMode } from 'pages/flash-questions/types';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { setMode } from 'store/slices/fqCurrentSlice';
import { reset } from 'store/slices/fqTableSlice';
import * as yup from 'yup';

interface DeckNameFormInput {
  deckName: string;
}

const deckNameFormSchema = yup.object({
  deckName: yup
    .string()
    .required('Veuillez entrer un nom de paquet')
    .max(30, 'Le nom du paquet ne doit pas dépasser 30 caractères'),
});

export const useDeckNameForm = () => {
  return useForm<DeckNameFormInput>({
    resolver: yupResolver(deckNameFormSchema),
    mode: 'onSubmit', // You can change this to 'onChange' or 'onBlur' if you want validation to happen earlier
  });
};

export const useOnDeckSubmit = ({
  selectedFQ,
  createDeckFromName,
  addCardsToDeck,
  onFetch,
  afterFetch,
  resetCreationPage,
  refetchDeckList,
}: {
  selectedFQ: FQResultsType[];
  createDeckFromName: (
    params: FQDeckName,
    onSuccess: () => void,
    onError: (err: unknown) => void
  ) => void;
  addCardsToDeck: (deckName: string, selectedFQids: number[]) => void;
  onFetch: () => void;
  afterFetch: () => void;
  resetCreationPage: () => void;
  refetchDeckList: () => void;
}) => {
  const dispatch = useDispatch();
  const goToDeckList = () => {
    dispatch(setMode({ mode: FQMode.DECKLIST }));
  };
  const onDeckSubmit: SubmitHandler<DeckNameFormInput> = async (data) => {
    onFetch();

    if (selectedFQ.length === 0) {
      toast.error('Au moins une question flash doit être sélectionnée');
    } else {
      const successFcn = () => {
        addCardsToDeck(
          data.deckName,
          selectedFQ.map(({ id }) => id)
        );
        resetCreationPage();
        refetchDeckList();
        goToDeckList();
        toast.info(`Le paquet nommé ${data.deckName} a bien été créé !`);
        dispatch(reset());
      };
      const errorFcn = (err: unknown) => {
        if (axios.isAxiosError(err)) {
          if (err.response?.status === 409) {
            toast.error(
              'Un paquet avec ce nom existe déjà. Veuillez en choisir un autre'
            );

            return;
          }
        }

        toast.error("Vous devez d'abord nommer votre paquet");
      };
      createDeckFromName({ label: data.deckName }, successFcn, errorFcn);
    }

    afterFetch();
  };

  return onDeckSubmit;
};
