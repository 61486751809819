import { FC, useMemo } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Cell,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

import { SpecialtyIcon } from 'ui/icons/SpecialtyIcon/SpecialtyIcon';
import { useGetDpQiSelectionStats } from 'pages/start/hooks/useStartChartsData/useGetDpQiSelectionStats';
import { useAdaptCounterToSpecialtyChartData } from 'pages/start/hooks/useStartChartsData/useAdaptCounterToSpecialtyChartData';
import { useTour } from '@reactour/tour';
import { chartDataMocks } from 'pages/start/mocks/startMocks';

export interface SpecialtyChartData {
  trigram: string;
  name: string;
  count: number;
  color: string;
}

const BAR_HEIGHT = 40;

interface Props {}

export const SpecialitiesBarChart: FC<Props> = () => {
  const theme = useTheme();
  const { isOpen } = useTour();

  const selectionStats = useGetDpQiSelectionStats();

  const adaptCounterToSpecialtyChartData =
    useAdaptCounterToSpecialtyChartData();

  const chartData = useMemo(() => {
    return adaptCounterToSpecialtyChartData(selectionStats.specialties);
  }, [adaptCounterToSpecialtyChartData, selectionStats.specialties]);

  return (
    <Container>
      <ResponsiveContainer
        height={(isOpen ? chartDataMocks : chartData).length * BAR_HEIGHT}
        width="100%"
      >
        <BarChart
          layout="vertical"
          margin={{ bottom: 4, top: 4, left: 4, right: 30 }}
          barCategoryGap={4}
          barGap={4}
          data={isOpen ? chartDataMocks : chartData}
        >
          <Bar
            dataKey="count"
            label={{ position: 'right', fill: theme.colors.primary.dark }}
            barSize={16}
          >
            {(isOpen ? chartDataMocks : chartData).map((entry, index) => (
              <Cell fill={entry.color} key={index} />
            ))}
          </Bar>
          <YAxis
            type="category"
            tickLine={false}
            axisLine={false}
            dataKey="name"
            tick={(props) => {
              return (
                <foreignObject
                  style={{ overflow: 'visible' }}
                  width="26"
                  height="26"
                  x={props.x - 26}
                  y={props.y - 14}
                >
                  <SpecialtyIcon
                    trigram={
                      (isOpen ? chartDataMocks : chartData)[props.index].trigram
                    }
                  />
                </foreignObject>
              );
            }}
          />
          <XAxis type="number" hide />
          <Tooltip
            cursor={{ fill: 'transparent' }}
            content={(data) => {
              return (
                <div
                  style={{
                    padding: '8px',
                    backgroundColor: 'white',
                    border: '1px solid grey',
                    borderRadius: 4,
                    color: 'black',
                  }}
                >
                  {data.label}
                </div>
              );
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
};

const Container = styled.div`
  height: 316px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border: ${({ theme }) => theme.colors.primary.light} 1px solid;
  border-radius: 4px;
`;
