import { FC, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import parse from 'html-react-parser';
import { buttonReset } from 'helpers/css/button-reset';
import { popSlideInAnimation } from 'helpers/css/animations/popSlideIn';
import { StyledMathJax } from 'ui/MathJax';
import BlueCard from 'ui/Cards/BlueCard';
import { MultipleKnowledgeRank } from 'components/Knowledge/MultipleKnowledgeRank';
import { Books } from 'components/Book/Books';
import { Sources } from 'components/Source/Sources';
import { Lesson } from 'types/lesson.type';
import { ItemsFromKnowledges } from 'components/Item/ItemsFromKnowledges';
import { DropdownChevron } from 'ui/DropdownChevron';
import { useTour } from '@reactour/tour';

interface Props {
  lesson: Lesson;
}
export const CorrectionRelatedLesson: FC<Props> = ({ lesson }) => {
  const [isBodyShown, setIsBodyShown] = useState(false);
  const toggleVisibility = () => setIsBodyShown(!isBodyShown);
  const { isOpen } = useTour();

  useEffect(() => {
    if (
      isOpen &&
      lesson.title.split(' ').join('-') === 'Sémiologie-des-valvulopathies'
    ) {
      setIsBodyShown(true);
    }
  }, [isOpen, lesson.title]);
  return (
    <BlueCard
      $smallPading
      key={lesson.title}
      hasMargin={false}
      fullWidth
      className={`correction-card-group-${lesson.title.split(' ').join('-')}`}
    >
      <Title onClick={toggleVisibility}>
        <Left>
          <div>{lesson.title}</div>
        </Left>
        <Right>
          {lesson.knowledges.length !== 0 && (
            <StyledItem>
              <ItemsFromKnowledges knowledges={lesson.knowledges} />
            </StyledItem>
          )}
          <MultipleKnowledgeRank knowledges={lesson.knowledges} />
          <DropdownChevron isOpen={isBodyShown} />
        </Right>
      </Title>
      {isBodyShown && (
        <BodyContainer className="lesson-body">
          <LessonBody>
            <StyledMathJax inline>
              {lesson.content ? parse(lesson.content) : ''}
            </StyledMathJax>
            <Footer>
              <SubTitle>LIVRES</SubTitle>
              <Books books={lesson.books} isBlue={false} />
              <Sources sources={lesson.sources} />
            </Footer>
          </LessonBody>
        </BodyContainer>
      )}
    </BlueCard>
  );
};

const Left = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  ${({ theme }) => theme.typography.h2}
`;
const Right = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  ${({ theme }) => theme.typography.h2}
  gap: 3rem;
`;

const StyledItem = styled.h2`
  font-family: 'Free-Sans', sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.2;
`;

const Title = styled.button`
  ${buttonReset}
  ${({ theme }) => theme.typography.h2}
  border-radius: 8px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
`;

const BodyContainer = styled.div`
  overflow: hidden;
`;

const LessonBody = styled.div`
  padding: 0 16px;

  ${popSlideInAnimation}

  img {
    max-width: 100%;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

const SubTitle = styled.div`
  margin-bottom: 8px;
`;
