import { FC } from 'react';
import styled from 'styled-components';
import { Loader } from './Loader';
interface Props {
  text?: string;
}

export const LoaderWithText: FC<Props> = ({ text }) => {
  return (
    <LoaderContainer>
      <Loader />
      {text && <h3>{text}</h3>}
    </LoaderContainer>
  );
};

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  min-height: 500px;
  width: 100%;
  height: 100%;
`;
