import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { routes } from 'routing/constants';
import { Button } from 'ui/Buttons/Button';
import { useGetStaticPage } from 'http/static-pages/connector';
import { ContainedLoader } from 'components/ContainedLoader';
import { StyledMathJax } from 'ui/MathJax';
import parse from 'html-react-parser';

export const PremiumLockedFlash = () => {
  const { data: page, isLoading: isLoadingPage } = useGetStaticPage('Flash');

  if (isLoadingPage) {
    return (
      <LoadingContainer>
        <ContainedLoader />
      </LoadingContainer>
    );
  }

  return (
    <Container>
      {page && (
        <StaticCOntentContainer>
          <StyledMathJax inline>{parse(page.page)}</StyledMathJax>
        </StaticCOntentContainer>
      )}
      <UnstyledLink to={routes.SUBSCRIBE}>
        <StyledButton>S'abonner</StyledButton>
      </UnstyledLink>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10%;
  height: 100%;
  min-height: 50vh;
  width: 100%;
`;

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.secondary.main};
  color: ${({ theme }) => theme.colors.secondary.contrastText};
`;

const UnstyledLink = styled(Link)`
  /* Override link default styles */
  text-decoration: none !important;
`;

const StaticCOntentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  margin-bottom: 48px;
`;

const LoadingContainer = styled.div`
  height: 100%;
  display: grid;
  place-items: center;
`;
