import styled from 'styled-components/macro';
import { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateStatsActivePromotion } from 'store/slices/ccbSlice';
import { device } from 'helpers/css/responsive';
import { Option } from 'http/ccb/type';
import { RootState } from 'store';

interface PromotionFiltersProps {
  isShown: boolean;
}

export const PromotionFilters: FC<PromotionFiltersProps> = ({ isShown }) => {
  const dispatch = useDispatch();
  const { activeCcb } = useSelector((state: RootState) => state.ccb);

  const handleClick = (clickedPromotion: Option) => {
    if (clickedPromotion !== activeCcb?.statsActivePromotion) {
      dispatch(
        updateStatsActivePromotion({
          ccbId: activeCcb?.id,
          promotion: clickedPromotion,
        })
      );
    }
  };

  if (!isShown) return null;

  return (
    <Container>
      <PromotionButton
        isActive={'Toutes les promos' === activeCcb?.statsActivePromotion?.name}
        onClick={() => handleClick({ name: 'Toutes les promos', code: null })}
      >
        Toutes les promos
      </PromotionButton>
      {activeCcb?.promotions?.map((promotion: Option) => (
        <PromotionButton
          key={promotion?.code}
          isActive={promotion?.name === activeCcb?.statsActivePromotion?.name}
          onClick={() => handleClick(promotion)}
        >
          <p>{promotion?.name}</p>
        </PromotionButton>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  white-space: nowrap;
  flex-wrap: wrap;

  @media ${device.desktop} {
    flex-wrap: none;
  }
`;

const PromotionButton = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  max-height: 36px;
  background-color: ${({ isActive }) =>
    isActive
      ? ({ theme }) => theme.colors.primary.main
      : ({ theme }) => theme.colors.primary.lighter};
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.main};
  }
`;
