import {
  DoneTableContainer,
  FirstHeaderCell,
  HeaderCell,
  HeaderCellText,
  LastHeaderCell,
  Cell,
} from './uiElements';
import styled from 'styled-components';
import React, { FC, useState, useEffect } from 'react';
import { ValidateIconContained } from 'ui/icons/ValidateIconContained';
import { ContainedLoader } from 'components/ContainedLoader';
import { useSelector } from 'react-redux';
import { FormatedCCBSession } from 'http/ccb/type';
import { RootState } from 'store';

interface Props {
  all: boolean;
}

export const CCBDoneTable: FC<Props> = ({ all }) => {
  const { activeCcb } = useSelector((state: RootState) => state.ccb);
  const [sessionsLeft, setSessionsLeft] = useState<FormatedCCBSession[] | []>(
    []
  );

  useEffect(() => {
    if (activeCcb?.sessions && activeCcb?.sessionsLeftToDo) {
      if (all) {
        // Filter out the session that matches the first element in sessionsLeftToDo
        const doneSessions = activeCcb.sessions.filter(
          (session: FormatedCCBSession) =>
            session.composition_unit !==
            activeCcb?.sessionsLeftToDo?.[0]?.composition_unit
        );
        setSessionsLeft(doneSessions);
      } else if (!all) {
        // Filter to get only finished sessions
        const doneSessions = activeCcb.sessions.filter(
          (session: FormatedCCBSession) =>
            session.composition_unit === activeCcb?.lastTask?.composition_unit
        );
        setSessionsLeft(doneSessions);
      }
    }
  }, [
    all,
    activeCcb?.sessions,
    activeCcb?.sessionsLeftToDo,
    activeCcb?.lastTask,
  ]);

  if (!activeCcb?.sessions) return <ContainedLoader loadingText="Chargement" />;
  return (
    <DoneTableContainer>
      <FirstHeaderCell>
        <HeaderCellText>Réalisée</HeaderCellText>
      </FirstHeaderCell>
      <HeaderCell>
        <HeaderCellText>Épreuve</HeaderCellText>
      </HeaderCell>
      <HeaderCell>
        <HeaderCellText>Date de début</HeaderCellText>
      </HeaderCell>
      <HeaderCell>
        <HeaderCellText>Date de fin</HeaderCellText>
      </HeaderCell>
      <HeaderCell>
        <HeaderCellText>Durée</HeaderCellText>
      </HeaderCell>
      <LastHeaderCell>
        <HeaderCellText>Tier temps</HeaderCellText>
      </LastHeaderCell>
      <>
        {sessionsLeft &&
          sessionsLeft?.length !== 0 &&
          sessionsLeft?.map((sessionLeft: FormatedCCBSession) => (
            <React.Fragment key={sessionLeft?.composition_unit}>
              <Cell>
                {sessionLeft.finished && (
                  <IconContainer>
                    <ValidateIconContained />
                  </IconContainer>
                )}
              </Cell>
              <Cell>{sessionLeft?.composition_unit}</Cell>
              <Cell>{sessionLeft?.start_date}</Cell>
              <Cell>{sessionLeft.end_date}</Cell>
              <Cell>{sessionLeft?.duration}</Cell>
              <Cell>{sessionLeft?.timeRange}</Cell>
            </React.Fragment>
          ))}
      </>
    </DoneTableContainer>
  );
};

const IconContainer = styled.div`
  height: 24px;
  width: 24px;
  color: ${({ theme }) => theme.colors.success.main};
`;
