import { useState } from 'react';
import styled from 'styled-components/macro';
import { Modal } from 'ui/Modal';
import { device } from 'helpers/css/responsive';
import NewsletterImg from 'assets/images/Newsletter.png';
import { ModalHeader } from 'ui/Modal/ModalHeader';
import { AuthorisationCheckbox } from 'ui/AuthorisationCheckbox';
import { ButtonBase } from 'ui/Buttons/ButtonBase';
import { NewsletterButton } from './NewsletterButton';
import { useGetUserOptions } from 'modules/user-options/hooks/useGetUserOptions';
import { useAccount } from 'pages/account/useAccount';

export const NewsletterModal = () => {
  const [checked, setChecked] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(true);
  const { data: userOptions } = useGetUserOptions();
  const { updateUserOptions } = useAccount();

  const closeModal = () => {
    // if checked user don't want to be asked any more
    if (checked) {
      updateUserOptions({ ...userOptions, needs_newsletter_prompt: false });
    }
    setIsOpen(false);
  };

  if (!userOptions?.needs_newsletter_prompt) return null;

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalHeader onCloseButtonClick={closeModal}>
        <Right>
          <h4>👀 Ne Manquez aucune information importante !</h4>
        </Right>
        <Left />
      </ModalHeader>
      <ModalContent>
        <ScrollableContainer>
          <Right>
            <p>
              Inscrivez-vous à notre newsletters et soyez les premiers informés
              des offres, des nouveautés et autres informations importantes
              concernant le concours des EDN.
            </p>
            <AuthorisationCheckbox
              checked={checked}
              text={'Ne plus afficher ce message'}
              type={'newsletterDisplay'}
              onChange={() => setChecked((prev) => !prev)}
            />
            <ButtonContainer>
              <NewsletterButton
                activeText={'Rester informé'}
                desactiveText={'le rappel est activé'}
                color={''}
              />
              <ButtonBase
                onClick={closeModal}
                size="medium"
                styled={'secondary'}
                disabled={false}
                iconOnly={false}
                hasPadding={true}
              >
                {'Fermer'}
              </ButtonBase>
            </ButtonContainer>
            <LegalText>
              Les informations recueillies font l'objet d'un traitement
              informatique. Pour en savoir plus sur la collecte, nous vous
              invitons à lire notre politique de{' '}
              <a href={'https://asclepia.io/confidentiality'}>
                confidentialité de données
              </a>
              .
            </LegalText>
          </Right>
          <Left>
            <StyledImage src={NewsletterImg} alt={'Newsletter'} />
          </Left>
        </ScrollableContainer>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const ScrollableContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media ${device.mobile} {
    overflow: auto;
    padding-right: 4px;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 60%;
  @media ${device.mobile} {
    width: 100%;
  }
`;

const Left = styled.div`
  width: 40%;
  @media ${device.mobile} {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  @media ${device.mobile} {
    flex-direction: col;
  }
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
`;

const LegalText = styled.div`
  margin-top: 16px;
  ${({ theme }) => theme.typography.small}
`;
