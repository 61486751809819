import styled from 'styled-components';
import { FC } from 'react';
import { GreyCard } from 'ui/Cards/GreyCard';
import { LockIcon } from 'ui/icons/Lock';
import { AccessSticker } from 'components/Stickers/AccessSticker';
import { NewsletterButton } from 'components/Newsletter/NewsletterButton';
import { useIsUserFree } from 'modules/user-options/hooks/useIsUserPremium';
import { formatDateRange } from 'helpers/dates/utils';
import { device } from 'helpers/css/responsive';
import { availableCCB } from 'http/ccb/type';

interface Props {
  ccb: availableCCB;
}

export const SoonAvailableSection: FC<Props> = ({ ccb }) => {
  const isUserFree = useIsUserFree();

  return (
    <Container>
      <h3>Bientôt disponible</h3>
      <GreyCard>
        <ContentContener>
          <Left>
            <GreyTitle>{ccb.collection.label}</GreyTitle>
            <AccessSticker status="free" />
            {isUserFree && <StyledLockIcon />}
          </Left>
          <Right>
            <Box>
              🗓 Accès gratuit{' '}
              {formatDateRange(ccb.start_date, ccb.end_date, 'du')}
              <small>
                Les informations recueillies font l'objet d'un traitement
                informatique destiné
                <br /> à vous adresser la newsletters. Voir notre{' '}
                <a
                  href={'https://asclepia.io/confidentiality'}
                  target="_blank"
                  rel="noreferrer"
                >
                  politique de confidentialité de données.
                </a>
              </small>
            </Box>

            <NewsletterButton
              activeText={'Prévenez-moi'}
              desactiveText={'le rappel est activé'}
              color={'primary'}
            />
          </Right>
        </ContentContener>
      </GreyCard>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
  width: 100%;
  margin-bottom: 3rem;
`;

const GreyTitle = styled.label`
  color: #7f8a95;
`;

const ContentContener = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  @media ${device.desktop} {
    flex-direction: row;
  }
`;

const StyledLockIcon = styled(LockIcon)`
  width: 20px;
  height: 20px;
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  @media ${device.desktop} {
    flex-direction: row;
    gap: 2rem;
  }
`;
const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  flex-wrap: wrap;
  gap: 1rem;
  @media ${device.desktop} {
    align-items: center;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: 'flex-end';
  justify-content: 'center';
  gap: 0.5rem;
`;
