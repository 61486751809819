import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { PageTitle } from 'ui/Titles/PageTitle';
import { TrainingLaunch } from './TrainingBlocks/TrainingLaunch';
import { TrainingList } from './TrainingBlocks/TrainingList';
import { InfoModalButton } from '../Modales/InfoModalButton';
import { updateActiveCcb, updateScreenToDisplay } from 'store/slices/ccbSlice';
import { BackwardsButton } from 'ui/Buttons/Backwards';
import { CCBScreenType } from 'http/ccb/type';
import { RootState } from 'store';

export const LauchingPage = () => {
  const dispatch = useDispatch();
  const { activeCcb } = useSelector((state: RootState) => state.ccb);
  const handleClicked = () => {
    dispatch(updateActiveCcb(null));
    dispatch(updateScreenToDisplay(CCBScreenType.PreCCBInfo));
  };

  return (
    <Container>
      <CcbHeader>
        <Head>
          <BackwardsButton handleClick={handleClicked} text={'Retour'} />
          <InfoModalButton />
        </Head>
        <PageTitle>{activeCcb?.collection?.label}</PageTitle>
      </CcbHeader>
      <TrainingLaunch />
      <TrainingList />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

const Head = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  max-width: 100%;
  margin-right: 3rem;
`;

const CcbHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 1rem;
`;
