import { FC } from 'react';
import styled from 'styled-components/macro';
import parse from 'html-react-parser';

import { Button } from 'ui/Buttons/Button';
import { ModalHeader, Modal } from 'ui/Modal';
import { QuestionPreviewBlock } from './QuestionPreview/QuestionPreviewBlock';
import { useIsSimilarQuestionAlreadySelected } from './useIsSimilarQuestionAlreadySelected';
import { useToggleSimilarQuestionInCart } from './useToggleSimilarQuestionInCart';
import { buttonReset } from 'helpers/css/button-reset';
import { CorrectionThemeProvider } from 'components/CorrectionThemeProvider';
import { StyledMathJax } from 'ui/MathJax';
import { SimilarQuestion } from 'types/correction.type';
import { QuestionHeaders } from 'ui/Headers/QuestionHeaders';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  question: SimilarQuestion;
  nextQuestion: () => void;
  previousQuestion: () => void;
  canNextQuestion: boolean;
  canPreviousQuestion: boolean;
}

export const QuestionModal: FC<Props> = ({
  isOpen,
  closeModal,
  question,
  nextQuestion,
  previousQuestion,
  canNextQuestion,
  canPreviousQuestion,
}) => {
  const isQuestionAlreadySelected = useIsSimilarQuestionAlreadySelected(
    question!
  );
  const addQuestionToCart = useToggleSimilarQuestionInCart();

  return (
    <CorrectionThemeProvider>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <ModalHeader onCloseButtonClick={closeModal}>
          <HeaderActions>
            <QuestionHeaders
              collection={question.group.collection}
              type={question.group.type}
              questionNum={
                question.group.type === 'QI' ? question.num : question.group.num
              }
              specialty={question.specialty1 || ''}
              item={question.item ?? undefined}
              knowledge={question.knowledge}
              composition={question.group?.composition_unit ?? undefined}
              category={question.categories[0]}
              group_type={question.type}
            />
            <Toggle onClick={previousQuestion} disabled={!canPreviousQuestion}>
              ←
            </Toggle>
            <Toggle onClick={nextQuestion} disabled={!canNextQuestion}>
              →
            </Toggle>
          </HeaderActions>
        </ModalHeader>
        <ModalContent>
          <ScrollableContainer>
            <CorrectionThemeProvider>
              <Statement>
                <StyledMathJax inline>
                  {parse(question?.statement ?? '')}
                </StyledMathJax>
              </Statement>
              {question && <QuestionPreviewBlock questionData={question} />}
            </CorrectionThemeProvider>
          </ScrollableContainer>

          <AddButton onClick={() => addQuestionToCart(question)}>
            {isQuestionAlreadySelected
              ? 'Supprimer de la sélection'
              : 'Ajouter le ' + question?.group.type + ' à la sélection'}
          </AddButton>
        </ModalContent>
      </Modal>
    </CorrectionThemeProvider>
  );
};

const ModalContent = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const Statement = styled.div`
  width: 100%;
  margin-bottom: 8px;
`;

const AddButton = styled(Button)`
  margin-top: 32px;
`;

const ScrollableContainer = styled.div`
  overflow: auto;
  padding-right: 4px;
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  gap: 16px;
  ${({ theme }) => theme.typography.h2}
`;

const Toggle = styled.button`
  ${buttonReset}
  height: 24px;
  width: 24px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.primary.dark};
  text-align: center;

  &:disabled {
    color: ${({ theme }) => theme.colors.inactive};
  }
`;
