import { FC, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';

import { useGetCorrectionTask } from 'http/correction/get_task/connector';
import { useGetFinishedTasks } from 'http/task-history/connector';
import { Correction } from './Correction';
import { useTour } from '@reactour/tour';

export const LastCorrectionPage: FC = () => {
  const { data: taskHistory } = useGetFinishedTasks();

  const { isOpen } = useTour();

  const [hasDataYetToLoad, setHasDataYetToLoad] = useState(true);

  const lastCorrectionId = useMemo(() => {
    if (!taskHistory) {
      return undefined;
    }

    if (taskHistory.length === 0) {
      return undefined;
    }

    const taskHistoryWithDates = taskHistory.map((task) => ({
      ...task,
      date_create: new Date(task.date_creation),
    }));

    const lastCorrectionDate = taskHistoryWithDates
      .map((task) => task.date_create)
      .reduce(function (a, b) {
        return a > b ? a : b;
      });

    const latestCorrection = taskHistoryWithDates.find(
      (task) => task.date_create === lastCorrectionDate
    );

    if (hasDataYetToLoad) {
      setHasDataYetToLoad(false);

      toast.info(
        'Nous chargeons votre dernière correction qui date du ' +
          latestCorrection?.date_create.toLocaleDateString('fr-FR')
      );
    }

    return latestCorrection?.id;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskHistory]);

  const { data: taskCorrection } = useGetCorrectionTask(
    lastCorrectionId!,
    !!lastCorrectionId
  );

  if (taskHistory?.length === 0 && !isOpen) {
    return (
      <Container>
        <h1>Vous n'avez encore aucune correction 😉</h1>
      </Container>
    );
  }

  return <Correction correction={taskCorrection} />;
};

const Container = styled.div`
  display: grid;
  place-items: center;
  --size: 100%;
  height: var(--size);
  width: var(--size);
`;
