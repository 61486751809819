import styled from 'styled-components';
import { FC, ReactElement } from 'react';
import BlueCard from 'ui/Cards/BlueCard';
import RankLetter from 'ui/Misc/RankLetter';
import { Rank } from 'types/knowledge.type';
import { device } from 'helpers/css/responsive';
import Tippy from '@tippyjs/react';

interface Props {
  ranks: Rank[];
  grade: number;
  text: string;
  fullWidth?: boolean;
  percentage?: boolean;
}

interface WrapperProps {
  children: ReactElement;
  isActive: boolean;
}

const Wrapper: FC<WrapperProps> = ({ children, isActive }) => {
  return isActive ? (
    <Tippy
      content={
        <>
          Il faut obtenir une note ≥ 14/20 sur les questions dites “fermées”
          (QRU, QRP, QROC, QZP) portant sur une connaissance de rang A.
        </>
      }
    >
      {children}
    </Tippy>
  ) : (
    children
  );
};

export const MyGradeCard: FC<Props> = ({
  ranks,
  grade,
  text,
  fullWidth,
  percentage,
}) => {
  return (
    <BlueCard maxWidth="240px" fullWidth={fullWidth}>
      <Container>
        <RanksContainer>
          {ranks.map((rank) => (
            <Wrapper
              isActive={ranks.length === 1 && rank === 'A'}
              key={'wrapper_' + rank}
            >
              <RankLetter letter={rank}>{rank}</RankLetter>
            </Wrapper>
          ))}
        </RanksContainer>
        <h5>
          {grade}
          {percentage ? '%' : '/20'}
        </h5>
        <Styledsmall>{text}</Styledsmall>
      </Container>
    </BlueCard>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  min-width: 140px;
  max-width: 160px;
  height: 100%;
  min-height: 144px;
  /* width: fit-content; */

  @media ${device.mobile} {
    width: 116px;
    min-width: 110px;
  }
`;

const RanksContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const Styledsmall = styled.small`
  color: ${({ theme }) => theme.colors.inactive};
`;
