import { FC } from 'react';
import styled from 'styled-components/macro';

import { Button } from 'ui/Buttons/Button';
import { useResetFilters } from 'hooks/useResetFilters';
import { useSearchbarContext } from './Searchbar/SearchbarContext';

export const ResetFiltersButton: FC = () => {
  const { resetFilters } = useResetFilters();
  const { setSearchTerm } = useSearchbarContext();

  return (
    <LightButton
      small
      onClick={() => {
        resetFilters();
        setSearchTerm('');
      }}
    >
      Réinitialiser les filtres
    </LightButton>
  );
};

const LightButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary.main};
`;
