import { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components/macro';

import { Button } from 'ui/Buttons/Button';

import { FLUSH_LOCAL_SELECTION_BUTTON_ID } from 'pages/progress/GeneralProgress/GeneralProgress';
import { useAddToLocalCartContext } from '../../contexts/addToLocalCartContext';
import { toast } from 'react-toastify';

interface Props {}

export const FlushLocalSelectionButton: FC<Props> = () => {
  const { flushLocalCart } = useAddToLocalCartContext();

  // We need to trigger the the apparition of the button with
  // a useEffect because on page mount the portal div is
  // not created yet
  const [buttonPortalNode, setButtonPortalNode] = useState(
    document.getElementById(FLUSH_LOCAL_SELECTION_BUTTON_ID)
  );
  useEffect(() => {
    setButtonPortalNode(
      document.getElementById(FLUSH_LOCAL_SELECTION_BUTTON_ID)
    );
  }, []);

  if (!buttonPortalNode) {
    return null;
  }

  return createPortal(
    <StyledButton
      small
      onClick={() => {
        flushLocalCart();
        toast.info('La sélection locale a été réinitialisée');
      }}
    >
      Réinitialiser la sélection
    </StyledButton>,
    buttonPortalNode
  );
};

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary.main};
`;
