import {
  RepartitionBar,
  RepartitionBarData,
} from 'components/StatsElements/RepartitionBar';
import { useAccount } from 'pages/account/useAccount';
import { Dot } from 'pages/concours-blanc/components/StatisticsPage/Stats/RepartitionLca/RepartitionLca';
import { SmallSectionTitle } from 'pages/newHome/ui/Global';
import { FlexDivJustify } from 'pages/newHome/ui/StatCard';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import BlueCard from 'ui/Cards/BlueCard';

interface GlobalAvancementProps {
  progressionBarData: RepartitionBarData;
  grade?: number;
}

export const GlobalAvancement: React.FC<GlobalAvancementProps> = ({
  progressionBarData,
  grade,
}) => {
  const theme = useTheme();
  const { userOptions } = useAccount();
  const total = progressionBarData.reduce((acc, curr) => acc + curr.value, 0);
  const notDone =
    progressionBarData.find((d) => d.id === 'not_done')?.value || 0;

  const percent = 100 - Math.round((notDone / total) * 100) || 0;

  return (
    <BlueCard
      noMargin
      color="#CDE0F6"
      darker
      fullWidth
      gap="1.5rem"
      minHeight="200px"
    >
      <FlexDivJustify>
        <SmallSectionTitle>Avancement Global</SmallSectionTitle>
        <FlexDiv>
          <SmallSectionTitle>{Math.round(percent)}% - </SmallSectionTitle>
          <SmallSectionTitle blur={!userOptions?.home_see_grades}>
            {grade}/20
          </SmallSectionTitle>
        </FlexDiv>
      </FlexDivJustify>
      <RepartitionBar repartition={progressionBarData} big />
      <LabelContainer>
        <FlexDivJustify>
          <FlexDiv>
            <Dot fillColor={theme.colors.success.main} />
            <Label>Réponses justes</Label>
          </FlexDiv>
          <Label>
            {Math.round((progressionBarData[0].value / total) * 100) || 0}% -{' '}
            {progressionBarData[0].value || 0} questions
          </Label>
        </FlexDivJustify>
        <FlexDivJustify>
          <FlexDiv>
            <Dot fillColor={theme.colors.secondary.main} />
            <Label>Réponses fausses</Label>
          </FlexDiv>
          <Label>
            {Math.round((progressionBarData[1].value / total) * 100) || 0}% -{' '}
            {progressionBarData[1].value || 0} questions
          </Label>{' '}
        </FlexDivJustify>
        <FlexDivJustify>
          <FlexDiv>
            <Dot fillColor={theme.colors.primary.main} />
            <Label>Non répondues</Label>
          </FlexDiv>
          <Label>
            {Math.round((progressionBarData[2].value / total) * 100) || 0}% -{' '}
            {progressionBarData[2].value || 0} questions
          </Label>{' '}
        </FlexDivJustify>
      </LabelContainer>
    </BlueCard>
  );
};
const FlexDiv = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
`;

const Label = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.inactive};
  margin: 0;
`;
