import { FC } from 'react';
import styled from 'styled-components';
import { Button } from 'ui/Buttons/Button';

import { FullWidthRow } from './FullWidthRow';

interface Props {
  colNumber: number;
  retry: () => void;
}

export const TableError: FC<Props> = ({ colNumber, retry }) => (
  <FullWidthRow colNumber={colNumber}>
    <Container>
      <h2>Une erreur est survenue ❌</h2>
      <Button onClick={retry}>Réessayer</Button>
      <span>Si l'erreur persiste veuillez réessayer plus tard</span>
    </Container>
  </FullWidthRow>
);

const Container = styled.div`
  width: 100%;
  min-height: 500px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
`;
