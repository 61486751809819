import { device } from 'helpers/css/responsive';
import { forwardRef, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components/macro';
import { FilterIcon } from 'ui/icons/Filter';

import { SmallDownArrow } from 'ui/icons/Arrows/SmallDownArrow';
import { SortAscendingIcon } from 'ui/icons/SortAscending';
import { sortOptions, SortOptions } from './constants';

interface Props extends HTMLAttributes<HTMLDivElement> {
  isCheckbox?: boolean;
  isSub?: boolean;
  isReadOnly?: boolean;
  isFiltering?: boolean;
  sortOrder?: SortOptions;
  filters?: number;
  isBig?: boolean;
}

export const ColumnHeaderWrapper = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      isSub,
      isReadOnly,
      isFiltering,
      sortOrder,
      filters,
      isBig,
      ...divProps
    },
    ref
  ) => {
    return (
      <Container
        isSub={isSub}
        isReadOnly={isReadOnly}
        {...divProps}
        ref={ref}
        isBig
      >
        <ContentContainer isSub={isSub}>{children}</ContentContainer>
        <IconsContainer>
          {sortOrder === sortOptions.ascending && <AscendingSortIcon />}
          {sortOrder === sortOptions.descending && <DescendingSortIcon />}
          {isFiltering && <StyledFilterIcon />}
          {filters && filters !== 0 ? <Filters>{filters}</Filters> : null}
          {!isReadOnly && <FilterArrow />}
        </IconsContainer>
      </Container>
    );
  }
);

const Container = styled.div<{
  isCheckbox?: boolean;
  isSub?: boolean;
  isReadOnly?: boolean;
  isBig?: boolean;
}>`
  padding: ${({ isBig }) => (isBig ? '1rem' : '4px 8px')};
  background-color: ${({ theme, isSub }) =>
    isSub ? theme.colors.primary.light : theme.colors.background.paper};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.primary.dark};
  cursor: ${({ isReadOnly }) => (isReadOnly ? 'default' : 'pointer')};

  ${({ isSub, theme }) =>
    isSub
      ? css`
          ${theme.typography.textBody2}
        `
      : css`
          ${theme.typography.label}
        `}

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  @media ${device.largeTable} {
    padding: ${({ isBig }) => (isBig ? '1rem' : '4px 8px')};
    gap: 1rem;

    ${({ isCheckbox }) =>
      isCheckbox &&
      css`
        padding: 6px 12px;
        gap: 8px;
        width: 60px;
      `}

    ${({ isSub }) =>
      isSub &&
      css`
        gap: 4px;
      `}
  }

  ${({ isCheckbox }) =>
    isCheckbox &&
    css`
      padding: 6px 12px;
      gap: 8px;
      width: 60px;
    `}
`;

const IconsContainer = styled.div`
  gap: 1rem;
  display: flex;
  align-items: center;
`;

const FilterArrow = styled(SmallDownArrow)`
  height: 8px;
  width: 8px;
`;

const StyledFilterIcon = styled(FilterIcon)`
  height: 12px;
  width: 12px;
`;

const AscendingSortIcon = styled(SortAscendingIcon)`
  height: 12px;
  width: 12px;
`;

const DescendingSortIcon = styled(AscendingSortIcon)`
  transform: scaleY(-1);
`;

const ContentContainer = styled.div<{ isSub?: boolean }>`
  display: flex;
  flex-grow: 1;
  justify-content: ${({ isSub }) => (isSub ? 'center' : 'flex-start')};
  margin-right: 1rem;
`;

const Filters = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary.main};
  display: flex;
  justify-content: center;
  align-content: center;
  color: ${({ theme }) => theme.colors.primary.contrastText};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 3px;
  font-size: 1rem;
  line-height: 1.1;
  font-weight: 700;
`;
