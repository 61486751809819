import { FC, useState } from 'react';
import styled from 'styled-components/macro';
import { MultipleKnowledgeRank } from 'components/Knowledge/MultipleKnowledgeRank';
import { CorrectionThemeProvider } from 'components/CorrectionThemeProvider';
import { RelatedLessonModal } from './RelatedLessonModal';
import WhiteCard from 'ui/Cards/WhiteCard';
import { ItemsFromKnowledges } from 'components/Item/ItemsFromKnowledges';
import { Lesson } from 'types/lesson.type';

interface Props {
  lesson: Lesson;
}

export const RelatedLessonCard: FC<Props> = ({ lesson }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleVisibility = () => setIsModalOpen(!isModalOpen);
  const closeModal = () => setIsModalOpen(false);

  if (!lesson) return null;

  return (
    <CorrectionThemeProvider>
      <StyledWhiteCard onClick={toggleVisibility} $smallPading>
        <div>{lesson.title}</div>
        <Bottom>
          <ItemsFromKnowledges knowledges={lesson.knowledges} />
          <MultipleKnowledgeRank knowledges={lesson.knowledges} />
        </Bottom>
        {
          <RelatedLessonModal
            isOpen={isModalOpen}
            closeModal={closeModal}
            title={lesson.title}
            body={lesson.content}
            knowledges={lesson.knowledges}
          />
        }
      </StyledWhiteCard>
    </CorrectionThemeProvider>
  );
};

const StyledWhiteCard = styled(WhiteCard)`
  ${({ theme }) => theme.typography.h2}
  max-width: 280px;
  width: 100%;
  &:hover {
    cursor: pointer;
    border: 1px solid #a5c7ef;
  }
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${({ theme }) => theme.typography.h2}
`;
