import { FC } from 'react';
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  Label,
} from 'recharts';
import styled, { useTheme } from 'styled-components';
import {
  StyledTooltip,
  TooltipLine,
  TooltipText,
} from 'ui/Graph/CustomTooltip';
import { FormatedDataChart } from './GlobalGrades';

interface GlobalGradesChartProps {
  data: FormatedDataChart[];
}

const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <StyledTooltip>
        <TooltipLine>
          <TooltipText>{`${payload[0].payload.grade}/20`}</TooltipText>
        </TooltipLine>
        <TooltipLine>
          <TooltipText>{`Rang ${payload[0].payload.rank}`}</TooltipText>
        </TooltipLine>
      </StyledTooltip>
    );
  }
  return null;
};

export const GlobalGradesChart: FC<GlobalGradesChartProps> = ({ data }) => {
  const theme = useTheme();

  return (
    <Container width="100%" height={400}>
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 50,
        }}
      >
        <CartesianGrid horizontal={false} stroke="#576068" />
        <XAxis
          dataKey="rank"
          axisLine={false}
          tickLine={false}
          tickMargin={15}
          stroke={theme.colors.inactive}
          fontWeight={400}
          fontSize={14}
        >
          <Label
            value="Rang des participants"
            position="bottom"
            fontSize={16}
            fontWeight={400}
            style={{ textAnchor: 'middle' }}
            fill="black"
            offset={20}
          />
        </XAxis>
        <YAxis
          dataKey="grade"
          axisLine={false}
          tickLine={false}
          tickMargin={15}
          stroke={theme.colors.inactive}
          fontWeight={400}
          fontSize={14}
          range={[0, 20]}
        >
          <Label
            value="Notes globales"
            angle={-90}
            position="insideLeft"
            fontSize={16}
            fontWeight={400}
            style={{ textAnchor: 'middle' }}
            fill="black"
            offset={-5}
          />
        </YAxis>
        <Tooltip content={<CustomTooltip />} />

        <Line
          type="monotone"
          dataKey="grade"
          stroke={theme.colors.secondary.main}
          strokeWidth={4}
          dot={false}
        />
      </LineChart>
    </Container>
  );
};

const Container = styled(ResponsiveContainer)`
  margin-top: 3rem;
`;
