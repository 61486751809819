import { FC } from 'react';
import styled from 'styled-components/macro';

import { DPProgressRowBySession } from './DPProgressRowBySession';
import {
  Footer,
  ProgressContainer,
  Table,
  TableScrollContainer,
} from '../../components/SectionUIElements';
import { DPBasicInformations } from 'modules/progress/general/dp/domain/entities/DPBasicInformations';
import React from 'react';
import { DPProgressByCompositionUnits } from 'modules/progress/general/dp/domain/entities/DPProgressByCompositionUnits';

interface Props {
  progress: DPProgressByCompositionUnits;
}

export const DPProgressYearBySession: FC<Props> = ({ progress }) => {
  return (
    <ProgressContainer>
      <Header>{progress.collection}</Header>
      <TableScrollContainer
        id={
          'dp-progress-year-by-session-' +
          progress.collection.split(' ').join('-')
        }
      >
        <Table>
          <tbody>
            {Object.keys(progress.compositionUnits).map((compositionUnit) => {
              return (
                <React.Fragment key={progress.collection + compositionUnit}>
                  {compositionUnit !== '0' && (
                    <tr>
                      <HeaderCell colSpan={5}>UC{compositionUnit}</HeaderCell>
                    </tr>
                  )}
                  {
                    // @ts-ignore
                    progress.compositionUnits[parseInt(compositionUnit)].map(
                      (group: DPBasicInformations) => (
                        <DPProgressRowBySession
                          question={group}
                          key={group.id}
                        />
                      )
                    )
                  }
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
      </TableScrollContainer>
      <Footer />
    </ProgressContainer>
  );
};

const Header = styled.div`
  ${({ theme }) => theme.typography.label}
  width: 100%;
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.primary.main};
`;

const HeaderCell = styled.td`
  background-color: ${({ theme }) => theme.colors.primary.main};
  width: 100%;

  padding: 4px 12px;
  vertical-align: middle;
  text-align: start;

  ${({ theme }) => theme.typography.label}
`;
